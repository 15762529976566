import React from "react";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";
import Container from "../../Components/UI/Container";
import { useUserStore } from "../../Context/store";
import { useSubworkspaceStore } from "../../Context/store";
import { useGetPrivateUserWiseDebatedList } from "../../Web-Hooks/Debate/use-debate";
import Posts from "../Posts/Posts";

const Private = () => {
  const location = useLocation();
  const { pathname } = location;
  const opponentId = pathname.slice(1).split("/")[1];
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { user } = useUserStore();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetPrivateUserWiseDebatedList(
      user?.userId,
      selectedSubWorkspace?.subWorkSpaceId,
      opponentId
    );

  return (
    <Container>
      {/* Title */}
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
        >
          <Posts
            apiType={pathname}
            user={user}
            isLoading={isLoading && !isFetchingNextPage}
            AllDebates={data?.pages?.flatMap((page) => page.privateDebateLists)} // Combine all pages
          />
        </InfiniteScroll>
    </Container>
  );
};

export default Private;
