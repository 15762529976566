import {
  Button,
  Chip,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { signOut } from "firebase/auth";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { agentSessionLogout } from "../../../api/agents/agentsApi";
import { usePlan } from "../../../Context/PlanContext";
import { useSocket } from "../../../Context/SocketContext";
import { useUserProfileStore, useUserStore } from "../../../Context/store";
import { auth } from "../../../Services/Google-Signin/config";
import { getInitials, getInitialsFromEmail } from "../../../Utils/Helper";
import ImageOrInitials from "../../Functionality/ImageOrInitials";

const BurgerMenu = ({
  toggle,
  setToggle1,
  Options,
  openProfile,
  children,
  openDrawerNotification,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { logout, user } = useUserStore();
  const { disconnect } = useSocket();
  const { currentPlan } = usePlan();
  const { profileDetail } = useUserProfileStore();

  const handleClick = (route, isAccessible = true) => {
    if (route === "/") {
      agentSessionLogout({
        user_id: user?.userId,
        session_id: user?.sessionId,
      });
      signOut(auth);
      disconnect();
      logout();
      navigate(route);
    } else if (route === "profile") {
      openProfile();
    } else if (route === "rate-us") {
      setToggle1(true);
    } else if (route === "setting") {
      openDrawerNotification();
    } else {
      navigate(isAccessible ? `/${route}` : `/upgrade-plan?plan=base-plan`);
    }
    setOpen(false);
  };

  const restrictionForOptions = (option) => {
    switch (option) {
      case "Start Debate":
        return currentPlan?.createDebate;
      case "Library":
        return currentPlan?.libraryOption;
      case "Role Management":
        return currentPlan?.roleManagement;
      default:
        return true;
    }
  };

  const MenuListRenderer = (option, i) => {
    const isAccessible = restrictionForOptions(option?.item);

    return (
      <Fragment key={i}>
        {option.isHRline && <hr className="my-2 border-blue-gray-50" />}
        <MenuItem
          id={option?.item}
          className="flex justify-between items-center gap-2 hover:bg-primary "
          onClick={() => handleClick(option?.route, isAccessible)}
        >
          <div className="flex items-center gap-2">
            {option.SVG && (
              <img src={option.SVG} alt={option.item} className="h-5 w-5" />
            )}
            <Typography
              variant="small"
              className="font-semibold text-debatePalette-title"
            >
              {option.item}
            </Typography>
          </div>
          {!isAccessible && (
            <Tooltip content={"Upgrade to Pro"}>
              <Chip
                size="sm"
                variant="outlined"
                value={"Pro"}
                className="cursor-pointer text-secondary border-secondary bg-transparent group-hover:border-white group-hover:text-white"
              />
            </Tooltip>
          )}
        </MenuItem>
      </Fragment>
    );
  };

  return (
    <Menu
      placement="top-end"
      open={open}
      handler={setOpen}
      dismiss={{
        itemPress: true,
        enabled: true,
        outsidePress: true,
      }}
    >
      <MenuHandler>
        <div id="profile-options">{children}</div>
      </MenuHandler>
      <MenuList className="min-w-[200px] py-1">
        <div className="flex items-center gap-2 my-2 hover:bg-primary p-2 rounded-md bg-primary">
          <ImageOrInitials
            imageSrc={profileDetail?.userImage}
            alt={profileDetail?.userName}
            initials={
              getInitials(profileDetail?.userName) ||
              getInitialsFromEmail(profileDetail?.email)
            }
            size={"h-12 w-12"}
            classNameInitials={" text-lg"}
          />
          <p className="text-debatePalette-background font-bold text-xl capitalize">
            {profileDetail?.userName
              ? profileDetail?.userName
              : profileDetail?.email?.split("@")[0]}
          </p>
        </div>
        {Options.map((option, i) => MenuListRenderer(option, i))}
      </MenuList>
    </Menu>
  );
};

export default BurgerMenu;

BurgerMenu.ButtonMenu = function BurgerMenuButton({ ripple, label }) {
  return (
    <Button
      id="profile-options"
      ripple={ripple}
      variant="text"
      className="flex px-3 py-1 justify-between w-full items-center bg-transparent gap-3 text-base font-normal capitalize tracking-normal"
    >
      <div className="flex ">{label}</div>
    </Button>
  );
};

BurgerMenu.TextMenu = function TextMenu({ label }) {
  return (
    <div className="flex text-blue-gray-500 gap-2 items-center">{label}</div>
  );
};
