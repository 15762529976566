import {
  Bars3BottomLeftIcon,
  BellIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
  PlusCircleIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import {
  Avatar,
  Button,
  Drawer,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Search } from "../Assets/Svg/SearchIcon.svg";
import FeedHeader from "../Components/Functionality/FeedHeader";
import AddSubWorkspacePopup from "../Components/Popup/AddSubWorkspacePopup";
import BurgerMenu from "../Components/UI/Menus/BurgerMenu";
import { SideBarMenu } from "../Components/UI/Menus/SideBarMenu";
import { useSubworkspaceStore } from "../Context/store";
import { useWorkspaceStore } from "../Context/store";
import { DebateOptions, ProfileMenuOptions } from "../Utils/Constant";
import { extractPathname, getInitialsFromEmail } from "../Utils/Helper";
import MobileFeedBar from "./Sidebar/MobileFeedBar";
import { useUserStore } from "../Context/store";
import { getSubWorkspaceByWorkId } from "../api/subworkspace/subworkspace";

const MobileLayout = ({ search, setSearch, openProfile, children, isTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search: searchQuery } = location;
  const query = new URLSearchParams(searchQuery);
  const { user } = useUserStore();
  const userName = query?.get("userName");
  const [popup, setPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSidebarDrawer, setOpenSidebarDrawer] = useState(false);
  const openDrawer = () => setOpenSidebarDrawer(true);
  const closeDrawer = () => setOpenSidebarDrawer(false);
  const { selectWorkspace, selectedWorkspace } = useWorkspaceStore();
  const { workspaceList } = useWorkspaceStore();
  
  const {
    subWorkspaceList,
    selectSubWorkspace,
    selectedSubWorkspace,
    SubWorkspaceUser,
  } = useSubworkspaceStore();

  const filteredSubWorkspaceList = subWorkspaceList?.filter(
    (subWorkspace) =>
      subWorkspace.workSpaceId === selectedWorkspace?.workSpaceId
  );

  const handleSelectWorkspace = async (workspace) => {
    selectWorkspace(workspace);

    const subworkspaceList = await getSubWorkspaceByWorkId(
      workspace.workSpaceId,
      user?.userId
    );

    const subworkspace = subworkspaceList?.listOfSubworkspaces[0];
    if (subworkspace) {
      selectSubWorkspace(subworkspace);
    }
  };

  return (
    <div className="">
      <div className="fixed bottom-10 right-0 z-100">
        <SideBarMenu
          isMobileLayout={true}
          isArrow={false}
          label={<PlusCircleIcon className="w-14 h-14 text-secondary" />}
          options={DebateOptions}
          handleClick={(route, selectedDebate) =>
            navigate(route, { state: { selectedDebate: selectedDebate } })
          }
        />
      </div>
      <div className="p-2 h-16 flex gap-2 items-center">
        <div className="relative flex  w-full items-center justify-between">
          <input
            type="search"
            className=" h-12 m-0 block w-[1px] min-w-0 flex-auto rounded border border-none bg-clip-padding px-3 py-[0.25rem] text-debatePalette-background font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none motion-reduce:transition-none dark:border-neutral-500 dark:text-neutral-200 dark:placeholder:text-neutral-200  placeholder:text-debatePalette-background placeholder:text-lg bg-secondary"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="button-addon2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <span
            className=" absolute right-0  input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-debatePalette-background font-normal"
            id="basic-addon2"
          >
            <Search />
          </span>
        </div>
        <div className="flex flex-row  justify-end items-center">
          <Menu>
            <MenuHandler>
              <Button
                istooltip={`${true}`}
                toolcontent={"Help"}
                variant="text"
                className="border-none p-2"
              >
                <EllipsisVerticalIcon className="h-8 w-8 " />
              </Button>
            </MenuHandler>
            <MenuList className="min-w-[0rem]">
              <MenuItem
                className="flex gap-2 items-center"
                onClick={() => navigate("/notification")}
              >
                <BellIcon className="h-5 w-5 " /> Notification
              </MenuItem>
              <MenuItem className="flex gap-2 items-center">
                <QuestionMarkCircleIcon className="h-5 w-5 " /> Help
              </MenuItem>
              <MenuItem>
                <BurgerMenu
                  setToggle1={setOpen}
                  toggle1={open}
                  Options={ProfileMenuOptions}
                  openProfile={openProfile}
                >
                  <BurgerMenu.TextMenu
                    label={
                      <div className="flex justify-center text-blue-gray-500 gap-2 items-center">
                        <ChevronLeftIcon
                          strokeWidth={2.5}
                          className={`h-3.5 w-3.5 transition-transform rotate-90`}
                        />
                        Profile
                      </div>
                    }
                  />
                </BurgerMenu>
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <div className="p-2 h-12 justify-start flex gap-3 items-center">
        <Button onClick={openDrawer} variant="text" className="p-0.5">
          <Bars3BottomLeftIcon className="h-8 w-8" />
        </Button>
        <Menu>
          <MenuHandler>
            <div className="flex gap-2 items-center">
              <span className="text-lg font-semibold w-20 truncate">
                {selectedWorkspace?.workSpaceName}
              </span>
              <ChevronDownIcon className="h-5 w-5" />
            </div>
          </MenuHandler>
          <MenuList className="">
            {workspaceList &&
              workspaceList?.map((workspace) => (
                <MenuItem
                  key={workspace?.workSpaceId}
                  className="flex items-center p-1 justify-between gap-3 min-w-60"
                  onClick={() => {
                    handleSelectWorkspace(workspace);
                  }}
                >
                  <div className="flex items-center p-1 justify-start gap-3">
                    {workspace?.profileImage ? (
                      <Avatar
                        src={workspace?.profileImage}
                        alt={workspace?.selectedWorkspacename}
                        className="h-10 w-10"
                      />
                    ) : (
                      <div className="inline-flex items-center justify-center h-10 w-10 uppercase font-bold shadow-lg border border-primary-basic text-secondary bg-white  rounded-full">
                        {getInitialsFromEmail(workspace?.workSpaceName)}
                      </div>
                    )}
                    <p className="text-md font-semibold text-secondary">
                      {workspace?.workSpaceName}
                    </p>
                  </div>
                </MenuItem>
              ))}
            <MenuItem
              className="flex items-center p-1 justify-start gap-3 min-w-60"
              onClick={() => {
                navigate("/createworkspace");
              }}
            >
              {
                <PlusIcon className="h-10 w-10 text-debatePalette-background rounded-full bg-secondary" />
              }
              <p className="text-md font-semibold text-secondary">
                Add a Debatebase
              </p>
            </MenuItem>
          </MenuList>
        </Menu>

        <Menu>
          <MenuHandler>
            <div className="flex gap-2 items-center">
              <span className="text-lg font-semibold w-28 text-ellipsis truncate">
                {selectedSubWorkspace?.subWorkSpaceName}
              </span>
              <ChevronDownIcon className="h-5 w-5" />
            </div>
          </MenuHandler>
          <MenuList className="">
            {filteredSubWorkspaceList?.map((subWorkspace, index) => (
              <MenuItem
                className={`flex gap-3 p-2 text-debatePalette-title font-medium ${
                  subWorkspace?.subWorkSpaceId ===
                  selectedSubWorkspace?.subWorkSpaceId
                    ? " rounded-none border-b-2 border-primary "
                    : ""
                }`}
                key={index}
                onClick={() => {
                  setOpen(0);
                  //   setopenMessage(0);
                  selectSubWorkspace(subWorkspace);
                }}
              >
                {subWorkspace?.subWorkSpaceName}
                {/* {<ChevronRightIcon className="h-4 w-4" />} */}
              </MenuItem>
            ))}
            <MenuItem
              className="flex gap-3 p-2 text-debatePalette-title font-medium"
              onClick={() => setPopup(true)}
            >
              Create Forum{<ChevronRightIcon className="h-4 w-4" />}
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
      <div className="h-[calc(100vh-7rem)]">
        {extractPathname(pathname)[0] !== "createprofile" &&
          extractPathname(pathname)[0] !== "message" && (
            <FeedHeader
              apiType={pathname}
              isTab={isTab}
              AvtarStackCount={SubWorkspaceUser?.length}
              userName={userName}
            />
          )}
        <div className="overflow-y-auto h-[calc(100vh-7rem)]">{children}</div>
      </div>

      <Drawer
        open={openSidebarDrawer}
        overlay={false}
        placement="right"
        onClose={closeDrawer}
        className="p-4 md:hidden"
        children={
          <MobileFeedBar
            closeDrawer={closeDrawer}
            openSidebarDrawer={openSidebarDrawer}
          />
        }
      />
      <AddSubWorkspacePopup
        handleClose={() => setPopup(false)}
        setPopup={setPopup}
        open={popup}
      />
      {/* <EditSubDebatePopup
        popupFor="workspace"
        open={openEditWorkspace}
        handleClose={() => setEditWorkspacePopup(false)}
      /> */}
    </div>
  );
};

export default MobileLayout;
