import { Avatar, Button, Chip, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Versus from "../../Assets/Images/versus.png";
import { ReactComponent as AILogo } from "../../Assets/Svg/AILogo.svg";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import NoData from "../../Components/Functionality/NoData";
import Container from "../../Components/UI/Container";
import { useUserStore } from "../../Context/store";
import { useDebateSummaryAI } from "../../Web-Hooks/Agents/use-global-agents";
import {
  useGetDebateDetails,
  useGetDebateResult,
} from "../../Web-Hooks/Debate/use-debate";
import DebateSummaryPopup from "./DebateSummaryPopup";

// User detail component for rendering individual user information
const UserDetail = ({
  userName,
  userVote,
  voteStatus,
  avatarSrc,
  direction,
}) => {
  const isRight = direction === "right";

  return (
    <div
      className={`userDetail flex w-full sm:w-3/4 lg:w-full -space-x-16 lg:-space-x-10 xl:-space-x-16`}
    >
      {isRight && (
        <ImageOrInitials
          imageSrc={avatarSrc}
          initials={userName}
          size={"h-24 w-24 xs:w-32 xs:h-32"}
          classNameInitials={"text-3xl xs:text-5xl ring-4 ring-primary"}
          classNameImage={"ring-4 ring-primary"}
        />
      )}
      <div
        className={`flex-1 h-24 xs:h-32 flex flex-col justify-evenly items-center ${
          isRight ? "lg:ps-5 xl:ps-0" : "lg:pe-5 xl:pe-0"
        } bg-secondary rounded-${isRight ? "r" : "l"}-lg animate-${
          isRight ? "slideInLeft" : "slideInRight"
        }`}
      >
        <p className="text-debatePalette-background font-semibold text-xl xs:text-2xl">
          {userName}
        </p>
        <p className="text-debatePalette-background font-semibold text-base xs:text-lg">
          {userVote}
        </p>
        <Chip
          value={voteStatus}
          color={
            voteStatus === "won"
              ? "blue"
              : voteStatus === "lost"
              ? "red"
              : "yellow"
          }
        />
      </div>
      {!isRight && (
        <ImageOrInitials
          imageSrc={avatarSrc}
          initials={userName}
          size={"h-24 w-24 xs:w-32 xs:h-32"}
          classNameInitials={"text-3xl xs:text-5xl ring-4 ring-primary"}
          classNameImage={"ring-4 ring-primary"}
        />
      )}
    </div>
  );
};

// Main component to display active debate results
const ActiveDebateResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const debateId = pathname.split("/").pop();
  const [showSummary, setShowSummary] = useState(false);
  const { user: loggedInUser } = useUserStore();
  const { data: debateResults } = useGetDebateResult(debateId);
  const { data: debateSummary, isLoading: isSummaryPending } =
    useDebateSummaryAI(debateId);
  const { data: debateInfo, isLoading: isInfoPending } = useGetDebateDetails(
    debateId,
    loggedInUser?.userId
  );
  const createdUserId = debateResults?.roundResults[0]?.creatorUserId;
  const isCreator = loggedInUser?.userId === createdUserId;
  const status = debateResults?.status;

  // Find the next round number based on total rounds and completed rounds
  const totalRounds = debateResults?.totalRound;
  const completedRounds = debateResults?.roundResults?.length;
  const nextRoundNumber = completedRounds + 1;

  const handleNextRoundClick = () => {
    navigate(
      `/debate/${debateId}/add-argument?roundNo=${nextRoundNumber}&isNextRound=true`
    );
  };

  const handleDebateView = () => {
    if (debateInfo) {
      navigate(
        `/debate/${debateId}?round=${debateInfo?.currentRound}&isUpdateVote=true`,
        {
          state: {
            debate: debateInfo,
            category: debateInfo?.fields,
            debateType: debateInfo?.type,
          },
        }
      );
      return;
    }
    toast.error("Debate not found");
  };

  const handleSummaryPopupClick = () => {
    if (!debateSummary) {
      toast?.error(
        "Summary is not available."
      );
      return;
    }
    setShowSummary(true);
  };

  return (
    <Container>
      <div className="w-full flex justify-end">
        <Button
          size="sm"
          variant="outlined"
          id="debateSummaryButton"
          disabled={isSummaryPending}
          onClick={handleSummaryPopupClick}
          className={` flex items-center p-1 justify-end self-end gap-2 border-[#7647FC] -pl-8`}
        >
          <AILogo className="h-6 w-6" />
          <span
            className="text-[#7647FC] font-semibold animate__animated animate__fadeIn animate__faster"
            style={{ whiteSpace: "nowrap" }}
          >
            Debate Summary
          </span>
        </Button>
      </div>
      {debateInfo?.isPublishWithoutOpponent === true ||
      debateInfo?.type === "eonian" ? (
        <NoData message={debateResults?.message} size="w-full h-[80%]">
          <NoData.Buttons
            btn={"View Debate details"}
            disable={isInfoPending}
            handleClick={handleDebateView}
          />
        </NoData>
      ) : (
        <section className="flex flex-col gap-3 justify-center items-center w-full">
          {/* Show message that a round is in process, based on the difference between total and completed rounds */}
          {totalRounds > completedRounds && status === "published" && (
            <>
              <Typography variant="h1" className="text-center w-full">
                Round {nextRoundNumber}
              </Typography>
              <div className="h-28">
                <Typography variant="h5" className="text-center">
                  Round {nextRoundNumber} is currently in process.
                </Typography>
              </div>
            </>
          )}
          {totalRounds > completedRounds && status === "review" && (
            <div className="flex flex-col justify-center items-center gap-4 w-full">
              {isCreator ? (
                <>
                  <Typography variant="h1" className="text-center w-full">
                    Round {nextRoundNumber}
                  </Typography>
                  <div className="h-28">
                    <Button
                      variant="filled"
                      className="bg-primary text-debatePalette-title w-fit"
                      onClick={handleNextRoundClick}
                    >
                      Start Round {nextRoundNumber}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Typography variant="h1" className="text-center w-full">
                    Round {nextRoundNumber}
                  </Typography>
                  <div className="h-28">
                    <Typography variant="h5" className="text-center">
                      Round {nextRoundNumber} has not started yet.
                    </Typography>
                  </div>
                </>
              )}
            </div>
          )}

          <div className="flex flex-col justify-center gap-4 w-full">
            {debateResults?.roundResults?.map((debate) => (
              <div key={debate?.roundNo} className="w-full flex-col flex gap-4">
                <div className="flex flex-row gap-2 items-start justify-center w-full">
                  <Typography variant="h1" className="text-center w-full">
                    Round {debate?.roundNo}
                  </Typography>
                </div>
                <section className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-4 xl:gap-10 w-full">
                  <UserDetail
                    userName={debate?.creatorName}
                    userVote={debate?.creatorVote}
                    voteStatus={
                      debate?.creatorUserId === debate?.winnerId
                        ? "won"
                        : debate?.winnerId === ""
                        ? "tie"
                        : "lost"
                    }
                    avatarSrc={debate?.creatorImage}
                    direction="right"
                  />
                  <Avatar src={Versus} alt="avatar" className="h-20 w-20" />
                  <UserDetail
                    userName={debate?.opponentName}
                    userVote={debate?.opponentVote}
                    voteStatus={
                      debate?.opponentUserId === debate?.winnerId
                        ? "won"
                        : debate?.winnerId === ""
                        ? "tie"
                        : "lost"
                    }
                    avatarSrc={debate?.opponentImage}
                    direction="left"
                  />
                </section>
              </div>
            ))}
          </div>
        </section>
      )}
      <DebateSummaryPopup
        closeDialog={() => setShowSummary(false)}
        isOpen={showSummary && !!debateSummary}
        debateSummary={debateSummary}
        debateInfo={debateInfo}
        handleDebateView={handleDebateView}
      />
    </Container>
  );
};

export default ActiveDebateResults;
