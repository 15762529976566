import { Alert, Button } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useCancelSchedule } from "../../Web-Hooks/Subscription/use-subscription";
import {
  convertSubCurrency,
  convertSubcurrencyToMain,
} from "../../Utils/Helper";
import Swal from "sweetalert2";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { primaryColor } from "../../Utils/Constant";
import { useSubworkspaceStore, useWorkspaceStore } from "../../Context/store";

const ActionButton = ({
  plan,
  isYearly,
  isCurrentPlan,
  subscribedPlan,
  theme,
}) => {
  const {
    stripeProductId,
    isRecommended,
    isContactUs,
    // isMonthlyPlan,
    // isYearlyPlan,
    monthlyAmonut,
    planKeywords,
    planName,
    yearlyAmonut,
    planId,
  } = plan;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: cancelScheduledChange } = useCancelSchedule();
  const intervalWiseAmount = !isYearly ? monthlyAmonut : yearlyAmonut;
  const isFreeTrialPeriodAvailable = subscribedPlan?.isTrialPlanAvailable;
  const currentPlan = subscribedPlan?.stripeJson?.items?.data[0]?.plan;
  const scheduledPlan = subscribedPlan?.scheduleSubscriptionDetails;
  const isScheduled = subscribedPlan?.stripeJson?.schedule;
  const isSamePlanScheduled =
    scheduledPlan?.planName === planName &&
    scheduledPlan?.planAmount === convertSubCurrency(intervalWiseAmount);
  const currentPlanAmount = currentPlan?.amount;
  const currentPlanType = currentPlan?.interval;
  const isDowngrade =
    currentPlanAmount >
    convertSubCurrency(
      currentPlanType === "year" ? yearlyAmonut : monthlyAmonut
    );
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();

  const recommendedButton =
    theme === "base"
      ? "bg-primary text-secondary hover:scale-110 hover:shadow-lg"
      : "bg-AITheme-primary text-white hover:scale-110 hover:shadow-lg";
  const notRecommendedButton =
    theme === "base"
      ? "border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-white hover:scale-110 hover:shadow-lg"
      : "border border-secondary text-secondary bg-transparent hover:bg-secondary hover:text-white hover:scale-110 hover:shadow-lg";

  const handleRedirection = () => {
    // Check if there is a scheduled plan
    if (isCurrentPlan) {
      if (!selectedWorkspace && !selectedSubWorkspace) {
        navigate(`/createworkspace`);
        return;
      }
      navigate(`/feed-public`);
      return;
    }
    if (isScheduled) {
      Swal.fire({
        title: "Cancel scheduled update",
        html: `
          <p class="text-justify">Your subscription is scheduled to change at the end of your current billing period on <b>Mar 17</b>. You may cancel this change and remain on your current plan, or choose a different plan.</p>    
          <div style="margin-top: 1.5em;">
            <strong>Current Plan</strong>
            <div>${subscribedPlan?.planName} - ${convertSubcurrencyToMain(
          currentPlan?.currency,
          currentPlan?.amount
        )}</div>
          </div>
    
          <div style="margin-top: 1.5em;">
            <strong>Scheduled Plan</strong>
            <div>${scheduledPlan?.planName} - ${convertSubcurrencyToMain(
          scheduledPlan?.planCurrency,
          scheduledPlan?.planAmount
        )}</div>
          </div>
        `,
        confirmButtonColor: primaryColor,
        showCancelButton: true,
        confirmButtonText: "Cancel change",
        cancelButtonText: "Go back",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const result = await cancelScheduledChange(isScheduled); // Call your async function
            await queryClient.invalidateQueries({
              queryKey: ["subscription"],
            });
            return result; // Pass data to the `then` block of Swal
          } catch (error) {
            Swal.showValidationMessage(`Error: ${error.message}`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });

      return;
    }

    //Check if the plan is customizable
    if (isContactUs) {
      navigate(
        `/contact-us?plan=${planName}&amount=${intervalWiseAmount}&planId=${planId}&type=${
          isYearly ? "year" : "month"
        }&contactUs=true`
      );
      return;
    }

    //Check if the user is continue with the free plan
    if (planName === "Free" || planName === "King's") {
      navigate(`/createworkspace`);
      return;
    }

    //Check if the user wants to update the plan
    if (!subscribedPlan?.isTrialPlanAvailable) {
      navigate(
        `update/${planName.toLowerCase()}?plan=${planName}&amount=${intervalWiseAmount}&planId=${planId}&type=${
          isYearly ? "year" : "month"
        }&productId=${stripeProductId}`,
        {
          state: {
            features: planKeywords,
            stripeSubscription: subscribedPlan?.stripeJson,
            currentPlanName: subscribedPlan?.planName,
          },
        }
      );
      return;
    }

    //Check if the user is continue with the free trial period
    navigate(
      `${planName.toLowerCase()}?plan=${planName}&amount=${intervalWiseAmount}&planId=${planId}&type=${
        isYearly ? "year" : "month"
      }&productId=${stripeProductId}`,
      {
        state: {
          features: planKeywords,
        },
      }
    );
  };

  return (
    <Fragment>
      {isSamePlanScheduled && (
        <Alert
          icon={<ExclamationCircleIcon color="red" className="w-5 h-6" />}
          color="red"
          className="px-2 py-1 justify-center"
          variant="outlined"
        >
          <span className="text-xs">
            Active on{" "}
            {moment(scheduledPlan?.planStartDate)?.format("MMMM D, YYYY")}
          </span>
        </Alert>
      )}
      {(planName === "Free" || planName === "King's") && isFreeTrialPeriodAvailable && (
        <Button
          disabled={isCurrentPlan}
          className={` py-2 px-8 w-full rounded-md transition-all duration-300 ${
            isRecommended ? recommendedButton : notRecommendedButton
          }`}
          onClick={handleRedirection}
        >
          Continue with free
        </Button>
      )}
      {isSamePlanScheduled && (
        <Button
          className={` py-2 px-8 w-full rounded-md transition-all duration-300 ${
            isRecommended ? recommendedButton : notRecommendedButton
          }`}
          onClick={handleRedirection}
        >
          Cancel Schedule Change
        </Button>
      )}
      {planName !== "Free" && planName !== "King's" && !isSamePlanScheduled && (
        <Button
          className={` py-2 px-8 w-full shrink-0 rounded-md transition-all duration-300 ${
            isRecommended ? recommendedButton : notRecommendedButton
          }`}
          onClick={handleRedirection}
        >
          {isCurrentPlan && "Continue with Current Plan"}
          {!isCurrentPlan &&
            (isContactUs
              ? "Contact Us"
              : isFreeTrialPeriodAvailable
              ? "Start your free trial"
              : isDowngrade
              ? "Downgrade"
              : "Upgrade now")}
        </Button>
      )}
    </Fragment>
  );
};

export default ActionButton;
