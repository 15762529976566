import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import CustomizedSwitch from "../../Components/UI/Switch/CustomizedSwitch";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { usePersistedState } from "../../Services/usePersistedState";
import { useGetDebateCitationList } from "../../Web-Hooks/Debate/use-debate";
import { useUploadCitation } from "../../Web-Hooks/LibraryAPI/use-citation";
import DebateCitationList from "./DebateCitationList";
import { useSubworkspaceStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import { GetCopiedURL } from "../../api/debate/debate";

const DebateAddCitation = ({ argument,limitCitation }) => {
  const [allCitation, setAllCitation] = usePersistedState("AllCitation", []);

  const { debateInfo, citation, setCitation, handleStateUpdate } =
    useCreateDebateContext();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const [query] = useSearchParams();
  const debateId = query.get("debateId");
  const from = query.get("from");
  const {
    mutateAsync: uploadCitationOnServer,
    isPending: isUploadCitationPending,
  } = useUploadCitation();
  // console.log(citation,"citation")
  const { data: citationsFromDraft } = useGetDebateCitationList(debateId);

  const memoizedCitationListFromDraft = React.useMemo(() => {
    if (!citationsFromDraft) return [];

    return citationsFromDraft.map((c) => ({
      citationId: c?.citationId,
      citationUrl: c?.citationUrl,
      isCitationFromDatabase: c?.isCitationFromDatabase,
      citationNote: c?.citationNote,
      citationType: c?.citationFileType,
      citationSide: c?.bySide,
    }));
  }, [citationsFromDraft]);

  const debateUploadFilePath  = `${process.env.REACT_APP_DEBATE_UPLOAD}/${debateInfo?.workSpaceId || selectedWorkspace?.workSpaceId}/${debateInfo?.subWorkSpaceId || selectedSubWorkspace?.subWorkSpaceId}`


  useEffect(() => {
    if (from === "draft" || from === "pending") {
      if (memoizedCitationListFromDraft?.length > 0) {
        setCitation(memoizedCitationListFromDraft);
        localStorage.setItem(
          "Citation",
          JSON.stringify(memoizedCitationListFromDraft)
        );
        setAllCitation(memoizedCitationListFromDraft);
      }
    } else {
      return;
    }
  }, [
    debateId,
    memoizedCitationListFromDraft,
    setCitation,
    from,
    setAllCitation,
  ]);

  useEffect(() => {
    if (
      allCitation?.length &&
      debateInfo?.SideForDebate &&
      (from === "draft" || from === "pending")
    ) {
      const citationForSelectedSide = allCitation?.filter(
        (cite) =>
          cite?.citationSide?.toLowerCase() ===
          debateInfo?.SideForDebate?.toLowerCase()
      );
      setCitation(citationForSelectedSide);
      localStorage.setItem("Citation", JSON.stringify(citationForSelectedSide));
    }
  }, [debateInfo, from, setCitation]);

  const handleCitationSubmit = async (selectedCitation) => {
    selectedCitation.citationSide = debateInfo?.SideForDebate;

    const isAlreadyAdded = citation?.some(
      (cite) => cite?.citationUrl === selectedCitation?.citationUrl
    );
    if (isAlreadyAdded) {
      setCitation([...citation]);
      (from === "draft" || from === "pending") &&
        setAllCitation([...allCitation]);

      localStorage.setItem("Citation", JSON.stringify([...citation]));
      return;
    }
    if (selectedCitation?.citationFrom === "external") {
      setCitation([...citation, selectedCitation]);
      (from === "draft" || from === "pending") &&
        setAllCitation([...allCitation, selectedCitation]);
      return;
    }
    if (!selectedCitation.citationFile) {
      const isAlreadyAdded = citation?.some(
        (cite) => cite?.citationUrl === selectedCitation?.citationUrl
      );
      if (!isAlreadyAdded) {
        if (selectedCitation.citationType === "url") {
          setCitation([...citation, {...selectedCitation}]);
          localStorage.setItem(
            "Citation",
            JSON.stringify([...citation, {...selectedCitation}])
          );
          (from === "draft" || from === "pending") &&
            setAllCitation([...allCitation, {...selectedCitation}]);
          return;
        }
        const filePath = selectedCitation?.citationUrl?.split("debatemodule/")?.pop();
      const data = await GetCopiedURL(filePath, debateUploadFilePath)
      const newUrl = `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${debateUploadFilePath}/${data}`
        setCitation([...citation, {...selectedCitation, citationUrl: newUrl}]);
        localStorage.setItem(
          "Citation",
          JSON.stringify([...citation, {...selectedCitation, citationUrl: newUrl}])
        );
        (from === "draft" || from === "pending") &&
          setAllCitation([...allCitation, {...selectedCitation, citationUrl: newUrl}]);
      } else {
        setCitation([...citation]);
        (from === "draft" || from === "pending") &&
          setAllCitation([...allCitation]);

        localStorage.setItem("Citation", JSON.stringify([...citation]));
      }
      return;
    }
    const formdata = new FormData();
    formdata.append("file", selectedCitation.citationFile);
    formdata.append(
      "filePath",
      `${debateUploadFilePath}`
    );
    try {
      const { data } = await uploadCitationOnServer(formdata);
      setCitation([
        ...citation,
        {
          ...selectedCitation,
          citationUrl: data.data,
          citationSide: debateInfo.SideForDebate,
        },
      ]);
      (from === "draft" || from === "pending") &&
        setAllCitation([
          ...allCitation,
          {
            ...selectedCitation,
            citationUrl: data.data,
            citationSide: debateInfo.SideForDebate,
          },
        ]);

      localStorage.setItem(
        "Citation",
        JSON.stringify([
          ...citation,
          {
            ...selectedCitation,
            citationUrl: data.data,
            citationSide: debateInfo.SideForDebate,
          },
        ])
      );
    } catch (error) {
      toast.error("Error uploading citation", error);
    }
  };
  return (
    <div className="w-full">
      <div
        className={`flex sm:flex-row flex-col items-center ${
          debateInfo?.IsDebatePrivate || from === "pending"
            ? "justify-end"
            : "justify-between"
        } w-full`}
      >
        {debateInfo?.IsDebatePrivate || from === "pending" ? null : (
          <CustomizedSwitch
            value={debateInfo?.IsOtheruserAllow}
            onChange={(e) =>
              handleStateUpdate({
                ...debateInfo,
                IsOtheruserAllow: e.target.checked,
                IsPublishWithoutOpponent: e.target.checked,
              })
            }
            textClass={"text-debatePalette-shadow text-md"}
            circleClass={"bg-debatePalette-buttonBorder"}
            text={"Allow for open Debate"}
            containerClass={"bg-debatePalette-shadow"}
            mainClassName={"border-none"}
          />
        )}
        <CitationComponent
          from={
            from === "Motion"
              ? ["motion", "library", "gallary", "device", "external"]
              : ["library", "gallary", "device", "external"]
          }
          isLoadingFromHandleSubmit={isUploadCitationPending}
          handleCitationSubmit={async (file) =>
            await handleCitationSubmit(file)
          }
          debateDetails={debateInfo}
          citation={citation}
          key={"addArgument"}
          MenuHandlerComponent={
            <Button
              variant="text"
              className="px-3 py-2 min-w-fit flex gap-2 items-center justify-end"
              disabled={citation.length >= limitCitation || !debateInfo?.SideForDebate}
            >
              <PlusCircleIcon className="min-h-8 min-w-8 h-8 w-8 text-debatePalette-buttonBorder" />
              <span
                id="AddCitationToDebate"
                className="text-[#008BFA] text-nowrap text-base font-normal lowercase first-letter:uppercase"
              >
                Add a citation
              </span>
            </Button>
          }
        />
      </div>

      {citation.length > 0 && (
        <DebateCitationList
        allCitation={allCitation}
          citation={citation}
          setCitation={setCitation}
          argument={argument}
          setAllCitation={setAllCitation}
        />
      )}
    </div>
  );
};

export default DebateAddCitation;
