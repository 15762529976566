import { useQueryClient } from "@tanstack/react-query";
import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

export const SocketEvents = {
  JOIN_ROOM: "joinRoom",
  SEND_MESSAGE: "sendMessage",
  RECEIVE_MESSAGE: "receiveMessage",
  USER_STATUS: "userStatus",
  USER_LIST: "userList",
  REGISTER_USER: "registerUserId",
  UPDATE_USER_STATUS: "updateUserStatus",
  MARK_ALL_AS_READ: "markAsRead",
  MARKED_AS_READ: "markedAsRead",
  TYPING: "isTyping",
  GROUP_TYPING: "isGroupTyping",
  UPDATE_RECEIVER_DATA: "update-receiver-data",
  UPDATE_RECEIVER_TOTAL_UNREAD: "update-receiver-total-unread",
  DELETE_MESSAGE: "deleteMessage",
  DELETE_GROUP_MESSAGE: "deleteSenderMessageFromGroup",
  GET_DELETED_GROUP_MESSAGE: "groupMessageDeleted",
  GET_DELETED_MESSAGE: "messageDeleted",
  SEND_GROUP_MESSAGE: "sendGroupMessage",
  JOIN_GROUP: "joinGroup",
};

// Replace with your backend API URL
export const SOCKET_SERVER_URL = "http://localhost:3001/";

const SocketContext = createContext({
  socket: null,
  users: [],
  userStatus: [],
  initializeConnection: () => {},
  connect: () => {},
  disconnect: () => {},
  isConnected: () => {},
  joinRoom: () => {},
  sendMessage: () => {},
  readMessage: () => {},
  on: () => {},
  off: () => {},
  isTyping: () => {},
  isGroupTyping: () => {},
  deleteMessage: () => {},
  sendGroupMessage: () => {},
  deleteGroupMessage: () => {},
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const queryClient = useQueryClient();

  /**
   * Initialize the WebSocket connection with the user's ID and username.
   */
  const initializeConnection = (userId, username) => {
    if (socket && !socket.connected) {
      socket.connect();
      return;
    }

    if (!socket) {
      const newSocket = io(`${process.env.REACT_APP_CHAT_URL}/`, {
        query: { userId, username },
        transports: ["websocket"],
      });

      newSocket.on("connect", () => {
        registerUserId(userId);
      });

      setSocket(newSocket);
    }
  };

  /**
   * Connect the socket if not already connected.
   */
  const connect = () => {
    if (socket && !socket.connected) {
      socket.connect();
    }
  };

  /**
   * Disconnect the socket if connected.
   */
  const disconnect = () => {
    if (socket && socket.connected) {
      socket.disconnect();
    }
  };

  /**
   * Check if the socket is connected.
   */
  const isConnected = () => socket && socket.connected;

  /**
   * Register the user ID after connection.
   */
  const registerUserId = (userId) => {
    if (isConnected()) {
      emit(SocketEvents.REGISTER_USER, { userId });
    } else {
      socket?.on("connect", () => {
        emit(SocketEvents.REGISTER_USER, { userId });
      });
    }
  };

  /**
   * Emit an event with optional data.
   */
  const emit = (event, data) => {
    if (socket && socket.connected) {
      socket.emit(event, data);
    }
  };

  /**
   * Listen to events and return a subscription.
   */
  const on = (event, callback) => {
    if (socket && socket.connected) {
      socket.on(event, callback);
    }
  };

  const off = (event, callback) => {
    if (socket && socket.connected) {
      socket.off(event, callback);
    }
  };

  /**
   * Send a message to a specific user.
   */
  const sendMessage = async (
    senderId,
    receiverId,
    content,
    workspaceId,
    subworkspaceId
  ) => {
    emit(SocketEvents.SEND_MESSAGE, {
      senderId,
      receiverId,
      content,
      workspaceId,
      subworkspaceId,
    });
    // Invalidate the message list query key
    setTimeout(async () => {
      await queryClient.invalidateQueries({
        queryKey: ["user-workspace-list"],
      });
    }, 2000);
  };

  const sendGroupMessage = async (
    groupId,
    senderId,
    content,
    workspaceId,
    subworkspaceId
  ) => {
    emit(SocketEvents.SEND_GROUP_MESSAGE, {
      groupId,
      senderId,
      content,
      workspaceId,
      subworkspaceId,
    });
    // Invalidate the message list query key
    setTimeout(async () => {
      await queryClient.invalidateQueries({
        queryKey: ["group","list","chats"],
      });
    }, 2000);
  };

  const deleteMessage = async (senderId, receiverId, messageId) => {
    emit(SocketEvents.DELETE_MESSAGE, { senderId, receiverId, messageId });
  };
  const deleteGroupMessage = async (messageId,groupId) => {
    emit(SocketEvents.DELETE_GROUP_MESSAGE, { messageId, groupId });
  };

  /**
   * Notify that the user is typing.
   */
  const isTyping = (senderId, receiverId, isTyping) => {
    emit(SocketEvents.TYPING, { senderId, receiverId, isTyping });
  };
  const isGroupTyping = (groupId, userId, isTyping) => {
    emit(SocketEvents.GROUP_TYPING, { groupId, userId, isTyping });
  };

  /**
   * Join a specific chat room.
   */
  const joinRoom = (senderId, receiverId) => {
    emit(SocketEvents.JOIN_ROOM, { senderId, receiverId });
  };

  /**
   * Mark messages as read.
   */
  const readMessage = (senderId, receiverId) => {
    emit(SocketEvents.MARK_ALL_AS_READ, { senderId, receiverId });
  };

  /**
   * Subscribe to socket events (like user list and status).
   */
  useEffect(() => {
    if (socket) {
      socket.on(SocketEvents.USER_LIST, (data) => setUsers(data));
      socket.on(SocketEvents.USER_STATUS, (data) => setUserStatus(data));

      // Clean up listeners on unmount or when socket changes
      return () => {
        socket.off(SocketEvents.USER_LIST);
        socket.off(SocketEvents.USER_STATUS);
      };
    }
  }, [socket]);

  const contextValue = {
    socket,
    users,
    userStatus,
    initializeConnection,
    connect,
    disconnect,
    isConnected,
    sendMessage,
    joinRoom,
    readMessage,
    on,
    off,
    deleteMessage,
    isTyping,
    sendGroupMessage,
    deleteGroupMessage,
    isGroupTyping
  };

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};
