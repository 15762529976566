import { useQueryClient } from "@tanstack/react-query";
import "animate.css";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useUserStore, useWorkspaceStore } from "../../Context/store";
import { useGetSubscriptionPlans } from "../../Web-Hooks/Subscription/use-subscription";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const plan = search.get("plan"); // Default plan name
  const amount = search.get("amount");
  const planId = search.get("planId");
  const queryClient = useQueryClient();
  const subType = search.get("subType");
    const { user } = useUserStore();
  
  const { data: subscriptionPlans, isLoading } = useGetSubscriptionPlans(user?.userId);
  const updatedPlan = subscriptionPlans?.find((p) => p.planId === planId);
  const { selectedWorkspace } = useWorkspaceStore();

  const handleRedirectToFeed = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["subscription"],
    });
    if (selectedWorkspace) {
      navigate("/feed-public", { replace: true });
      return;
    }
    navigate("/createworkspace", { replace: true });
  };

  if (isLoading || !planId || !plan || !amount) {
    return <LoadingSpinner />;
  }

  const feature = updatedPlan?.planKeywords?.filter(
    (feature) => feature.included
  );

  const renderMessage = (subType) => {
    if (subType === "downgrade") {
      return (
        <p className="text-gray-600 px-8 xl:px-20 text-justify">
          You’ve successfully scheduled a downgrade to <strong>{plan}</strong>.
          Your new plan will take effect at the end of your current billing
          period.
        </p>
      );
    }
    if (subType === "upgrade") {
      return (
        <p className="text-gray-600 px-8 xl:px-20 text-justify">
          You’ve successfully upgraded to <strong>{plan}</strong>. Enjoy your
          new features immediately!
        </p>
      );
    }
    return (
      <p className="text-gray-600 px-8 xl:px-20 text-justify">
        You’ve successfully subscribed to <strong>{plan}</strong>. Start
        enjoying your new debating tools and features.
      </p>
    );
  };

  return (
    <div className="flex items-center justify-center lg:h-screen w-full bg-gray-100">
      <div className="flex flex-col lg:flex-row bg-white  w-[90%] xl:w-[70%] h-[70%] rounded-lg shadow-xl">
        {/* Left Section */}
        <div className="flex-1 p-8 flex flex-col gap-12 items-center justify-center border-b lg:border-b-0 lg:border-r border-gray-200">
          <div className="flex items-center justify-center w-16 h-16 bg-green-100 rounded-full animate__animated animate__bounceIn mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-full h-full text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4M9 12l2 2 4-4"
              />
            </svg>
          </div>
          <div className="flex flex-col gap-3 justify-center items-center">
            <h2 className="text-2xl font-semibold px-6 text-center text-green-700 ">
              Your subscription is confirmed!
            </h2>
            {renderMessage(subType)}
          </div>
          <button
            className=" px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow hover:bg-green-400 transition"
            onClick={handleRedirectToFeed}
          >
            Start Using Features
          </button>
        </div>

        {/* Right Section */}
        <div className="flex-1 p-8 rounded-r-lg flex flex-col gap-12 justify-center overflow-y-auto bg-gray-50">
          <div className="px-6">
            <h3 className="text-lg font-semibold text-debatePalette-title">
              Subscription Summary
            </h3>
            <div className="mt-4">
              <div className="flex justify-between text-gray-600 mb-2">
                <span>Plan:</span>
                <span>{plan}</span>
              </div>
              <div className="flex justify-between text-gray-600 mb-2">
                <span>Amount:</span>
                <span>${amount}</span>
              </div>
              <div className="mt-6">
                <h4 className="text-debatePalette-title font-semibold">
                  New Features:
                </h4>
                <ul className="mt-4 space-y-3 grid grid-cols-2 gap-1 text-gray-600">
                  {feature?.map((feature, index) => (
                    <li className="flex items-center" key={index}>
                      <span className="inline-block w-4 h-4 mr-2 bg-green-500 rounded-full"></span>
                      {feature.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
