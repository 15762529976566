import React, { useState } from "react";
import DebateAddCitation from "../DebateAddCitation";
import { ReactComponent as AILogo } from "../../../Assets/Svg/AILogo.svg";
import AICitationSuggestion from "../../../Components/Functionality/AICitationSuggestion";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import useDebounce from "../../../Services/useDebounce";
import { useCitationSuggestAgent } from "../../../Web-Hooks/Agents/use-citation-agent";
import { CITATION_SUGGESTION } from "../../../Utils/Constant";
import { useNavigate } from "react-router-dom";
import { handleAIUpgradePlan } from "../../../Services/SweetAlert";
import { usePlan } from "../../../Context/PlanContext";
import { Button } from "@material-tailwind/react";

const AddCitationWithAI = () => {
  const { debateInfo, handleStateUpdate, citation } = useCreateDebateContext();
  const argument = useDebounce(debateInfo.CreatorOpeningArgument, 1000);
  const [showAIValidator, setShowAIValidator] = useState(false);
  const [activeBlinkingButton, setActiveBlinkingButton] = useState();
  const { currentPlan } = usePlan();
  const navigate = useNavigate();
  const { data: citationAgent, isLoading: isCitationLoading } =
    useCitationSuggestAgent(argument, currentPlan?.aiFeature);

  const handleCitationSuggester = () => {
    if (!currentPlan?.aiFeature) {
      handleAIUpgradePlan(CITATION_SUGGESTION, () =>
        navigate(`/upgrade-plan?plan=debato-plan`)
      );
      return;
    }
    setShowAIValidator(true);
  };

  return (
    <div>
      <AICitationSuggestion
        open={showAIValidator}
        debateInfo={debateInfo}
        handleClose={() => setShowAIValidator(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => setActiveBlinkingButton(false)}
        setActiveBlinkingButton={setActiveBlinkingButton}
        argument={argument}
        citationAgent={citationAgent}
        isLoading={isCitationLoading}
      />
      <div
        className="flex flex-row items-center  w-full"
        onMouseEnter={() => setActiveBlinkingButton(true)}
        onMouseLeave={() => setActiveBlinkingButton(false)}
      >
        <div className="flex flex-col items-end w-full">
          {argument && citation.length < currentPlan?.maxCitationCount && (
            <Button
              size="sm"
              variant="outlined"
              id="add-citation-with-ai"
              onClick={handleCitationSuggester}
              onMouseEnter={() => setActiveBlinkingButton(true)}
              onMouseLeave={() => setActiveBlinkingButton(false)}
              className={` flex items-center p-1 mb-2 justify-end self-end gap-2 border-[#7647FC] -pl-8`}
            >
              <AILogo className="h-6 w-6" />
              {activeBlinkingButton && (
                <span
                  className="text-[#7647FC] font-semibold animate__animated animate__fadeIn animate__faster"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Citation Suggestion
                </span>
              )}
            </Button>
          )}
          <DebateAddCitation
            argument={argument}
            limitCitation={currentPlan?.maxCitationCount}
          />

          {/* {isButtonBlinking("Citation") && (
                <CheckCircleIcon color="green" className="h-7 w-7" />
              )} */}
        </div>
      </div>
    </div>
  );
};

export default AddCitationWithAI;
