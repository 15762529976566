import React, { useState } from "react";
import { useUserStore } from "../../../Context/store";
import {
  useGetDebateCitationList,
  useGetDebateDetailsPage,
  useGetUserDebateArgumentList,
} from "../../../Web-Hooks/Debate/use-debate";
import CitationTagList from "../../Listing/CitationTagList";
import ReusableTabStructure from "./ReusableTabStructure";
import { useSearchParams } from "react-router-dom";
import { usePlan } from "../../../Context/PlanContext";

const CitationListTab = ({ debate, type, argumentId, argumentType, count }) => {
  const [query] = useSearchParams();
  const round = query.get("round");
  const { user } = useUserStore();
  const { data: citationList, isLoading: isCitationListLoading } =
    useGetDebateCitationList(debate?.debateId);

  const { data: debateDetails } = useGetDebateDetailsPage(
    debate?.debateId,
    round ? round : debate?.currentRound,
    "all",
    user?.userId
  );
  const { currentPlan } = usePlan();
  const { data: argumentList } = useGetUserDebateArgumentList(
    user?.userId,
    debate?.debateId,
    debate?.debateType === "eonian",
    currentPlan?.maxCitationCount
  );
  const debateDetailsArguments = debateDetails?.filterArguments;

  const userArguments = debateDetailsArguments?.find((argument) => {
    return argument?.userId === user?.userId;
  });
  

  const citationListLength = userArguments?.citationList?.length; // user added citation with argument

  const filteredCitation = citationList?.filter((citation) => {
    return citation?.createdByUserId === user?.userId;
  });
  const filteredArgumentCitation = argumentList?.find((argument) => {
    //Eonian debate check find the argument that user has selected
    return (
      argument?.argumentId === argumentId ||
      argument?.invitationId === argumentId
    );
  });

  const loggedInUserSide = debate?.loginUserSide;

  const disableAddCitationForEonian = filteredArgumentCitation ? false : true;

  const roundWiseCitationList = filteredCitation?.filter((citation) => {
    return citation?.round === (parseInt(round) || debate?.currentRound);
  });

  const disableAddCitation =
    citationListLength >= currentPlan?.maxCitationCount;

  const disableAddCitationAfterArgumentAdd =
    roundWiseCitationList?.length >= currentPlan?.maxCitationCount; // user added citation roundwise

  const agreeList = citationList?.filter((citation) => {
    return citation?.bySide?.toLowerCase() === "agree";
  });
  const disagreeList = citationList?.filter((citation) => {
    return citation?.bySide?.toLowerCase() === "disagree";
  });

  const agreeDisable =
    loggedInUserSide?.toLowerCase() === "agree"
      ? disableAddCitation
      : loggedInUserSide === ""
      ? disableAddCitationAfterArgumentAdd
      : true;

  const disagreeDisable =
    loggedInUserSide?.toLowerCase() === "disagree"
      ? disableAddCitation
      : loggedInUserSide === ""
      ? disableAddCitationAfterArgumentAdd
      : true;

  const agreeDisableEonian =
    loggedInUserSide?.toLowerCase() === "agree"
      ? disableAddCitationForEonian
      : true;
  const disagreeDisableEonian =
    loggedInUserSide?.toLowerCase() === "disagree"
      ? disableAddCitationForEonian
      : true;

  const TabOptions = [
    {
      label: "Agree",
      value: "agree",
      Component: (
        <CitationTagList
          round={parseInt(round) || debate?.currentRound}
          side={"agree"}
          citationList={agreeList}
          isCitationListLoading={isCitationListLoading}
          motion={debate}
          type={type}
          argumentId={argumentId}
          argumentType={argumentType}
          userArguments={debateDetails?.filterArguments}
          disableAddCitation={
            debate?.debateType === "eonian" ? agreeDisableEonian : agreeDisable
          }
        />
      ),
    },
    {
      label: "Disagree",
      value: "disagree",
      Component: (
        <CitationTagList
          round={parseInt(round) || debate?.currentRound}
          side={"disagree"}
          citationList={disagreeList}
          isCitationListLoading={isCitationListLoading}
          motion={debate}
          type={type}
          argumentId={argumentId}
          argumentType={argumentType}
          userArguments={debateDetails?.filterArguments}
          disableAddCitation={
            debate?.debateType === "eonian"
              ? disagreeDisableEonian
              : disagreeDisable
          }
        />
      ),
    },
  ];

  const [selected, setSelected] = useState("agree");

  const isRoundCompleted = debate?.roundStatus === "completed";

  return (
    <div className="flex flex-col gap-3">
      {isRoundCompleted && (
        <p className="text-sm text-debatePalette-timer font-semibold">
          Round is completed and you cannot add citations
        </p>
      )}
      {argumentList?.length === 0 && !userArguments && debate?.debateType === "eonian" && (
        <p className="text-sm text-debatePalette-timer font-semibold">
          You have to add an argument first
        </p>
      )}
      {!isRoundCompleted &&
      disableAddCitationForEonian &&
        userArguments &&
        debate?.debateType === "eonian" && (
          <p className="text-sm text-debatePalette-timer font-semibold">
            You have already added {currentPlan?.maxCitationCount} citations
          </p>
        )}
      {!isRoundCompleted &&
        (loggedInUserSide !== ""
          ? disableAddCitation
          : disableAddCitationAfterArgumentAdd) &&
        debate?.debateType !== "eonian" && (
          <p className="text-sm text-debatePalette-timer font-semibold">
            You have already added {currentPlan?.maxCitationCount} citations
          </p>
        )}
      <ReusableTabStructure
        Options={TabOptions}
        selected={selected}
        setSelected={setSelected}
        type="outlined"
      />
    </div>
  );
};

export default CitationListTab;
