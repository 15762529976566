import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Copy } from "../../Assets/Svg/Copy.svg";
import { useUserStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import LibraryCard, { formatCitation } from "../../Pages/Library/LibraryCard";
import { LibrarySortByOptions } from "../../Utils/Constant";
import { useGetCategoriesByWorkspaceId } from "../../Web-Hooks/Categories/use-categories";
import { useGetCitationUserWise } from "../../Web-Hooks/LibraryAPI/use-citation";
import SortByFilter from "../../Components/Functionality/SortByFilter";
import NoData from "../../Components/Functionality/NoData";

const LibraryPopup = ({
  setSelectedCitation,
  libraryPopup,
  setLibraryPopup,
  handleSubmit,
}) => {
  const { user } = useUserStore();
  const [query] = useSearchParams();
  const sortByDefault = query.get("sortBy");
  const { selectedWorkspace } = useWorkspaceStore();
  const [option, setOption] = useState(
    () =>
      LibrarySortByOptions.find((option) =>
        option.value.includes(sortByDefault)
      ) || {
        label: "Most Recent",
        value: "",
      }
  );
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    label: "Arts",
    value: "Arts",
  });

  const { data } = useGetCitationUserWise(
    user?.userId,
    selectedCategory?.value,
    "",
    option?.value
  );

  const citationList = data?.citations;

  const { data: categoryList } = useGetCategoriesByWorkspaceId(
    selectedWorkspace?.workSpaceId
  );

  const CategoryOptions = useMemo(() => {
    return categoryList?.map((category) => ({
      label: category?.categoryName,
      value: category?.categoryName,
    }));
  }, [categoryList]);

  useEffect(() => {
    const getCitationOption = () => {
      return [
        {
          label: "Not Selected",
          value: "",
        },
        ...CategoryOptions,
      ];
    };
    if (libraryPopup) {
      const initialOption = LibrarySortByOptions.find((option) =>
        option.value.includes(sortByDefault)
      ) || {
        label: "Most Recent",
        value: "recent",
      };
      setOption(initialOption);
      if (CategoryOptions) {
        setCategories(getCitationOption());
      }
    }
  }, [libraryPopup, sortByDefault, CategoryOptions]);

  const Actions = [
    {
      SVG: Copy,
      title: "Copy",
      handleClick: (citation) => {
        const { title, description } = formatCitation(citation);

        setSelectedCitation({
          citationUrl: citation?.refUrl,
          citationNote: citation?.note,
          citationType: citation?.refFileType,
          citationSize: citation?.size,
          citationTitle: title,
          citationDescription: description,
        });
        handleSubmit();
      },
    },
  ];

  return (
    <Dialog open={libraryPopup} handler={setLibraryPopup}>
      <DialogHeader className="flex items-center justify-between">
        <Typography variant="h4">Select Citation</Typography>
        <Button
          id="close-modal"
          variant="text"
          className="p-2"
          onClick={() => {
            setLibraryPopup(false);
          }}
        >
          <XMarkIcon className="h-6 w-6  text-debatePalette-title" />
        </Button>
      </DialogHeader>
      <DialogBody className="flex h-full flex-col gap-4">
        <div className="w-full flex gap-2 justify-end">
          <SortByFilter
            id={"category"}
            title="Category By"
            Options={categories}
            setOption={setSelectedCategory}
            defaultValue={selectedCategory}
          />
          <SortByFilter
            id={"sortBy"}
            Options={
              sortByDefault === "image"
                ? [
                    {
                      label: "Photos",
                      value: ["png", "jpg", "jpeg", "webp", "image", "svg"],
                    },
                  ]
                : LibrarySortByOptions
            }
            setOption={setOption}
            defaultValue={option}
          />
        </div>
        <div
          className={`flex flex-col gap-2 min-h-[400px] ${
            citationList?.length > 0 ? "max-h-64" : "max-h-64"
          } overflow-y-auto`}
        >
          {citationList?.length > 0 ? (
            citationList?.map((citation, i) => (
              <LibraryCard
                Actions={Actions}
                key={citation.id}
                Citation={citation}
              />
            ))
          ) : (
            <div className="h-[400px]">
              <NoData
                message={"No citations found"}
                isNoData={true}
                size={"w-full h-[80%]"}
              />
            </div>
          )}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default LibraryPopup;
