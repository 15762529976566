import React from "react";
import Swal from "sweetalert2";
import {
  useCancelSchedule,
  useCancelSubscription,
} from "../../Web-Hooks/Subscription/use-subscription";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@material-tailwind/react";
import moment from "moment";
import { primaryColor } from "../../Utils/Constant";

const CancelActionButton = ({ plan, isYearly, isCurrentPlan, subscribedPlan }) => {
  const queryClient = useQueryClient();
  const { isRecommended, planName, monthlyAmonut, yearlyAmonut } = plan;
  const intervalWiseAmount = isYearly ? yearlyAmonut : monthlyAmonut;
  const typeForCancellation = isYearly ? "per year" : "per month";
  const isScheduled = subscribedPlan?.stripeJson?.schedule;
  const isCanceledAtPeriodEnd =
    subscribedPlan?.stripeJson?.cancel_at_period_end;
  const { mutateAsync: cancelSubscription } = useCancelSubscription();
  const { mutateAsync: cancelScheduledChange } = useCancelSchedule();

  const billingPeriodEndDate = moment(
    subscribedPlan?.planEndDate || new Date()
  ).format("MMMM D, YYYY");

  const handleCancelSubscription = () => {
    if (isCanceledAtPeriodEnd) {
      const newWindow = window.open(
        "https://billing.stripe.com/p/login/test_eVa14k41L2Vj7hS8ww",
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
      return;
    }
    Swal.fire({
      titleText: `${planName}`,
      iconColor: primaryColor,
      html: `<div class="flex flex-col gap-4">        
            <p class="text-2xl font-bold">
              $${intervalWiseAmount.toFixed(2)} ${typeForCancellation}
            </p>
            <div class="flex flex-col gap-4">
                    <p class="text-base text-justify">
              Your subscription will be canceled, but is still available until the
              end of your billing period on ${billingPeriodEndDate}.
            </p>
            <p class="text-base text-justify">
              If you change your mind, you can renew your subscription from the manage subscription page.
          <a href="https://billing.stripe.com/p/login/6oEdSzabUaQHdLacMM" target="_blank" class="text-secondary font-semibold hover:underline">(Customer Portal)</a>
            </p>
            </div>
          </div>`,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          if (isScheduled) {
            await cancelScheduledChange(isScheduled); // Call your async function
          }
          const result = await cancelSubscription(
            subscribedPlan?.subscriptionId
          ); // Call your async function
          await queryClient.invalidateQueries({
            queryKey: ["subscription"],
          });
          return result; // Pass data to the `then` block of Swal
        } catch (error) {
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: primaryColor,
      confirmButtonText: "Yes, cancel it!",
    });
  };

  if (!isCurrentPlan || planName === "Free" || planName === "King's") {
    return null;
  }

  return (
    <Button
      className={`${
        isCanceledAtPeriodEnd
          ? isRecommended
            ? "bg-primary text-secondary"
            : "bg-secondary"
          : "bg-red-600 "
      } py-2 px-8 w-full rounded-md transition-all duration-300`}
      onClick={handleCancelSubscription}
    >
      {isCanceledAtPeriodEnd ? "Renew Subscription" : "Cancel Subscription"}
    </Button>
  );
};

export default CancelActionButton;
