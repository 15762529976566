import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as EditPencil } from "../../Assets/Svg/EditPencil.svg";
import AddSubWorkspacePopup from "../../Components/Popup/AddSubWorkspacePopup";
import EditSubDebatePopup from "../../Components/Popup/EditSubDebatePopup";
import { usePlan } from "../../Context/PlanContext";
import { useSubworkspaceStore, useUserStore, useWorkspaceStore } from "../../Context/store";
import MessageToggleNav from "./MessageToggleNav";
import PublicFeedBar from "./PublicFeedBar";
import SemiFeedBar from "./SemiFeedBar";

export default function FeedSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const { user: loggedInUser } = useUserStore();
  const [toggle, setToggle] = useState();
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace, selectSubWorkspace, subWorkspaceList } = useSubworkspaceStore();


  const { currentPlan } = usePlan();
  const [openEditWorkspace, setEditWorkspacePopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const pilotPlanRestriction =
    currentPlan.createForum &&
    loggedInUser?.role === "Admin" &&
    subWorkspaceList?.length < currentPlan.maxCreateForumCount;

  return (
    <div className={`hidden bg-secondary h-full rounded-l-lg md:block w-full`}>
      <div className="h-16 px-4 flex justify-between items-center ">
        {pathname !== "/createprofile" && (
          <Menu
            ripple={false}
            placement="bottom-start"
            open={toggle}
            handler={() => setToggle(!toggle)}
          >
            <MenuHandler>
              <div className="flex w-[80%]">
                <Button
                  ripple={false}
                  variant="text"
                  className="flex p-0 gap-2 justify-start w-full items-center font-normal capitalize tracking-normal hover:bg-transparent active:bg-transparent"
                >
                  <span className="flex-1 xs:line-clamp-1 w-full text-lg text-left text-debatePalette-background">
                    {selectedWorkspace?.WorkSpaceName ||
                      selectedWorkspace?.workSpaceName}
                  </span>
                  <ChevronDownIcon
                    strokeWidth={2.5}
                    className={`h-3.5 w-3.5 transition-transform mt-1 text-debatePalette-background ${
                      toggle ? "rotate-180" : ""
                    }`}
                  />
                </Button>
              </div>
            </MenuHandler>
            <Tooltip content="Edit debatebase">
              <EditPencil
                className="h-6 w-6 text-white cursor-pointer hover:text-debatePalette-buttonBorder"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditWorkspacePopup(true);
                }}
              />
            </Tooltip>
            <MenuList>
              {subWorkspaceList?.map((subWorkspace, index) => (
                <MenuItem
                  className="flex gap-3 p-2 text-debatePalette-title font-medium"
                  key={index}
                  onClick={() => {
                    selectSubWorkspace(subWorkspace);
                  }}
                >
                  {subWorkspace?.subWorkSpaceName}
                  {/* {<ChevronRightIcon className="h-4 w-4" />} */}
                </MenuItem>
              ))}
              {pilotPlanRestriction ? (
                <MenuItem
                  className="flex gap-3 p-2 text-debatePalette-title font-medium"
                  onClick={() => setPopup(true)}
                >
                  Create Forum {<ChevronRightIcon className="h-4 w-4" />}
                </MenuItem>
              ) : (
                subWorkspaceList?.length === 0 && (
                  <MenuItem
                    disabled={true}
                    className="flex gap-3 p-2 text-debatePalette-title font-medium"
                  >
                    No forum
                  </MenuItem>
                )
              )}
            </MenuList>
          </Menu>
        )}

        {/* <ButtonWithIcon
                  SVG={PencilSquareIcon}
                  BtnHeight={"h-10"}
                  BtnWidth={"w-10"}
                  color={"hover:!text-primary"}
                /> */}
      </div>
      {pathname !== "/createprofile" && (
        <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
      )}
      <div className=" h-[calc(100vh-9.1rem)] overflow-y-auto sidebar-scroller">
        <PublicFeedBar
          loggedInUser={loggedInUser}
          pathname={pathname}
          selectedWorkspace={selectedWorkspace}
          currentPlan={currentPlan}
        />
        {selectedSubWorkspace && (
          <div className="px-4 flex justify-between items-center">
            <div
              key={selectedSubWorkspace?.subWorkSpaceId}
              className={` py-2 rounded-none text-lg truncate text-ellipsis scrollbar-hide font-semibold text-debatePalette-background `}
            >
              {/* <ListItemPrefix> <InboxIcon className="h-5 w-5 text-white" /> </ListItemPrefix> */}
              {selectedSubWorkspace?.subWorkSpaceName}
              {/* <ListItemSuffix> <Chip value="14" size="sm" variant="ghost" color="white" className="rounded-full"/> </ListItemSuffix> */}
            </div>
            <Tooltip content="Edit forum">
              <EditPencil
                className="h-6 shrink-0 w-6 text-white cursor-pointer hover:text-debatePalette-buttonBorder"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenPopup(true);
                }}
              />
            </Tooltip>
          </div>
        )}
        <SemiFeedBar
          loggedInUser={loggedInUser}
          pathname={pathname}
          selectedSubWorkspace={selectedSubWorkspace}
          currentPlan={currentPlan}
        />

        <MessageToggleNav currentPlan={currentPlan} />
      </div>
      <EditSubDebatePopup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
      />
      <AddSubWorkspacePopup
        handleClose={() => setPopup(false)}
        setPopup={setPopup}
        open={popup}
      />
      <EditSubDebatePopup
        popupFor="workspace"
        open={openEditWorkspace}
        handleClose={() => setEditWorkspacePopup(false)}
      />
    </div>
  );
}
