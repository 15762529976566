import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signUp_V1 } from "../../api/auth/authApi";
import betaLogo from "../../Assets/Images/Beta.png";
import Logo from "../../Assets/Svg/Logo.svg";
import SignupFooter from "../../Components/Functionality/SignupFooter";
import UserAvtarUpload from "../../Components/UI/AvatarStack/UserAvtarUpload";
import { useWorkspaceStore } from "../../Context/store";
import BasicInputField from "../../Layout/BasicInputField";
import {
  hoverTextStyle,
  LoginInfo,
  primaryColor,
  secondaryColor,
  UserAgreement,
  UserAgreementDecline,
} from "../../Utils/Constant";
import { getInitials } from "../../Utils/Helper";
import Swal from "sweetalert2";
import { getDataINSTANCE } from "../../api/controller";
import { USER_CHECK } from "../../api/apiRoutes";

export default function SignUpMain() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    Email: "",
    Username: "",
    picture: "",
    profilePicture: "",
  });
  const [query] = useSearchParams();
  const Email = query?.get("Email");
  const Username = query?.get("Username");
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const { selectWorkspace } = useWorkspaceStore();
  const to = query?.get("to");

  useEffect(() => {
    selectWorkspace(null);
    localStorage.clear();
    if (Email) {
      setUser({ Email: Email });
    } // eslint-disable-next-line
  }, []);

  const { mutateAsync: signUp_mutate_V1, isPending } = useMutation({
    mutationFn: async (formData) => {
      return await signUp_V1(formData);
    },
    onSuccess: (response) => {
      if (response?.error) {
        toast?.error(response?.error?.message);
        navigate(`/?Email=${user.Email}${to ? `&to=${to}` : ""}`);
        return;
      }
      if (response?.data) {
        const userData = {
          username: user.Username,
          email: user.Email,
          Initials: getInitials(user.Username),
        };
        toast?.success(response?.data?.message);
        navigate(
          `/otp-login?Email=${user.Email}&userId=${response?.data?.userId}&isTrialPlanAvailable=${response?.data?.isTrialPlanAvailable}${to ? `&to=${to}` : ""}`,
          { state: { userData: userData }, replace: true }
        );
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // SignUpp btn Function
  const signUpFn = async (e) => {
    e.preventDefault();

    if (user.Email && user.Username) {
      const formValues = {
        Email: user?.Email,
        UserName: user?.Username,
        UserImage: user?.picture,
      };

      const formData = new FormData();
      for (const [key, value] of Object.entries(formValues)) {
        formData.append(key, value);
      }

      const checkUser = async () => {
        const data = await getDataINSTANCE(
          `${USER_CHECK}?Email=${formValues?.Email}`,
          "Failed to fetch Sub Workspaces Users"
        );
        return data;
      };

      const data = await checkUser();
      if (data?.isUserExist) {
        navigate(`/?Email=${user.Email}${to ? `&to=${to}` : ""}`);
      }

      if (!data?.isUserExist) {
        Swal.fire({
          title: "Beta Program Agreement",
          customClass: {
            title: "font-semibold text-gray-900",
          },
          width: 700,
          html: UserAgreement,
          showDenyButton: true,
          confirmButtonText: "I Accept",
          denyButtonText: "Cancel",
          confirmButtonColor: primaryColor,
          denyButtonColor: secondaryColor,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              formData.append("UserAgreement", true);
              await signUp_mutate_V1(formData);
            } catch (error) {
              Swal.showValidationMessage(`Error: ${error.message}`);
            }
          },
          preDeny: async () => {
            Swal.fire({
              customClass: {
                title: "font-semibold text-gray-900",
              },
              icon: "error",
              title: "Registration Required",
              html: UserAgreementDecline,
            });
          },
          allowOutsideClick: false,
        });
      }
    } else if (!user.Email) {
      setEmailError(true);
    } else {
      setUsernameError(true);
    }
  };

  // Form Change Event
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (name === "Email") {
      // Check if the email is valid
      if (!emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }

    if (name === "Username") {
      if (value === "") {
        setUsernameError(true);
      } else {
        setUsernameError(false);
      }
    }

    // Update user state
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateUserImage = (file, url) => {
    setUser((prevValues) => ({
      ...prevValues,
      profilePicture: url,
    }));
    setUser((prevValues) => ({
      ...prevValues,
      picture: file,
    }));
  };

  return (
    <section className="flex justify-center items-center md:h-screen bg-white">
      <Card className=" flex-col justify-center md:max-h-screen overflow-y-auto shadow-none gap-2 px-4 md:px-10 py-3  border border-debatePalette-buttonBorder">
        <img
          src={betaLogo}
          alt=""
          className="absolute top-0 left-0"
          style={{ width: 100, height: 100 }}
        />
        {/* Card Header with Logo */}
        <CardHeader
          variant="gradient"
          color="transparent"
          className="grid max-h-14 md:max-h-20 mt-2 shrink-0  place-items-center shadow-none !static"
        >
          <img src={Logo} alt="" className="max-h-14 shrink-0 md:max-h-20" />
        </CardHeader>
        <CardBody className="flex flex-col gap-4 p-0">
          {/* TagLines */}
          <div className="flex flex-col text-center justify-center items-center gap-2">
            <Typography className="text-2xl md:text-5xl text-debatePalette-title font-semibold">
              {LoginInfo.headLine}
            </Typography>
            <Typography variant="small" className="text-debatePalette-bodyText">
              {LoginInfo.subLine}
            </Typography>
          </div>
          {/* Email Input */}
          <form className="max-w-[100%] my-1" onSubmit={signUpFn}>
            <div className="flex flex-col w-full justify-center items-center gap-4 p-0">
              <UserAvtarUpload
                setPicture={updateUserImage}
                picture={user?.profilePicture}
                userName={user?.Username || Username}
                size={24}
              />
              {!user?.profilePicture && (
                <label className="text-debatePalette-title font-semibold ">
                  Choose a profile picture
                </label>
              )}
              <div className="mb-1 flex flex-col w-full md:w-2/3 gap-6">
                <BasicInputField
                  required={false}
                  label="Username"
                  value={user?.Username}
                  name="Username"
                  fieldSize="lg"
                  placeholder="John Doe"
                  handleChange={handleChange}
                  // className={"!border-debatePalette-buttonBorder"}
                  emailError={usernameError}
                  type="text"
                  errorMessage="Please enter username e.g.(John doe)"
                  autoComplete="name"
                  minLength={3}
                  maxLength={16}
                />
                <BasicInputField
                  label="Email"
                  value={user?.Email}
                  name="Email"
                  fieldSize="lg"
                  placeholder="name@workemail.com"
                  handleChange={handleChange}
                  // className={"!border-debatePalette-buttonBorder"}
                  emailError={emailError}
                  type="email"
                  errorMessage="Please enter valid email address"
                  autoComplete="email"
                />
                {/* Continue Button */}
                <Button
                  id="signupBtn"
                  disabled={emailError || !user?.Email}
                  loading={isPending}
                  type="submit"
                  variant="gradient"
                  fullWidth
                  color="amber"
                  className="text-black text-md inline-flex items-center justify-center w-full"
                >
                  Continue
                </Button>
                <div className="inline-flex items-center justify-center w-full">
                  <hr className="w-full h-[1.5px] my-2 bg-debatePalette-hr border-0 dark:bg-gray-700" />
                  <span className="absolute px-3 font-medium text-debatePalette-hr -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
                    OR
                  </span>
                </div>
                <div className="pt-0 flex flex-col  w-full justify-center items-center mb-2">
                  <Typography variant="small" className=" flex justify-center">
                    Already have an account?
                    <Button
                      id="loginBtn"
                      ripple={false}
                      variant="text"
                      color="black"
                      onClick={() => navigate(`/${to ? `?to=${to}` : ""}`)}
                      className={`ml-1 font-bold capitalize text-md underline ${hoverTextStyle} p-0 focus:bg-transparent active:bg-transparent hover:bg-transparent`}
                    >
                      login
                    </Button>
                  </Typography>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
        {/* Footer */}
        <CardFooter className="py-0">
          <SignupFooter info={LoginInfo} />
        </CardFooter>
      </Card>
    </section>
  );
}
