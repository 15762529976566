import React, { Fragment, useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import ActiveCard from "../../Components/UI/Cards/ActiveCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { useUserStore } from "../../Context/store";
import { useSubworkspaceStore } from "../../Context/store";
import useDebounce from "../../Services/useDebounce";
import { useGetWonDebates } from "../../Web-Hooks/Profile/use-profile";
import SearchFilter from "./SearchFilter";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";

const DebateWonList = () => {
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  const { selectedSubWorkspace, subWorkspaceList } = useSubworkspaceStore();
  const [filter, setFilters] = useState(null);
  const { user: loggedInUser } = useUserStore();

  const subId = filter
    ? filter?.forum?.label !== "All forum"
      ? filter?.forum?.value
      : ""
    : selectedSubWorkspace?.subWorkSpaceId;

  const {
    data,
    isLoading: isWonListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetWonDebates(loggedInUser?.userId, subId, debouncedSearchTerm);

  const WonList = data?.pages?.flatMap((page) => page?.wonList);

  const forumOptions =
    subWorkspaceList?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
      };
    }) || [];

  return (
    <Fragment>
      <div className="h-full flex flex-col gap-5">
        <div className="h-auto flex gap-4 justify-between w-full">
          {" "}
          <SearchFilter
            search={search}
            setSearch={setSearch}
            filters={
              filter || {
                forum: {
                  label: selectedSubWorkspace?.subWorkSpaceName,
                  value: selectedSubWorkspace?.subWorkSpaceId,
                },
              }
            }
            setFilters={setFilters}
            showFilters={["search", "forum"]}
            forumOptions={[...forumOptions]}
          />
        </div>
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isWonListLoading}
        >
          {isWonListLoading  &&
            RenderLoaderSkeleton(3, "motion")}
          {WonList?.length === 0 && (
            <NoData
              isNoData={true}
              message={"Your have not won any debate yet"}
              size={"w-full h-[80%]"}
            />
          )}

          {WonList?.map((won) => (
            <ActiveCard post={won} key={won?.debateId} />
          ))}
        </InfiniteScroll>
      </div>
    </Fragment>
  );
};

export default DebateWonList;
