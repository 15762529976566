import { Button } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import NoData from "../../../Components/Functionality/NoData";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import LoadingSpinner from "../../../Components/UI/LoadingSpinner";
import { useUserStore } from "../../../Context/store";
import { useSocket } from "../../../Context/SocketContext";
import { useSubworkspaceStore } from "../../../Context/store";
import { useWorkspaceStore } from "../../../Context/store";
import { isTodayOrYesterday } from "../../../Utils/Helper";
import {
  useGetGroupChatHistory,
  useGetGroupUsersList,
} from "../../../Web-Hooks/Chat/use-group-chat";
import { useDeleteBlob } from "../../../Web-Hooks/LibraryAPI/use-citation";
import TitleDivider from "../TitleDivider";
import GroupMessageCard from "./GroupMessageCard";
import { primaryColor } from "../../../Utils/Constant";

const GroupMessageScreen = ({ group }) => {
  const messagesEndRef = useRef(null);
  const { mutateAsync: deleteBlob } = useDeleteBlob();
  const { user: loggedInUser } = useUserStore();
  const [chat, setChat] = useState([]);
  const [typeState, setTypeState] = useState([]);
  const { socket, sendMessage, deleteGroupMessage } = useSocket();
  const [size] = useState(100);
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const [selectedMessage, setSelectedMessage] = useState(null);
  const queryClient = useQueryClient();
  const { data: groupMembers } = useGetGroupUsersList(
    group?.id,
    true,
    (data) => data.data
  );
  const {
    data,
    isLoading: isChatLoading,
  } = useGetGroupChatHistory(group?.id, loggedInUser?.userId, size);

  const chatHistory = useMemo(() => {
    return data?.pages?.flatMap((page) => page.data.data.messages).reverse();
  }, [data]);

  const handleDeleteMessage = async () => {
    const { content } = selectedMessage;
    const typeToDelete = ["video", "pdf", "doc", "image"];
    if (typeToDelete?.includes(content?.type)) {
      await deleteBlob({ fileUrl: content?.content?.url });
    }
    await deleteGroupMessage(selectedMessage.id, group?.id);
    setSelectedMessage(null);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [chat, typeState]);

  useEffect(() => {
    const history = chatHistory;
    setChat(history || []);

    return () => {
      setChat([]);
    };
  }, [chatHistory, loggedInUser]);


  useEffect(() => {
    if (!socket) return;

    const handleNewGroupMessage = async (data) => {
      const { groupId, content } = data?.messages[0];

      if (
        content?.type === "user_added" ||
        content?.type === "user_removed" ||
        content?.type === "user_left"
      ) {
        await queryClient?.invalidateQueries({ queryKey: ["group", "list"] });
      }

      if (groupId === group?.id) {
        setChat((prevChat) => [...prevChat, data?.messages[0]]);
      }
    };

    const handleDeleteMessage = async (data) => {
      const { messageId } = data;
      setChat((prevChat) => prevChat.filter((msg) => msg.id !== messageId));
    };

    socket.on("messageDeletedConfirmation", handleDeleteMessage);

    socket.on("gettingNewGroupMessageChat", handleNewGroupMessage);

    socket.on("typingStatus", (data) => {
      setTypeState(data);
    });

    socket.on("newAdminAssigned", async (data) => {
      await queryClient?.invalidateQueries({ queryKey: ["group"] });
    });

    return () => {
      socket.off("gettingNewGroupMessageChat", handleNewGroupMessage);
      socket.off("messageDeleted", handleDeleteMessage);
    };
  }, [socket, group, queryClient]);

  useEffect(() => {
    if (!socket) return;
    const handleReadAllMessages = (groupId) => {
      if (groupId === group?.id) {
        socket.emit("readAllMessages", {
          groupId: group?.id,
          userId: loggedInUser?.userId,
        });
      }
    };
    socket.on("readNewMessage", handleReadAllMessages);

    return () => {
      socket.off("readNewMessage", handleReadAllMessages);
    };
  }, [socket, group, loggedInUser]);

  useEffect(() => {
    if (!socket) return;

    const handleReadUIUpdate = ({ readByUsers, groupId }) => {
      if (groupId === group?.id) {
        setChat((prevChat) =>
          prevChat.map((message, index) => {
            // Check if all group users are in the readByUsers array
            const allUsersRead = group?.users?.every((userId) =>
              readByUsers?.includes(userId)
            );

            if (allUsersRead) {
              // Update readBy for all messages in the current group
              return {
                ...message,
                readBy: readByUsers,
              };
            } else if (index === prevChat.length - 1) {
              // Update only the last message if not all users have read
              return {
                ...message,
                readBy: readByUsers,
              };
            }
            return message;
          })
        );
      }
    };

    socket.on("readByUsers", handleReadUIUpdate);

    return () => {
      socket.off("readByUsers", handleReadUIUpdate);
    };
  }, [socket, group, loggedInUser]);

  const handleSend = () => {
    const Message = {
      type: "text",
      content: {
        text: "Hey there",
      },
    };

    sendMessage(
      loggedInUser?.userId,
      group?.id,
      Message,
      selectedWorkspace?.workSpaceId,
      selectedSubWorkspace?.subWorkSpaceId
    );
  };

  if (isChatLoading) {
    return <LoadingSpinner />;
  }

  const TypingUsers =
    typeState?.filter((user) => user !== loggedInUser?.userId) || [];

  console.log(chat)

  return (
    <div className="h-full w-full overflow-y-auto  pb-3 px-6">
      <InfoPopup
        title={"Delete Message"}
        description={
          "Action will delete message from the group no user will be able to see this message"
        }
        onClickBtn1={handleDeleteMessage}
        onClickBtn2={() => setSelectedMessage(null)}
        handleClose={() => setSelectedMessage(null)}
        btn1={"Delete"}
        btn2={"Cancel"}
        open={selectedMessage}
      />

      <section
        className={`${
          chat?.length > 0 ? "min-h-full" : "h-full"
        } w-full flex flex-col justify-end`}
      >
        {chat?.length > 0 ? (
          chat?.map((message, index) => (
            <React.Fragment key={index}>
              {message && (
                <>
                  {index === 0 ||
                  !isTodayOrYesterday(
                    message?.timestamp,
                    chat[index - 1]?.timestamp
                  ) ? (
                    <TitleDivider
                      title={isTodayOrYesterday(message?.timestamp)}
                    />
                  ) : null}
                </>
              )}

              <GroupMessageCard
                selectedMessage={selectedMessage}
                setSelectedMessage={setSelectedMessage}
                group={group}
                members={groupMembers}
                message={message}
                key={message?.id}
              />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment key="hello">
            <div className="my-8 h-full">
              <NoData
                size={"h-[80%] w-full"}
                children={
                  <Button
                    className="bg-secondary shrink-0"
                    onClick={handleSend}
                  >
                    Say Hello
                  </Button>
                }
                message="No messages yet"
              />{" "}
            </div>
          </React.Fragment>
        )}

        {TypingUsers?.length > 0 && (
          <div className="flex items-end justify-start gap-4 my-2">
            <span className="text-md font-semibold text-secondary">
              {TypingUsers.length > 0 && (
                <>
                  {TypingUsers.slice(0, 3).map((id, index) => {
                    const name = groupMembers.find(
                      (member) => member.id === id
                    )?.username;
                    return (
                      <React.Fragment key={id}>
                        {index > 0 && index < 2 ? ", " : ""}
                        {index === 2 ? " and " : ""}
                        <span className="capitalize">{name}</span>
                      </React.Fragment>
                    );
                  })}
                  {TypingUsers.length === 1
                    ? " is typing"
                    : TypingUsers.length > 3
                    ? ` and ${TypingUsers.length - 3} more are typing`
                    : " are typing"}
                </>
              )}
            </span>
            <ThreeDots
              visible={true}
              height={"20px"}
              width={"20px"}
              color={primaryColor}
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </section>
      <div ref={messagesEndRef} />
    </div>
  );
};

export default GroupMessageScreen;
