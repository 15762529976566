import { List, ListItem, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import NewMessageCard from "../../Components/UI/Cards/NewMessageCard";
import SearchField from "../../Components/UI/Inputs/SearchField";
import { useUserStore } from "../../Context/store";
import { SocketEvents, useSocket } from "../../Context/SocketContext";
import { useSubworkspaceStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import useDebounce from "../../Services/useDebounce";
import { useGetExternalData } from "../../Web-Hooks/Chat/chat";
import NewGroupPopup from "./NewGroupPopup";
import { usePlan } from "../../Context/PlanContext";

const NewMessage = () => {
  const [searchValue, setSearchValue] = useState("");
  const deferredSearch = useDebounce(searchValue, 500);
  const navigate = useNavigate();
  const { user: loggedInUser } = useUserStore();
  const { socket } = useSocket();
  const [groupPopup, setGroupPopup] = useState(false);
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { data: chatUserList, isLoading: chatUserListLoading } =
    useGetExternalData(
      loggedInUser?.userId,
      selectedWorkspace?.workSpaceId,
      process.env.REACT_APP_FORUM_WISE === "true"
        ? selectedSubWorkspace?.subWorkSpaceId
        : "",
      deferredSearch,
      true
    );
  const [chattedUserList, setChattedUserList] = useState([]);
  const { currentPlan } = usePlan();

  useEffect(() => {
    setChattedUserList(chatUserList);
  }, [chatUserList]);

  useEffect(() => {
    if (!socket || !socket.connected || !chattedUserList?.length) return;
    socket.on(SocketEvents.UPDATE_USER_STATUS, (user) => {
      const { userId, online } = user;
      const findUserIndex = chattedUserList?.findIndex(
        (user) => user.id === userId
      );
      if (findUserIndex < 0) return;
      const findUser = chattedUserList[findUserIndex];
      const updateUser = { ...findUser, online };
      const newChattedUserList = [...chattedUserList];
      newChattedUserList[findUserIndex] = updateUser;
      setChattedUserList(newChattedUserList);
    });
  }, [socket, chattedUserList]);

  return (
    <section className="grid grid-rows-[auto_1fr] gap-0">
      <div className="sticky top-0 bg-white z-10">
        <div className="px-3 md:px-8 h-12 md:h-16 flex justify-between items-center">
          <Typography variant="h4" className="text-2xl ">
            New Message
          </Typography>
        </div>
        <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
      </div>
      <div className="flex flex-col gap-4 md:w-[80%] h-[calc(100vh-10.5rem)] md:h-[calc(100vh-8.4rem)] px-3 md:px-8 py-4 ">
        <div className="w-auto sm:w-full flex justify-between gap-3">
          <SearchField
            id="search-user-message"
            name="search-user-message"
            search={searchValue}
            setSearch={setSearchValue}
          />
          {currentPlan?.createGroupMessage && (
            <BasicButton
              color={"primary"}
              className={"px-5 min-w-fit"}
              onClick={() => setGroupPopup(true)}
            >
              New Group Chat
            </BasicButton>
          )}
        </div>
        {chatUserListLoading && (
          <List className="min-w-[0px] overflow-y-auto ">
            {MessageCardSkeleton({ count: 5 })}
          </List>
        )}
        <List className="min-w-[0px] overflow-y-auto ">
          {chattedUserList?.map((user, index) => (
            <ListItem
              key={user?.id}
              className="flex shrink-0 justify-between items-center min-w-[0px] py-4 "
              onClick={() => {
                // joinRoom(loggedInUser?.userId, user?.userId);
                navigate(`/message/${user?.id}`, {
                  state: {
                    new: true,
                    user: {
                      ...user,
                      userId: user?.id,
                      userName: user?.username,
                    },
                  },
                });
              }}
            >
              <NewMessageCard user={user} />
            </ListItem>
          ))}
        </List>
      </div>
      {currentPlan?.createGroupMessage && (
        <NewGroupPopup
          groupPopup={groupPopup}
          setGroupPopup={setGroupPopup}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          USERS={chatUserList}
        />
      )}
    </section>
  );
};

export default NewMessage;

const MessageCardSkeleton = ({ count = 1 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <ListItem
          key={index}
          className="flex shrink-0 justify-between items-center min-w-[0px] py-4 "
        >
          <div
            key={index}
            className="flex gap-4 truncate justify-between items-center w-full animate__fadeIn"
          >
            <div className="flex gap-4 items-center truncate w-full">
              <div className="h-12 w-12 rounded-full bg-gray-300"></div>
              <div className="flex flex-col w-full">
                <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-2 w-[50%] rounded-full bg-gray-300"
                >
                  &nbsp;
                </Typography>
                <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-2 w-[50%] rounded-full bg-gray-300"
                >
                  &nbsp;
                </Typography>
              </div>
            </div>
          </div>
        </ListItem>
      ))}
    </>
  );
};
