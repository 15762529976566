import { TrashIcon } from "@heroicons/react/24/outline";
import { EyeIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Avatar, Button } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import UserSolid from "../../Assets/Images/PlaceholderImage.png";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useFileViewer } from "../../Context/FileViewerContext";
import { useSubworkspaceStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import { shadowBlue, shadowNormal } from "../../Utils/Constant";
import {
  useDeleteBlob,
  useUploadCitation,
} from "../../Web-Hooks/LibraryAPI/use-citation";
import { GetCopiedURL } from "../../api/debate/debate";

// const AddPhoto = ({ debateInfo, handleStateUpdate }) => {
//   const location = useLocation();
//   const selectedDebate = location?.state?.selectedDebate;
const AddPhoto = ({ debateInfo, handleStateUpdate, LibraryPhotos }) => {
  const location = useLocation();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const LibraryPhoto = location?.state?.LibraryPhoto;
  const menuRef = useRef(null);
  const [profile, setProfile] = useState({
    // picture: debateInfo?.DebateImage,
    picture:
      LibraryPhoto?.citationUrl || debateInfo?.DebateImage || LibraryPhotos,
    title: "",
  });
  const { mutateAsync: uploadFile, isPending: isUploading } =
    useUploadCitation();
  const { mutateAsync: deleteBlob, isPending: isDeleting } = useDeleteBlob();
  const { handleFile } = useFileViewer();

  useEffect(() => {
    if (debateInfo?.DebateImage) {
      setProfile({
        picture: debateInfo?.DebateImage,
        title: "",
      });
    }
  }, [debateInfo]);

  const debateUploadFilePath  = `${process.env.REACT_APP_DEBATE_UPLOAD}/${debateInfo?.workSpaceId || selectedWorkspace?.workSpaceId}/${debateInfo?.subWorkSpaceId || selectedSubWorkspace?.subWorkSpaceId}`

  const handleSubmissionImage = async (selectedCitation) => {
    if (!selectedCitation?.citationFile) {
      const filePath = selectedCitation?.citationUrl?.split("debatemodule/")?.pop();
      const data = await GetCopiedURL(filePath, debateUploadFilePath)
      const newUrl = `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${debateUploadFilePath}/${data}`
      handleStateUpdate({
        ...debateInfo,
        DebateImage: newUrl,
        FileSize: selectedCitation?.citationSize,
      });
      setProfile({
        picture: newUrl,
        title: "",
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedCitation?.citationFile);
    formData.append(
      "filePath",
      `${process.env.REACT_APP_DEBATE_UPLOAD}/${debateInfo?.workSpaceId || selectedWorkspace?.workSpaceId}/${debateInfo?.subWorkSpaceId || selectedSubWorkspace?.subWorkSpaceId}/`
    );
    try {
      uploadFile(formData)
        .then((res) => {
          if (!res?.data?.data) {
            toast.error("Error uploading file");
            return;
          }
          setProfile({
            picture: res?.data?.data,
            title: selectedCitation?.citationFile?.name,
          });
          handleStateUpdate({
            ...debateInfo,
            DebateImage: res?.data?.data,
            FileSize: selectedCitation?.citationFile?.size,
          });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDelete = async() => {
    const Blob = {
      fileUrl: profile?.picture,
    };

    await deleteBlob(Blob);

    setProfile({
      picture: "",
      title: "",
    });
    handleStateUpdate({
      ...debateInfo,
      DebateImage: "",
      FileSize: 0,
    });
  };

  return (
    <>
      <section className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 my-2">
        <>
          <div className="flex flex-col gap-6 items-start my-4">
            <div
              className={`relative flex flex-col max-w-40 max-h-40 cursor-pointer items-center px-2 py-2 gap-6 rounded-md ${
                profile?.picture ? shadowBlue : shadowNormal
              } group`}
            >
              {isUploading || isDeleting ? (
                <div className="h-32 w-32">
                  <LoadingSpinner height={20} width={20} />
                </div>
              ) : (
                <Avatar
                  id="debateImage-preview"
                  src={profile?.picture ? profile?.picture : UserSolid}
                  className={`h-32 w-32 object-contain rounded-md transition-opacity duration-300 group-hover:opacity-40`}
                  variant="rounded"
                  onClick={() => {
                    if (profile?.picture) {
                      handleFile({
                        fileUrl: profile?.picture,
                        fileType: "image",
                        fileName: profile?.picture?.split(".")[1],
                        fileUploadedAt: Date.now(),
                      });
                    } else {
                      if (menuRef.current) {
                        menuRef.current.click();
                      }
                    }
                  }}
                />
              )}

              {!profile?.picture && (
                <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <CitationComponent
                    menuRef={menuRef}
                    MenuHandlerComponent={
                      <Button
                        id="debateImage-upload"
                        variant="text"
                        color="blue"
                        className="px-3 py-2 hover:bg-secondary hover:bg-opacity-20 text-secondary hover:text-debatePalette-title"
                        onClick={() => {
                          if (profile?.picture) {
                            handleFile({
                              fileUrl: profile?.picture,
                              fileType: "image",
                              fileName: profile?.picture?.split(".")[1],
                              fileUploadedAt: Date.now(),
                            });
                          } else {
                            if (menuRef.current) {
                              menuRef.current.click();
                            }
                          }
                        }}
                      >
                        {" "}
                        <PlusIcon className="h-8 w-8 text-inherit" />
                      </Button>
                    }
                    debateDetails={debateInfo}
                    isLoadingFromHandleSubmit={isUploading}
                    from={["library", "gallary"]}
                    handleCitationSubmit={async (selectedCitation) =>
                      await handleSubmissionImage(selectedCitation)
                    }
                    heading="Add Photo"
                    query={`image`}
                  />
                </div>
              )}
              {profile?.picture && (
                <div className="absolute inset-0 flex flex-col gap-2 justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <Button
                    id="debateImage-view"
                    variant="text"
                    color="blue"
                    className="px-3 py-2 hover:bg-secondary text-secondary hover:text-white"
                    onClick={() => {
                      if (profile?.picture) {
                        handleFile({
                          fileUrl: profile?.picture,
                          fileType: "image",
                          fileName: profile?.picture?.split(".")[1],
                          fileUploadedAt: Date.now(),
                        });
                      } else {
                        if (menuRef.current) {
                          menuRef.current.click();
                        }
                      }
                    }}
                  >
                    <EyeIcon className="w-6 h-6 !stroke-2   text-inherit" />
                  </Button>
                  <Button
                    id="debateImage-delete"
                    variant="text"
                    color="red"
                    className="px-3 py-2 hover:bg-debatePalette-timer text-debatePalette-timer hover:text-white"
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    <TrashIcon className="w-6 h-6 !stroke-2   text-inherit" />
                  </Button>
                </div>
              )}
            </div>
          </div>

          {/* <div className="flex gap-2 w-40 justify-center items-start">
            <CitationComponent
              menuRef={menuRef}
              MenuHandlerComponent={
                <Button
                  variant="filled"
                  className=" bg-debatePalette-focus text-debatePalette-background px-3 py-2"
                >
                  <Tooltip content={"Upload photo"}>
                    <ArrowUpTrayIcon className="w-6 h-6 !stroke-2" />
                  </Tooltip>
                </Button>
              }
              debateDetails={debateInfo}
              isLoadingFromHandleSubmit={isUploading}
              from={["library", "gallary"]}
              handleCitationSubmit={async (selectedCitation) =>
                await handleSubmissionImage(selectedCitation)
              }
              heading="Add Photo"
              query={"isFromDebatePhoto=true&sortBy=image"}
            />

            {profile.picture && (
              <Tooltip content={"Remove Photo"}>
                <Button
                  variant="text"
                  color="red"
                  className="px-3 py-2"
                  onClick={() => {
                    setProfile({
                      picture: "",
                      title: "",
                    });
                    handleStateUpdate({
                      ...debateInfo,
                      DebateImage: null,
                      FileSize: null,
                    });
                  }}
                >
                  <TrashIcon className="w-6 h-6 !stroke-2 text-debatePalette-timer" />
                </Button>
              </Tooltip>
            )}
          </div> */}
        </>
      </section>
    </>
  );
};

export default AddPhoto;
