import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Button, Drawer, Switch, Typography } from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import { useUserStore } from "../../Context/store";

import {
  useGetNotificationStatusWorkspaceWise,
  useUpdateNotificationList,
} from "../../Web-Hooks/Notification/use-notification";
import { ClockIcon } from "@heroicons/react/24/outline";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import toast from "react-hot-toast";
import InvitationNotification from "./InvitationNotification";
import ForumWiseNotification from "./ForumWiseNotification";
import NotificationScheduler from "./NotificationScheduler";

const NotificationDrawer = ({ openNotification, closeOpenNotification }) => {
  const [openFolder, setOpenFolder] = useState("All");
  const [openSchedular, setOpenSchedular] = useState(false);
  const handleCloseDrawer = () => {
    closeOpenNotification();
  };
  const { user } = useUserStore();

  const { data: workspaceStatusList } = useGetNotificationStatusWorkspaceWise(
    user?.userId
  );

  const { mutateAsync: updateStatus, isPending: isUpdating } =
    useUpdateNotificationList();

  const handleChangeNotification = async (type, value) => {
    if (workspaceStatusList?.pauseAll && type !== "All") {
      toast.error("Please unpause all notification to change the settings");
      return;
    }

    let notifyObj = {
      UserId: user?.userId,
    };
    if (type === "All") {
      notifyObj.PauseAll = value;
    }
    if (type === "Follow") {
      notifyObj.FollowerandFollowing = value;
    }
    if (type === "Block") {
      notifyObj.BlockandUnblock = value;
    }

    await updateStatus(notifyObj);
  };

  return (
    <Fragment>
      <Drawer
        placement="right"
        open={openNotification}
        onClose={handleCloseDrawer}
        className={`sm:w-[500px] ${
          openNotification ? "shadow-xl shadow-debatePalette-base" : ""
        }`}
        overlay={false}
        size={350}
      >
        <div className="grid grid-cols-1 grid-rows-[auto_auto_auto_auto_1fr] h-screen p-3 overflow-y-auto">
          <div className="flex justify-between items-center w-full h-20 ">
            {openFolder !== "All" && (
              <Button
                variant="text"
                onClick={() => setOpenFolder("All")}
                className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </Button>
            )}
            <Typography
              variant="h4"
              className="text-debatePalette-title font-semibold"
            >
              Notification
            </Typography>
            <Button
              variant="text"
              onClick={handleCloseDrawer}
              className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg"
            >
              <XMarkIcon className="h-5 w-5" />
            </Button>
          </div>
          <hr className="border-gray-300" />
          {openFolder === "All" && (
            <>
              <div className="flex justify-between items-center">
                <Typography
                  variant="h6"
                  className="text-debatePalette-title font-semibold py-3"
                >
                  General Notification
                </Typography>
                <Button
                  variant="text"
                  size="sm"
                  className="bg-gray-200 py-2 px-4 rounded-lg"
                  onClick={() => {
                    setOpenSchedular(true);
                  }}
                >
                  <ClockIcon className="h-6 w-6 text-secondary" />
                </Button>
              </div>
              <hr className="border-gray-300" />
              <form className="flex flex-col gap-4 overflow-y-auto py-3">
                <div className="flex w-full items-center  justify-between">
                  <label
                    htmlFor="all"
                    color="blue-gray"
                    className="font-medium"
                  >
                    Pause All
                  </label>
                  <Switch
                    id="all"
                    name="all"
                    color="blue"
                    disabled={isUpdating}
                    checked={workspaceStatusList?.pauseAll || false}
                    onChange={() =>
                      handleChangeNotification(
                        "All",
                        !workspaceStatusList?.pauseAll
                      )
                    }
                    containerProps={{
                      className: "flex-shrink-0 p-0 w-16",
                    }}
                  />
                </div>
                <div
                  className="flex w-full items-center justify-between hover:cursor-pointer"
                  onClick={() => setOpenFolder("Invitation")}
                >
                  <p color="blue-gray" className="font-medium">
                    Invitation
                  </p>
                  <ChevronRightIcon
                    strokeWidth={2.5}
                    className="h-5 flex-shrink-0 p-0 w-16"
                  />
                </div>
                <div
                  className="flex w-full items-center justify-between hover:cursor-pointer"
                  onClick={() => setOpenFolder("Like/Vote")}
                >
                  <p color="blue-gray" className="font-medium">
                    Like/Vote
                  </p>
                  <ChevronRightIcon
                    strokeWidth={2.5}
                    className="h-5 flex-shrink-0 p-0 w-16"
                  />
                </div>
                {/* <div
                className="flex w-full items-center justify-between hover:cursor-pointer"
                onClick={() => setOpenFolder("Draft")}
              >
                <p color="blue-gray" className="font-medium">
                  Draft
                </p>
                <ChevronRightIcon
                  strokeWidth={2.5}
                  className="h-5 flex-shrink-0 p-0 w-16"
                />
              </div> */}

                <div className="flex w-full items-center justify-between">
                  <label
                    htmlFor="follow"
                    color="blue-gray"
                    className="font-medium"
                  >
                    Followers and Following
                  </label>
                  <Switch
                    id="follow"
                    disabled={isUpdating}
                    name="follow"
                    color="blue"
                    checked={workspaceStatusList?.followerandFollowing || false}
                    onChange={() =>
                      handleChangeNotification(
                        "Follow",
                        !workspaceStatusList?.followerandFollowing
                      )
                    }
                    containerProps={{
                      className: "flex-shrink-0 p-0 w-16",
                    }}
                  />
                </div>
                {/* <div className="flex w-full items-center justify-between">
                <label
                  htmlFor="message"
                  color="blue-gray"
                  className="font-medium"
                >
                  Message
                </label>
                <Switch
                  name="message"
                  id="message"
                  color="blue"
                  checked={TEMP_DATA.NotificationOptions.Message}
                  onClick={() =>
                    handleChangeNotification(
                      "Message",
                      !TEMP_DATA.NotificationOptions.Message
                    )
                  }
                  onChange={() =>
                    handleChangeNotification(
                      "Message",
                      !TEMP_DATA.NotificationOptions.Message
                    )
                  }
                  containerProps={{
                    className: "flex-shrink-0 p-0 w-16",
                  }}
                />
              </div> */}
                <div className="flex w-full items-center justify-between">
                  <label
                    htmlFor="block"
                    color="blue-gray"
                    className="font-medium"
                  >
                    Block and Unblock
                  </label>
                  <Switch
                    disabled={isUpdating}
                    name="block"
                    id="block"
                    color="blue"
                    checked={workspaceStatusList?.blockandUnblock || false}
                    onChange={() =>
                      handleChangeNotification(
                        "Block",
                        !workspaceStatusList?.blockandUnblock
                      )
                    }
                    containerProps={{
                      className: "flex-shrink-0 p-0 w-16",
                    }}
                  />
                </div>
              </form>
            </>
          )}
          {openFolder === "Invitation" && (
            <InvitationNotification
              type={"Invitation"}
              settingInfo={workspaceStatusList?.debateBaseInvitation}
              defaultSettings={workspaceStatusList}
            />
          )}
          {openFolder === "Like/Vote" && (
            <ForumWiseNotification
              type={"VoteLike"}
              settingInfo={workspaceStatusList?.debateBaseLikeVote}
              defaultSettings={workspaceStatusList}
            />
          )}
          {/* {openFolder === "Draft" && (
          <ForumWiseNotification
            type={"Draft"}
            settingInfo={TEMP_DATA.NotificationOptions.Draft}
          />
        )} */}
        </div>
      </Drawer>
      <EditableBodyPopup
        open={openSchedular}
        handleClose={() => setOpenSchedular(false)}
        title={"Notification Schedule"}
      >
        <div className="flex flex-col gap-2 -mt-3">
          <Typography
            variant="small"
            className="text-debatePalette-timer font-semibold"
          >
            You’ll only receive notifications in the hours that you choose.
          </Typography>
          <NotificationScheduler handleClose={() => setOpenSchedular(false)} />
        </div>
      </EditableBodyPopup>
    </Fragment>
  );
};

export default NotificationDrawer;
