import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { ReactComponent as AILogo } from "../../../Assets/Svg/AILogo.svg";
import AIArgumentSuggestion from "../../../Components/Functionality/AIArgumentSuggestion";
import ArgumentEditorTextArea from "../../../Components/UI/Inputs/ArgumentEditorTextArea";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import useDebounce from "../../../Services/useDebounce";
import { argumentIdeas, convertArrayToString } from "../../../Utils/Helper";
import { useArgumentAgent } from "../../../Web-Hooks/Agents/use-argument-agent";
import { useNavigate } from "react-router-dom";
import { usePlan } from "../../../Context/PlanContext";
import { handleAIUpgradePlan } from "../../../Services/SweetAlert";
import { ARGUMENT_STRUCTURE } from "../../../Utils/Constant";
import { Button } from "@material-tailwind/react";

const AddArgumentWithAi = ({ error, setError }) => {
  const [argumentHighlight, setArgumentHighlight] = useState("debatebase");
  const { debateInfo, handleStateUpdate } = useCreateDebateContext();
  const motion = useDebounce(debateInfo.MotionOrClaim, 1000);
  const [showAIValidator, setShowAIValidator] = useState(false);
  const [activeBlinkingButton, setActiveBlinkingButton] = useState();
  const { currentPlan } = usePlan();

  const { data: argumentAssistance } = useArgumentAgent(
    motion,
    debateInfo?.Fields?.length > 0
      ? convertArrayToString(debateInfo.Fields)
      : "",
    currentPlan?.aiFeature
  );
  const navigate = useNavigate();

  const handleArgument = () => {
    if (!currentPlan?.aiFeature) {
      handleAIUpgradePlan(ARGUMENT_STRUCTURE, () => navigate(`/upgrade-plan?plan=debato-plan`));
      return;
    }
    setShowAIValidator(true);
  };
  return (
    <div>
      <AIArgumentSuggestion
        open={showAIValidator}
        debateInfo={debateInfo}
        handleClose={() => setShowAIValidator(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => setActiveBlinkingButton("Argument")}
        setActiveBlinkingButton={setActiveBlinkingButton}
        argumentAssistance={argumentAssistance}
        setArgumentHighlight={setArgumentHighlight}
      />
      <div
        className="flex flex-col gap-2 items-center mt-2"
        onMouseEnter={() => setActiveBlinkingButton(true)}
        onMouseLeave={() => setActiveBlinkingButton(false)}
      >
        <div
          className={`flex flex-row justify-between w-full ${
            debateInfo?.CreatorOpeningArgument !== "" && " pr-8"
          }  `}
        >
          <label htmlFor={"argument"} className="text-lg font-semibold">
            1st Round Argument
          </label>
          {debateInfo?.MotionOrClaim && debateInfo?.Fields?.length > 0 && (
            <Button
            size="sm"
            variant="outlined"
            id="argumentStructurerButton"
            onClick={handleArgument}
            onMouseEnter={() => setActiveBlinkingButton(true)}
            onMouseLeave={() => setActiveBlinkingButton(false)}
            className={` flex items-center p-1 justify-end self-end gap-2 border-[#7647FC] -pl-8`}
          >
            <AILogo className="h-6 w-6" />
            {activeBlinkingButton && (
              <span
                className="text-[#7647FC] font-semibold animate__animated animate__fadeIn animate__faster"
                style={{ whiteSpace: "nowrap" }}
              >
                Argument Structure Suggestion
              </span>
            )}
          </Button>
          )}
        </div>
        <div className="w-full flex flex-row gap-2 ">
          <div className="flex flex-row w-full gap-2 ">
            <div className="flex flex-col w-full gap-2">
              {argumentHighlight !== "debatebase" && (
                <div className=" flex justify-start items-start border-4 border-blue-800 p-2 w-full rounded-md py-2">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: argumentIdeas(argumentHighlight),
                    }}
                  />
                </div>
              )}
              <ArgumentEditorTextArea
                setArgumentHighlight={setArgumentHighlight}
                debateInfo={debateInfo}
                handleStateUpdate={handleStateUpdate}
                argumentHighlight={argumentHighlight}
              />
            </div>

            {/* <div
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  style={{ height: "150px" }}
                  className={`relative   area border-2 p-2 rounded-md w-full  ${shadowNormal} ${
                    isFocused
                      ? "border !border-debatePalette-shadow !shadow-[0_1px_4px_1.5px_rgba(0,_139,_250,_0.25)]"
                      : "border-debatePalette-buttonBorder"
                  }`}
                >
                  <HighlightWithinTextarea
                  style={{
                    minHeight: "150px",
                  }}
                    value={debateInfo?.CreatorOpeningArgument || ""}
                    onChange={(value) => {
                      let newHighLight = FindCommonSubstrings(argumentHighlight,value);
                      handleStateUpdate({
                        ...debateInfo,
                        CreatorOpeningArgument: value,
                      });
                      setArgumentHighlight(newHighLight);
                      setCharCount(value.length);
                    }}
                    placeholder=""
                    highlight={[
                      {
                        highlight: argumentHighlight,
                        className: "highlighted",
                      },
                    ]}
                  />
                  <span className="absolute bottom-0 right-3">
                    {" "}
                    {CountWords(debateInfo?.CreatorOpeningArgument)}
                  </span>{" "}
                </div> */}
            <div>
              {debateInfo?.CreatorOpeningArgument !== "" && (
                <CheckCircleIcon color="green" className="h-7 w-7 " />
              )}
            </div>
          </div>
        </div>
        {error?.CreatorOpeningArgument && (
          <p className="text-red-500 text-sm font-semibold">
            {error.CreatorOpeningArgument}
          </p>
        )}
      </div>
    </div>
  );
};

export default AddArgumentWithAi;
