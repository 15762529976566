import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogHeader,
  Radio,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import VoteScaler from "./VoteScaler";
import { useAddUpdateScalerVote } from "../../Web-Hooks/Debate/use-debate";
import { useUserStore } from "../../Context/store";
import { getUTCDate } from "../../Utils/Helper";

const AddUpdateScalerPopup = ({
  debate,
  scalerPopup = false,
  setScalerPopup,
  defaultValue,
  userSideForDebate,
  defaultChecked = false
}) => {
  const { user: loggedInUser } = useUserStore();
  const [vote, setVote] = useState(() =>
    defaultValue ? Number(defaultValue) : 0
  ); // Default vote value
  const [isNeutral, setIsNeutral] = useState(false); // Neutral state
  const [checked, setChecked] = useState(defaultChecked);
  const { mutateAsync: addUpdateVote, isPending: isAddUpdateVotePending } =
    useAddUpdateScalerVote();

  const handleVoteChange = (value) => {
    setVote(value);
  };

  useEffect(() => {
    setVote(Number(defaultValue));
    setChecked(defaultChecked);
  }, [defaultValue, defaultChecked]);
  const handleClose = () => {
    setScalerPopup(false);
  };

  const handleSubmit = () => {
    try {
      addUpdateVote({
        DebateId: debate?.debateId,
        UserId: loggedInUser?.userId,
        SideForDebate: isNeutral
          ? ""
          : vote > 0
          ? "agree"
          : vote < 0
          ? "disagree"
          : "",
        ScalerPoint: isNeutral ? 0 : vote,
        CreatedAt: getUTCDate(),
        IsScalerVoteChecked: checked,
      }).then(() => {
        handleClose();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Motion = debate?.motionOrClaim;

  const Title = userSideForDebate
    ? debate?.loginUserSide
      ? `Update ${userSideForDebate} vote`
      : "Update Vote"
    : "Add Vote";

  const HelperMessage = debate?.loginUserSide
    ? `As for the motion, you’ve chosen ${userSideForDebate} side.`
    : "You can change your scale at any time";

  return (
    <Dialog open={scalerPopup} size="md" className="p-6">
      <DialogHeader>
        <h3 className="text-2xl font-semibold">{Title}</h3>
      </DialogHeader>
      <DialogBody>
        <div className="flex flex-col space-y-4">
          {/* Title */}
          <p className="text-sm text-debatePalette-title">
            Before reading the arguments and citations let us know where you
            stand based only on the motion{" "}
          </p>
          <span className="font-bold text-secondary">({Motion})</span>
          <p className="text-sm text-debatePalette-title">{HelperMessage}</p>

          {/* Voting Options */}
          <div className="space-y-4">
            <div className="flex items-center">
              <Radio
                color="blue"
                id="vote-radio"
                name="voteOption"
                label="Add your vote"
                checked={!isNeutral}
                onChange={() => setIsNeutral(false)}
              />
            </div>

            {/* Slider for Voting */}
            {!isNeutral && (
              <VoteScaler
                setScalerPoint={handleVoteChange}
                scalerPoint={vote}
                userSideForDebate={userSideForDebate}
              />
            )}

            {!userSideForDebate && (
              <div className="flex items-center">
                <Radio
                  color="blue"
                  id="neutral-radio"
                  name="voteOption"
                  label="I am neutral right now"
                  checked={isNeutral}
                  onChange={() => setIsNeutral(true)}
                />
              </div>
            )}
          </div>

          <Checkbox
            id="vote-checkbox"
            label="Don't show this popup again"
            color="blue"
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
            ripple={false}
          />

          {/* Buttons */}
          <div className="flex justify-center  pt-4 gap-4 ">
            <Button variant="outlined" className="w-24" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="bg-yellow-600 text-debatePalette-title rounded-lg hover:bg-yellow-700"
              onClick={handleSubmit}
              loading={isAddUpdateVotePending}
            >
              Submit
            </Button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default AddUpdateScalerPopup;
