import { Card, CardBody, Typography } from "@material-tailwind/react";
import moment from "moment";
import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getDebateDetails } from "../../../api/debate/debate";
import { getWorkspaceByUserId } from "../../../api/workspace/workspace";
import { useUserStore } from "../../../Context/store";
import { usePlan } from "../../../Context/PlanContext";
import { handleUpgradePlan } from "../../../Services/SweetAlert";
import { ADD_WORKSPACES, primaryColor } from "../../../Utils/Constant";
import { useUpdateNotificationStatus } from "../../../Web-Hooks/Notification/use-notification";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { useTab } from "../../../Context/TabContext";

const NotificationCard = ({
  notification,
  setNotification,
  setRejectPopup,
  setNominatePopup,
}) => {
  const navigate = useNavigate();
  const { mutateAsync: updateNotificationStatus } =
    useUpdateNotificationStatus();
  const { user } = useUserStore();
  const statusObj = {
    NotificationId: notification?.notificationId,
    IsActionTaken: true,
  };
  const { currentPlan } = usePlan();
  const [loader, setLoader] = React.useState(false);
  const { setTab } = useTab();

  const handleJoinFromNotification = async (notification) => {
    setLoader(true);
    const url = new URL(notification?.urlToJoin);
    const searchParams = new URLSearchParams(url.search);
    const workspaceId = searchParams.get("workspaceId");
    // --- 1. Check if the user has exceeded join limits ---
    if (currentPlan?.totalWorkspaceJoin !== "unlimited") {
      const workspace = await getWorkspaceByUserId(user?.userId);
      const joinedWorkspace = workspace?.listWorkspaces?.filter(
        (ws) => ws?.createdBy?.userId !== user?.userId
      );
      const alreadyPartOfWorkspace = joinedWorkspace?.find(
        (ws) => ws?.workSpaceId === workspaceId
      );
      if (!alreadyPartOfWorkspace) {
        if (joinedWorkspace?.length >= currentPlan?.totalWorkspaceJoin) {
          handleUpgradePlan(ADD_WORKSPACES, () => {
            navigate("/upgrade-plan?plan=base-plan", { replace: true });
          });
          setLoader(false);
          return;
        }
      }
    }

    // --- 2. Extract URL parameters from notification.urlToJoin ---
    const urlParts = notification?.urlToJoin?.split("?");
    if (!urlParts || urlParts.length < 2) {
      setLoader(false);
      Swal.fire({
        title: "Invalid Invitation",
        text: "The invitation link is not valid.",
        icon: "error",
        iconColor: primaryColor,
        confirmButtonText: "OK",
        confirmButtonColor: primaryColor,
      }).then(async () => {
        if (!notification?.isUserView) {
          await updateNotificationStatus(statusObj);
        }
      });
      return;
    }
    const urlParams = new URLSearchParams(urlParts[1]);

    // --- 3. Validate the invitation link date (not older than 2 days) ---
    const lastJoinDateStr = urlParams.get("lastjoinDate");
    if (lastJoinDateStr) {
      const lastJoinDate = new Date(lastJoinDateStr);
      const now = new Date();
      const diffMs = now - lastJoinDate; // difference in milliseconds
      const diffDays = diffMs / (1000 * 60 * 60 * 24);
      if (diffDays > 2) {
        setLoader(false);
        Swal.fire({
          iconColor: primaryColor,
          title: "Link Expired",
          text: "This invitation link has expired.",
          icon: "warning",
          confirmButtonColor: primaryColor,
          confirmButtonText: "OK",
        }).then(async () => {
          // Mark the notification as read on closing the alert
          if (!notification?.isUserView) {
            await updateNotificationStatus(statusObj);
          }
        });
        return;
      }
    } else {
      setLoader(false);
      Swal.fire({
        title: "Invalid Link",
        text: "Invitation link is missing required information.",
        icon: "error",
        confirmButtonText: "OK",
      }).then(async () => {
        if (!notification?.isUserView) {
          await updateNotificationStatus(statusObj);
        }
      });
      return;
    }

    // --- 4. If the link is valid, mark the notification as read (if not already) ---
    if (!notification?.isUserView) {
      await updateNotificationStatus(statusObj);
    }

    setLoader(false);

    // --- 5. Navigate to the valid link ---
    navigate(`/join?${urlParts[1]}`);
  };

  const handleNotificationTypeUI = (notification, type) => {
    if (type === "cronjobnominate" && notification?.isUserView === false) {
      return (
        <span>
          -{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setNominatePopup(true);
              setNotification(notification);
            }}
          >
            Nominate
          </span>{" "}
        </span>
      );
    }
    if (type === "Reject" && notification?.isUserView === false) {
      return (
        <span>
          {" "}
          - would you like to make this debate{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setRejectPopup(true);
              setNotification(notification);
            }}
          >
            Open
          </span>{" "}
          or{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setNominatePopup(true);
              setNotification(notification);
            }}
          >
            Nominate
          </span>{" "}
          other user
        </span>
      );
    } else if (type === "eonian") {
      return (
        <span>
          - would you like to add new argument?{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              !notification?.isUserView && updateNotificationStatus(statusObj);
              navigate(`/profile/active-drafts?selected=active`);
            }}
          >
            Click here
          </span>
        </span>
      );
    } else if (
      type === "Subworkspacejoin" &&
      notification?.isUserView === false
    ) {
      return (
        <span className="inline-flex gap-2 items-center">
          - would you like to join this forum?{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={async (e) => {
              e.stopPropagation();
              handleJoinFromNotification(notification);
            }}
          >
            {loader ? <ThreeDots height={35} width={50} /> : "Join now"}
          </span>
        </span>
      );
    } else if (type === "NextRound" && notification?.isUserView === false) {
      return (
        <span>
          {" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={async (e) => {
              e.stopPropagation();
              const debateDetails = await getDebateDetails(
                notification.debateId,
                user?.userId
              );
              if (user?.userId === debateDetails?.opponentId) {
                navigate(
                  `/debate/${notification.debateId}/add-argument?userName=Add Argument`,
                  {
                    state: {
                      NotificationId: notification?.notificationId,
                      IsActionTaken: true,
                    },
                  }
                );
              } else {
                navigate(
                  `/debate/${notification.debateId}?round=${debateDetails?.currentRound}`,
                  {
                    state: {
                      NotificationId: notification?.notificationId,
                      IsActionTaken: true,
                    },
                  }
                );
              }
            }}
          >
            Click here
          </span>
        </span>
      );
    } else if (type === "NextRound" && notification?.isUserView === true) {
      return (
        <span>
          {" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={async (e) => {
              e.stopPropagation();
              const debateDetails = await getDebateDetails(
                notification.debateId,
                user?.userId
              );
              navigate(
                `/debate/${notification.debateId}?round=${debateDetails?.currentRound}`
              );
            }}
          >
            View details
          </span>
        </span>
      );
    }
  };

  const handleNotificationClick = async (notification, type) => {
    const isHandledExternally = [
      "Reject",
      "Subworkspacejoin",
      "cronresultopen",
      "NextRound",
    ].includes(type);

    if (!isHandledExternally && !notification?.isUserView) {
      await updateNotificationStatus({
        NotificationId: notification.notificationId,
        IsActionTaken: true,
      });
    }

    const debateDetails = await getDebateDetails(
      notification.debateId,
      user?.userId
    );

    switch (type) {
      case "cronresult":
        navigate(`/debate-results/${notification.debateId}`);
        break;
      case "cronjobsavedraft":
        navigate(`/profile/active-drafts?selected=draft`);
        break;
      case "follow":
        navigate(`/profile/${notification.sentBy}`);
        break;
      case "Like":
      case "Vote":
      case "cronjobopendebate":
      case "cronresultopen":
      case "Accept":
      case "AddMySaveDebate":
        !notification?.isUserView && updateNotificationStatus(statusObj);
        navigate(
          `/debate/${notification.debateId}?round=${
            debateDetails?.type === "eonian" ? 0 : debateDetails?.currentRound
          }`,
          {
            state: {
              category: debateDetails?.fields,
              debateType: debateDetails?.type,
            },
          }
        );
        break;
      case "nomination" || "cronjobnominate":
        if (notification?.sentBy === user?.userId) {
          navigate(`/profile/active-drafts?selected=pending`);
        } else {
          setTab("nomination");
        }
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div
        className={`flex flex-col w-full ${
          notification?.isUserView ? "" : "opacity-100 bg-gray-200"
        } shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] rounded-md border gap-8 justify-items-center items-center my-4 cursor-pointer`}
      >
        <Card
          className={`w-full ${
            notification?.isUserView ? "" : "opacity-100 bg-gray-200"
          }`}
          shadow={false}
          onClick={() =>
            handleNotificationClick(
              notification,
              notification?.notificationType
            )
          }
        >
          <CardBody className="flex flex-col sm:flex-row gap-2 items-center !p-3">
            <div className="inline-flex items-center justify-center w-[6%] ">
              <ImageOrInitials
                initials={notification?.senderName || "Anonymous"}
                imageSrc={notification?.senderImage}
              />
            </div>
            <div className="flex-1 text-wrap truncate">
              <Typography
                variant="h6"
                color="black"
                className="text-center sm:text-left !line-clamp-1"
              >
                {notification?.title}
              </Typography>
              <Typography
                variant="paragraph"
                className="text-justify sm:text-left "
                color="black"
              >
                {notification?.text}
                {handleNotificationTypeUI(
                  notification,
                  notification?.notificationType
                )}
              </Typography>
            </div>
            <Typography>
              {moment(`${notification?.timeInUtc}Z`).fromNow()}
            </Typography>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default NotificationCard;
