import { PlusCircleIcon } from "@heroicons/react/24/solid";
import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { SideBarMenu } from "../../Components/UI/Menus/SideBarMenu";
import { usePlan } from "../../Context/PlanContext";
import {
  useUserProfileStore,
  useUserStore,
  useWorkspaceStore,
} from "../../Context/store";
import { useSubworkspaceStore } from "../../Context/store";
import { DebateOptions } from "../../Utils/Constant";
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import NotificationButton from "./NotificationButton";
import ProfileMenu from "./ProfileMenu";
import WorkspaceMenu from "./WorkspaceMenu";
import { Button, Tooltip } from "@material-tailwind/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";

const Sidebar = ({ isNewWorkSpace, openProfile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { user: loggedInUser } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const { subscribedPlan } = usePlan();
  const { subWorkspaceList } = useSubworkspaceStore();
  const { profileDetail } = useUserProfileStore();
  const {currentPlan} = usePlan();
  return (
    <section className="h-full w-full bg-sideLayoutPrimary py-2">
      {!isNewWorkSpace && (
        <Fragment>
          {" "}
          <div className="flex md:flex-col flex-row h-full items-stretch justify-between">
            <div className="align-top">
              <div className="flex md:flex-col items-center md:item-start gap-4 md:gap-0 ">
                <WorkspaceMenu
                  loggedInUser={loggedInUser}
                  navigate={navigate}
                />
                <div className="hidden md:flex md:flex-col mt-4">
                  <NotificationButton
                    loggedInUser={loggedInUser}
                    navigate={navigate}
                  />
                  {!subscribedPlan?.isTrialPlanAvailable && subscribedPlan?.planName !== "Free" && (
                    <ManageSubscriptionButton
                      loggedInUser={loggedInUser}
                      navigate={navigate}
                    />
                  )}
                  {
                    currentPlan?.roleManagement && (
                           <Button
                              variant="text"
                              ripple={false}
                              className="border-none p-2"
                              onClick={() => navigate("/roleManagement")}
                            >
                              <Tooltip
                                content="Role Management"
                                className="bg-white text-black border border-black"
                              >
                                <UserPlusIcon className="h-8 w-8 text-debatePalette-background" />
                              </Tooltip>
                            </Button>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="align-bottom justify-center items-center flex md:flex-col flex-row gap-2">
              {subWorkspaceList?.length !== 0 && (
                <SideBarMenu
                  isArrow={false}
                  label={
                    <PlusCircleIcon className="w-14 h-14 text-debatePalette-background text-center" />
                    // <RoundedButton
                    //   SVG={PlusIcon}
                    //   BtnWidth={"w-12"}
                    //   BtnHeight={"h-12"}
                    //   className={"md:bg-blue-gray-800 bg-white/15 "}
                    //   svgClass={"!text-debatePalette-background"}
                    // />
                  }
                  options={DebateOptions}
                />
              )}
              {/* <DynamicSpeedDial actions={DebateSpeedOptions} /> */}

              <ProfileMenu
                openProfile={openProfile}
                profileDetail={profileDetail}
              />
            </div>
          </div>
        </Fragment>
      )}
      {isNewWorkSpace && (
        <div className="w-12">
          {" "}
          {pathname !== "/createprofile" ? (
            <ImageOrInitials initials={selectedWorkspace?.workSpaceName} />
          ) : null}
        </div>
      )}
    </section>
  );
};

export default Sidebar;
