import React from "react";
import { useUserStore } from "../../Context/store";
import { useGetSearchedUserList } from "../../Web-Hooks/Search/use-search";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import NoData from "../../Components/Functionality/NoData";
import UserCard from "../../Components/UI/Cards/UserCard";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";

const SearchUsers = ({ query }) => {
  const { user: loggedInUser } = useUserStore();
  const {
    data,
    isLoading: isUserListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetSearchedUserList(loggedInUser?.userId, encodeURIComponent(query));

  const users = data?.pages?.flatMap((page) => page?.userList);

  const userList = users?.filter(
    (user) => user?.userId !== loggedInUser?.userId
  );
  return (
    <div className="w-full h-full">
      {isUserListLoading ? (
        <LoadingSpinner />
      ) : userList?.length > 0 ? (
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isUserListLoading}
        >
          <div className="flex gap-4 flex-wrap">
            {userList.map((user, i) => (
              <UserCard key={user?.userId} user={user} />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <NoData message={"No Users Found"} size={"w-full h-[80%]"} />
      )}
    </div>
  );
};

export default SearchUsers;
