import { Tooltip } from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { ReactComponent as DisLike } from "../../Assets/Svg/DisLike.svg";
import { ReactComponent as FilledDisLike } from "../../Assets/Svg/FilledDislike.svg";
import { ReactComponent as FilledLike } from "../../Assets/Svg/FilledLike.svg";
import { ReactComponent as Like } from "../../Assets/Svg/Like.svg";
import { ReactComponent as Share } from "../../Assets/Svg/share.svg";
import { ReactComponent as Tag } from "../../Assets/Svg/Tag.svg";
import { useUserStore } from "../../Context/store";
import { getUTCDate } from "../../Utils/Helper";
import { useUpdateLikeDislike } from "../../Web-Hooks/Motion/use-motion-actions";

const MotionActions = ({
  motion,
  className,
  isLoading,
  toolcontent,
  istooltip,
  setCitationPopup,
  setMotionPopup,
  setSharePopup,
  setMotion
}) => {
  const { user: loggedInUser } = useUserStore();
  const [isUserLikeMotion, setIsUserLikeMotion] = useState(
    motion?.isUserLikeMotion
  );
  const [motionLikeCount, setMotionLikeCount] = useState(
    motion?.motionLikeCount
  );
  const [isUserDislikeMotion, setIsUserDislikeMotion] = useState(
    motion?.isUserDislikeMotion
  );
  const [motionDislikeCount, setMotionDislikeCount] = useState(
    motion?.motionDislikeCount
  );

  const { mutateAsync: addLikeDisLike } = useUpdateLikeDislike();

  const handleLikeClick = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    // Prepare the Like/Dislike object
    const likeObj = createLikeObject(type);
    if (Object.values(likeObj).some((value) => value === null)) {
      toast.error("Invalid like action");
      return;
    }

    // Backup previous state for rollback in case of an error
    const previousState = {
      isUserLikeMotion,
      isUserDislikeMotion,
      motionLikeCount,
      motionDislikeCount,
    };

    // Optimistic UI update
    updateUIOptimistically(type);

    try {
      const res = await addLikeDisLike(likeObj);
      if (res?.errorMessage) {
        rollbackUIState(previousState);
        toast.error("Failed to update like/dislike");
      }
    } catch (error) {
      rollbackUIState(previousState);
      toast.error("Failed to update like/dislike: " + error.message);
    }
  };

  const createLikeObject = (type) => ({
    MotionId: motion?.motionId,
    LikedBy: loggedInUser?.userId,
    CreatedAt: getUTCDate(),
    IsLikeMotion: type === "like" ? !isUserLikeMotion : false,
    IsDisLikeMotion: type === "dislike" ? !isUserDislikeMotion : false,
  });

  const updateUIOptimistically = (type) => {
    if (type === "like") {
      setIsUserLikeMotion((prev) => {
        setMotionLikeCount((count) => (prev ? count - 1 : count + 1));
        if (isUserDislikeMotion) {
          setIsUserDislikeMotion(false);
          setMotionDislikeCount((count) => count - 1);
        }
        return !prev;
      });
    } else if (type === "dislike") {
      setIsUserDislikeMotion((prev) => {
        setMotionDislikeCount((count) => (prev ? count - 1 : count + 1));
        if (isUserLikeMotion) {
          setIsUserLikeMotion(false);
          setMotionLikeCount((count) => count - 1);
        }
        return !prev;
      });
    }
  };

  const rollbackUIState = (previousState) => {
    setIsUserLikeMotion(previousState.isUserLikeMotion);
    setIsUserDislikeMotion(previousState.isUserDislikeMotion);
    setMotionLikeCount(previousState.motionLikeCount);
    setMotionDislikeCount(previousState.motionDislikeCount);
  };

  const Actions = [
    {
      SVG: Like,
      SVGFilled: FilledLike,
      Liked: isUserLikeMotion,
      count: motionLikeCount,
      isFilled: isUserLikeMotion,
      handleClick: (e) => handleLikeClick(e, "like"),
    },
    {
      SVG: DisLike,
      SVGFilled: FilledDisLike,
      Liked: isUserDislikeMotion,
      count: motionDislikeCount,
      isFilled: isUserDislikeMotion,
      handleClick: (e) => handleLikeClick(e, "dislike"),
    },
    {
      SVG: Tag,
      action: "tagCitation",
      count: motion?.citationCount,
      toolcontent: "Tag citation",
      isSvg: true,
      handleClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCitationPopup(true);
        setMotionPopup(motion);
      },
      isFilled: false,
    },
    {
      SVG: Share,
      action: "shareMotion",
      toolcontent: "Share Motion",
      handleClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        setMotion(motion);
        setSharePopup(true);
      },
      count: null,
    },
  ];

  return (
    <>
      {Actions.map((action, index) => (
        <Fragment key={index}>
          <Tooltip
            content={toolcontent}
            className={`bg-white text-black border border-black ${
              !istooltip ? "hidden" : ""
            }`}
          >
            <button
              className={`bg-transparent text-secondary rounded-sm p-2 ${
                isLoading ? "cursor-not-allowed" : "cursor-pointer"
              } flex justify-center gap-2 items-center ${className}`}
              onClick={action.handleClick}
              disabled={isLoading}
            >
              {action?.isFilled ? (
                <action.SVGFilled className="text-secondary h-6 w-6 hover:scale-110" />
              ) : (
                <action.SVG className="text-secondary h-6 w-6 hover:scale-110" />
              )}
              <span className="text-debatePalette-shadow font-semibold">
                {action?.count !== null ? `(${action?.count})` : ""}
              </span>
            </button>
          </Tooltip>
        </Fragment>
      ))}
    </>
  );
};

export default MotionActions;
