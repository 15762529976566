import {
  Bars3Icon,
  BellIcon,
  EllipsisVerticalIcon,
  QuestionMarkCircleIcon,
  StarIcon as StarIconOutline,
} from "@heroicons/react/24/outline";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Rating,
} from "@material-tailwind/react";
import React, { useState } from "react";
import NavigationButton from "../../Components/Functionality/NavigationButton";
import { ProfileMenuOptions } from "../../Utils/Constant";

import { useNavigate } from "react-router-dom";

import {
  MagnifyingGlassIcon,
  StarIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import InfoPopup from "../../Components/Popup/InfoPopup";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";
import BurgerMenu from "../../Components/UI/Menus/BurgerMenu";
import TrialPeriodPlanTimer from "./TrialPeriodPlanTimer";
import UpgradeButton from "./UpgradeButton";

export default function Header({
  search,
  setSearch,
  isNewWorkSpace,
  openProfile,
  openDrawerNotification,
}) {
  const navigate = useNavigate();
  const [openRateus, setOpenRateus] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rate, setRate] = useState(0);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div className=" flex flex-row justify-center h-full relative z-100 bg-sideLayoutPrimary py-3 ">
      {!isNewWorkSpace && (
        <div className="w-full flex flex-row ">
          <div className="flex flex-row justify-center">
            <div className="w-[72px] flex justify-center">
              <BurgerMenu
                setToggle1={setOpen}
                toggle1={open}
                ripple={false}
                Options={ProfileMenuOptions}
                openProfile={openProfile}
                openDrawerNotification={openDrawerNotification}
              >
                <BurgerMenu.ButtonMenu
                  ripple={false}
                  label={
                    <Bars3Icon
                      id="burger-menu"
                      color="black"
                      className="h-8 w-8 block  cursor-pointer text-debatePalette-background hover:text-debatePalette-buttonBorder"
                    />
                  }
                />
              </BurgerMenu>
            </div>
            <div className="flex w-64 justify-end">
              <NavigationButton />
            </div>
          </div>
          <div className="flex flex-1 flex-row justify-between px-2">
            <div className="relative flex w-full md:w-[75%] 2xl:w-[55%] items-center justify-between">
              <input
                id="globalSearch"
                type="search"
                className="h-12 m-0 block w-[1px] min-w-0 flex-auto rounded border border-none bg-clip-padding px-3 py-[0.25rem] text-debatePalette-background font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none motion-reduce:transition-none dark:border-neutral-500 dark:text-neutral-200 dark:placeholder:text-neutral-200  placeholder:text-debatePalette-background placeholder:text-lg bg-sideLayoutSecondary"
                placeholder="Search"
                autoComplete="list"
                value={search}
                aria-autocomplete="list"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className={`!absolute right-3 top-[10px] z-10`}>
                {search ? (
                  <XMarkIcon
                    className="h-5 w-5 text-debatePalette-background cursor-pointer"
                    onClick={() => setSearch("")}
                  />
                ) : (
                  <MagnifyingGlassIcon className="h-5 w-5 text-debatePalette-background" />
                )}
              </div>
            </div>
            <div className="flex flex-row  justify-end items-center">
              <div className="md:hidden">
                <Menu placement="bottom-start">
                  <MenuHandler>
                    <Button
                      istooltip={`${true}`}
                      toolcontent={"Help"}
                      variant="text"
                      className="border-none p-2"
                    >
                      <EllipsisVerticalIcon className="h-8 w-8 " />
                    </Button>
                  </MenuHandler>
                  <MenuList className="min-w-[0rem]">
                    <MenuItem>
                      {" "}
                      <Button
                        variant="text"
                        ripple={false}
                        className="border-none p-2 md:hidden flex gap-2 items-center"
                        onClick={() => navigate("/notification")}
                      >
                        <BellIcon className="h-8 w-8 " /> Notification
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        istooltip={`${true}`}
                        toolcontent={"Help"}
                        variant="text"
                        className="border-none p-2 flex gap-2 items-center"
                      >
                        <QuestionMarkCircleIcon className="h-8 w-8 " /> Help
                      </Button>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
              <div className="flex gap-2 items-center ">
                <TrialPeriodPlanTimer />

                <UpgradeButton />
                {/* <select
                  name="plan"
                  id="plan"
                  defaultValue={"Free"}
                  onChange={(e) => updatePlan(e.target.value)}
                >
                  <option
                    value="9e402b39-8b33-4530-b152-1581976c738c"
                    id="free"
                  >
                    Free
                  </option>
                  <option
                    value="158c7111-e288-433d-840d-83c358083a66"
                    id="team"
                  >
                    Team
                  </option>
                  <option value="9fd60f57-d334-43af-ac3e-43919e8117ae" id="pro">
                    Pro
                  </option>
                  <option
                    value="01b131b9-e75a-4222-8e30-1d14f7514578"
                    id="enterprise"
                  >
                    Enterprise
                  </option>
                  <option
                    value="cca9abd6-7d8e-4ac3-ade2-a3d4700de40d"
                    id="enterprise"
                  >
                    AIWithPro
                  </option>
                  <option
                    value="19782cda-cbe8-451d-af81-6efb8799afe0"
                    id="enterprise"
                  >
                    AIWithEnterprise
                  </option>
                </select> */}
              </div>

              {/* <Button
                istooltip={`${true}`}
                toolcontent={"Help"}
                variant="text"
                className="border-none p-2 hidden md:block"
              >
                <Tooltip
                  content="Help"
                  className="bg-white  text-black border border-black"
                >
                  <QuestionMarkCircleIcon className="h-8 w-8 text-debatePalette-background" />
                </Tooltip>
              </Button> */}
            </div>
          </div>
        </div>
      )}{" "}
      {isNewWorkSpace && <div className="h-12 py-3"></div>}
      {open ? (
        <InfoPopup
          title={"Rate us"}
          description={"Enjoying the Debatebase? Give us a feedback!"}
          onClickBtn1={() => {
            setOpen(false);
            setOpenFeedback(true);
          }}
          onClickBtn2={() => {
            setOpen(false);
            setOpenRateus(true);
          }}
          btn1={"Give Feedback"}
          btn2={"Rate us"}
          open={open}
          handleClose={() => setOpen(false)}
        />
      ) : null}
      {openFeedback ? (
        <EditableBodyPopup
          open={openFeedback}
          handleClose={() => {
            setOpenFeedback(false);
            setFeedback("");
          }}
          title={"Feedback"}
          children={
            <DebateTextArea
              id={"feedback"}
              maxLength={200}
              placeholder={"Enter your feedback here."}
              value={feedback}
              handleChange={(e) => setFeedback(e.target.value)}
            />
          }
          onClickBtn1={() => {
            setOpenFeedback(false);
            setFeedback("");
          }}
          onClickBtn2={() => {
            setOpenFeedback(false);
            setFeedback("");
          }}
          btn1={"Cancel"}
          btn2={"Submit"}
        />
      ) : null}
      {openRateus ? (
        <EditableBodyPopup
          open={openRateus}
          handleClose={() => {
            setOpenRateus(false);
            setRate(0);
          }}
          title={"Rate us"}
          children={
            <Rating
              ratedIcon={<StarIcon className="h-20 w-20" />}
              unratedIcon={<StarIconOutline className="h-20 w-20" />}
              style={{ fontSize: "40px" }}
              value={rate}
              onChange={(value) => setRate(value)}
              className="flex justify-center"
            />
          }
          onClickBtn1={() => {
            setOpenRateus(false);
            setRate(0);
          }}
          onClickBtn2={() => {
            setOpenRateus(false);
          }}
          btn1={"Cancel"}
          btn2={"Submit"}
        />
      ) : null}
    </div>
  );
}
