import React from "react";
import LinkExpiry from "../Assets/Images/LinkExpiry.png";
import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../Context/store";

const InvitationLinkExpired = () => {
  const {user} = useUserStore();
  const navigate = useNavigate();


  return (
    <section className="h-screen flex flex-col md:flex-row items-center justify-between p-8">
      <div className="flex flex-col justify-center items-start gap-4 md:w-1/3 w-full">
        <Typography variant="h1">Oops, this link is not valid</Typography>
        <Typography variant="lead">
          Maybe the link is expired, revoked, or maybe it was not copied
          correctly
        </Typography>
        <Button
          className="bg-primary text-secondary font-semibold"
          onClick={() => navigate(user ? "/feed-public" : "/", { replace: true })}
        >
          Back to {user ? "Home" : "Login"}
        </Button>
      </div>
      <div className="flex flex-col w-full h-full md:w-2/3 md:h-2/3 justify-center items-center">
        <img src={LinkExpiry} alt="" className="h-full w-full sm:w-2/3 xl:w-1/2" />
      </div>
    </section>
  );
};

export default InvitationLinkExpired;
