import { ArrowRightIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DocImage from "../../../Assets/Images/doc.png";
import PDFImage from "../../../Assets/Images/pdf.png";
import URLImage from "../../../Assets/Images/url.png";
import VIDImage from "../../../Assets/Images/video.png";
import { ReactComponent as DoubleChecked } from "../../../Assets/Svg/DoubleChecked.svg";
import { ReactComponent as SingleChecked } from "../../../Assets/Svg/SibgleChecked.svg";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { useUserStore } from "../../../Context/store";
import { useFileViewer } from "../../../Context/FileViewerContext";
import { notifyCss } from "../../../Utils/Constant";
import {
  extractTitle,
  formatTimestampToReadableDate,
  getUserImageUrl,
  isTodayOrYesterday,
} from "../../../Utils/Helper";

const GroupMessageCard = ({ message, group, members, setSelectedMessage }) => {
  const { handleFile } = useFileViewer();
  const { content, senderId, timestamp } = message;
  const { user } = useUserStore();
  const sender = senderId;

  const getSenderName = (id) => {
    const sender = members?.find((member) => member.id === id);
    if (sender?.id === user?.userId) return "you";
    if (sender?.id === group?.created_by) return "admin";
    return sender?.username;
  };

  const IsLoggedInUser = content?.content?.userId === user?.userId;

  const initialNameMessage = IsLoggedInUser
    ? "you"
    : content?.content?.userName;
  const initialVerbMessage = IsLoggedInUser ? "have" : "has";

  const renderContent = () => {
    switch (content?.type) {
      case "text":
        return <span>{content?.content?.text}</span>;

      case "image":
        return (
          <img
            src={content?.content?.url}
            className="w-72 sm:w-80 lg:w-96 cursor-zoom-in"
            alt="message-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "image",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "doc":
        return (
          <img
            src={DocImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="document-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "document",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "pdf":
        return (
          <img
            src={PDFImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="pdf-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "pdf",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "video":
        return (
          <img
            src={VIDImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="video-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "video",
                fileName: extractTitle(content?.content?.url),
                createdBy: content?.senderUsername,
                creatorImage: content?.senderAvatar,
                channelName: content?.workspaceName,
                fileUploadedAt: timestamp,
              });
            }}
          />
        );

      case "url":
        return (
          <img
            src={URLImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="video-img"
            onClick={() => {
              window.open(content?.content?.url, "_blank");
            }}
          />
        );
      case "profile":
        return (
          <div className="flex flex-col gap-2">
            <Card className="max-w-96 sm:max-w-96 lg:w-[500px] lg:max-w-[500px]">
              <CardBody className="p-3">
                <div
                  key={content?.content?.userId}
                  className="flex items-center justify-between"
                >
                  <div className="grid grid-cols-[auto_1fr] items-center gap-x-3">
                    <ImageOrInitials
                      imageSrc={content?.content?.userImage}
                      initials={content?.content?.userName}
                      className="shrink-0"
                      size="w-12 h-12"
                    />
                    <div className="flex flex-col gap-1">
                      <p
                        color="blue-gray"
                        className="text-lg font-bold capitalize text-debatePalette-title line-clamp-1"
                      >
                        {content?.content?.userName}
                      </p>
                      <p
                        variant="small"
                        className="text-base text-secondary font-semibold line-clamp-1"
                      >
                        {content?.content?.workspaceName}
                      </p>
                      <Link
                        to={`/profile/${content?.content?.userId}?userName=${content?.content?.userName}`}
                        className="flex items-center gap-x-2 text-[13px] text-debatePalette-link hover:underline"
                      >
                        View Profile <ArrowRightIcon className="size-4" />
                      </Link>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      case "motion":
        return (
          <div className="flex flex-col gap-2">
            <Card className="max-w-96 sm:max-w-96 lg:w-[500px] lg:max-w-[500px]">
              <CardBody className="p-3">
                <div
                  key={content?.content?.motionId}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center gap-x-3">
                    <div className="flex flex-col gap-2">
                      <p className="text-secondary text-base font-semibold leading-tight line-clamp-3">
                        {content?.content?.motion}
                      </p>
                      <Typography
                        variant="small"
                        className="truncate text-[13px] text-secondary line-clamp-1"
                      >
                        Posted in {content?.content?.subworkspaceName}
                      </Typography>
                      <div className="flex gap-2 items-center justify-between">
                        <Typography
                          variant="small"
                          className="truncate text-[13px] text-secondary line-clamp-1"
                        >
                          {formatTimestampToReadableDate(
                            content?.content?.createAt
                          )}
                        </Typography>

                        <Link
                          onClick={() => {}}
                          to={`/find-motion?search=${content?.content?.motion}`}
                          className="flex items-center text-[13px] text-debatePalette-link hover:underline"
                        >
                          View Motion
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      case "debate":
        return (
          <Card className="max-w-96 sm:max-w-96 lg:w-[500px] lg:max-w-[500px]">
            <CardBody className="p-3">
              <div
                key={content?.content?.userId}
                className="flex items-center justify-between"
              >
                <div className="grid w-full grid-cols-[auto_1fr] items-center gap-x-3">
                  <ImageOrInitials
                    imageSrc={content?.content?.userImage}
                    initials={content?.content?.userName}
                    className="shrink-0 w-10 h-10"
                    size="w-12 h-12"
                  />
                  <div className="flex flex-col w-full gap-2">
                    <Typography
                      color="blue-gray"
                      className="capitalize"
                      variant="h6"
                    >
                      {content?.content?.userName}
                    </Typography>
                    <p className="line-clamp-3 text-justify text-normal text-secondary leading-tight font-semibold">
                      {content?.content?.motion}
                    </p>
                    <Typography
                      variant="small"
                      className="truncate text-[13px] text-secondary line-clamp-1 overflow-y-scroll"
                    >
                      Posted in {content?.content?.workspaceName}
                    </Typography>
                    <div className="flex gap-2 items-center justify-between">
                      <Typography
                        variant="small"
                        className="truncate text-[13px] text-secondary"
                      >
                        {formatTimestampToReadableDate(
                          content?.content?.createAt
                        )}
                      </Typography>
                      <Link
                        onClick={() => {}}
                        to={`/debate/${content?.content?.debateId}?round=${content?.content?.currentRound}`}
                        className="flex items-center text-[13px] text-debatePalette-link hover:underline"
                      >
                        View Debate
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        );
      default:
        return <span>Unsupported message type</span>;
    }
  };

  const isSame =
    initialNameMessage === getSenderName(content?.content?.addedByUserId);

  const renderGroupContent = () => {
    switch (content?.type) {
      case "user_added":
        return (
          <span className={notifyCss}>
            {initialNameMessage} {initialVerbMessage} been added{" "}
            {isSame ? "" : "by"}
            {isSame ? "" : ` ${getSenderName(content?.content?.addedByUserId)}`}
          </span>
        );
      case "user_removed":
        return (
          <span className={notifyCss}>
            {initialNameMessage} {initialVerbMessage} been removed by{" "}
            {getSenderName(content?.content?.removedByUserId)}
          </span>
        );
      case "user_left":
        return (
          <span className={notifyCss}>
            {initialNameMessage} {initialVerbMessage} left the group
          </span>
        );
      case "group_updated":
        return <span className={notifyCss}>{content?.content?.text}</span>;
      default:
        return <span>Unsupported message type</span>;
    }
  };

  const isAllUserRead = () => {
    if (!message?.readBy) return false;
    return group?.users?.every((user) => message?.readBy.includes(user));
  };

  const isAllRead = isAllUserRead();

  const commonMessageType = [
    "user_added",
    "user_removed",
    "user_left",
    "group_updated",
  ];

  const RenderMainContent = () => {
    switch (content?.type) {
      case "text":
      case "doc":
      case "pdf":
      case "image":
      case "profile":
      case "debate":
      case "motion":
      case "url":
      case "video":
        return (
          <Fragment>
            {sender !== user.userId && (
              <div className="shrink-0 flex flex-col gap-2 items-center mr-4">
                <ImageOrInitials
                  imageSrc={getUserImageUrl(
                    message?.senderId,
                    message?.senderAvatar
                  )}
                  initials={message?.senderUsername}
                />
              </div>
            )}
            <div
              className={`flex-col flex ${
                sender === user.userId ? "items-end" : "items-start"
              }`}
            >
              <sub
                className={`flex flex-row gap-1 ${
                  sender === user.userId ? "justify-end" : "justify-start"
                } w-full items-start pb-2 shrink-0`}
              >
                <span
                  className={`text-xs text-center font-semibold capitalize ${
                    sender === user.userId ? "hidden" : "block"
                  }`}
                >
                  {message?.senderUsername},
                </span>
                <span className="text-xs">
                  {isTodayOrYesterday(timestamp) === "Today"
                    ? moment(timestamp).fromNow()
                    : moment(timestamp).format("HH:mm")}
                </span>
                {senderId === user.userId &&
                  (isAllRead ? (
                    <DoubleChecked className="w-4 text-secondary h-4" />
                  ) : (
                    <SingleChecked className="w-4 text-secondary h-4 " />
                  ))}
              </sub>
              <div className="flex gap-2 group">
                <div
                  className={`message-bubble flex flex-col gap-1 items-start  ${
                    sender === user.userId ? "sent" : "received"
                  }`}
                >
                  {renderContent()}
                </div>
                {sender === user.userId && (
                  <div className="hidden justify-end shrink-0 mr-4 group-hover:flex ">
                    <IconButton
                      className="p-0"
                      variant="text"
                      size="sm"
                      onClick={handleDeleteMessage}
                    >
                      <TrashIcon className="h-4 w-4 text-debatePalette-timer" />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        );
      case "user_added":
      case "user_removed":
      case "user_left":
      case "group_updated":
        return (
          <div className="flex justify-center w-full">
            {renderGroupContent()}
          </div>
        );
      default:
        return (
          <div className="flex justify-center w-full">
            <span>can't render</span>
          </div>
        );
    }
  };

  const handleDeleteMessage = async () => {
    setSelectedMessage(message);
  };

  
  return (
    <div
      className={`message-container w-full ${
        sender === user.userId
          ? `justify-end ${
              commonMessageType?.includes(content?.type)
                ? "pl-0"
                : "sm:pl-[25%] pl-0"
            }`
          : `justify-start ${
              commonMessageType?.includes(content?.type)
                ? "pr-0"
                : "sm:pr-[25%] pr-0"
            }`
      }`}
    >
      {RenderMainContent()}
    </div>
  );
};

export default GroupMessageCard;
