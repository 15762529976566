import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { AddFollowing, getFollowingList } from "../../api/profile/follwing";
import { useWorkspaceStore } from "../../Context/store";
import {
  updateDynamicFollowerFollowing
} from "../../Utils/Helper";

export const useGetFollowingList = (userId, workSpaceId, search, enabled) =>
  useInfiniteQuery({
    queryKey: ["followings", userId, workSpaceId, search],
    queryFn: ({ pageParam = 1 }) =>
      getFollowingList(userId, workSpaceId, search, pageParam),
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!workSpaceId && !!enabled,
  });

export const useAddFollowing = () => {
  const queryClient = useQueryClient();
  const { selectedWorkspace } = useWorkspaceStore();
  const [search] = useSearchParams();
  const query = search?.get("search");
  return useMutation({
    mutationFn: async (followingBody) => {
      const data = await AddFollowing(followingBody);
      const { message } = data;
      return {
        ...message,
        workSpaceId: followingBody?.workspaceId || selectedWorkspace.workSpaceId,
        query: query,
      };
    },
    onSuccess: async (data) => {
      await Promise.all([
        queryClient.setQueryData(
          ["followings", data?.followerUserId, data?.workSpaceId, data?.query],
          (oldData) =>
            updateDynamicFollowerFollowing(
              oldData,
              data?.followingUserId,
              "followingLists",
              "following"
            )
        ),
        queryClient.setQueryData(
          ["followers", data?.followerUserId,data?.workSpaceId, data?.query],
          (oldData) =>
            updateDynamicFollowerFollowing(
              oldData,
              data?.followingUserId,
              "followerLists",
              "follower"
            )
        ),
        
        queryClient.setQueryData(
          ["search-users", data?.followerUserId, encodeURIComponent(data?.query)],
          (oldData) =>
            updateDynamicFollowerFollowing(
              oldData,
              data?.followingUserId,
              "userList",
              "searchUser"
            )
        ),
        queryClient.invalidateQueries({
          queryKey: ["other-user"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["user"],
        }),
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
