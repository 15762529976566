import React, { useEffect, useState } from "react";
// import RichTextEditor fr./MessageScreen
import {
  InformationCircleIcon,
  PencilSquareIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import { Button, Typography } from "@material-tailwind/react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import EditableBodyPopup from "../../../Components/Popup/EditableBodyPopup";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import { useUserStore } from "../../../Context/store";
import { useSocket } from "../../../Context/SocketContext";
import { useWorkspaceStore } from "../../../Context/store";
import { usePersistedState } from "../../../Services/usePersistedState";
import {
  useClearChatGroup,
  useGetGroupUsersList,
} from "../../../Web-Hooks/Chat/use-group-chat";
import { useBlockUser } from "../../../Web-Hooks/Profile/use-profile";
import MessageMenu from "../individual/MessageMenu";
import AddGroupMember from "./AddGroupMember";
import GroupDetailsPopup from "./GroupDetailsPopup";
import GroupMessageInput from "./GroupMessageInput";
import GroupMessageScreen from "./GroupMessageScreen";
import UserView from "./UserView";

const Group = () => {
  const location = useLocation();
  const { state } = location;
  const { group } = state;
  const { user: loggedInUser } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const { socket } = useSocket();
  const { mutateAsync: blockUser, isPending: blockUserLoading } =
    useBlockUser();
  const { mutateAsync: clearChat, isPending: clearChatPending } =
    useClearChatGroup();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [clearChatPopup, setClearChatPopup] = useState(false);
  const [openGroupDetailsPopup, setOpenGroupDetailsPopup] = useState(false);
  const [memberPopup, setMemberPopup] = useState(false);
  const [groupDetails, setGroupDetails] = usePersistedState(
    "groupDetails",
    group
  );

  useEffect(() => {
    setGroupDetails(group);
  }, [group, setGroupDetails]);

  const { data: groupMembers } = useGetGroupUsersList(
    groupDetails?.id,
    true,
    (data) => data.data
  );

  const isLoggedInUserAdmin = !!groupMembers?.find(
    (member) => member?.id === loggedInUser?.userId && member?.isAdmin === true
  );

  const CitationOption = [
    {
      id: 1,
      label: "Clear Chat",
      value: "clear",
      handleClick: () => {
        setClearChatPopup(true);
      },
    },
    // {
    //   id: 2,
    //   label: "Block User",
    //   value: "block",
    //   handleClick: () => {
    //     setConfirmationPopup(true);
    //   },
    // },
  ];

  const handleBlockUser = () => {
    try {
      const blockBody = {
        BlockedUserId: groupDetails?.userId,
        BlockFrom: "UserToUser",
        // BlockFrom: "DebateWise",
        WorkSpaceId: selectedWorkspace?.workSpaceId,
        BlockedByUserId: loggedInUser?.userId,
        // DebateId: "7f691cb3-5c7d-436f-b174-b58442e900b4",
      };
      const res = blockUser(blockBody);
      if (res) {
        toast.success("User blocked successfully", "block");
      }
    } catch (error) {
      console.log("Something went wrong", "error");
    }
  };

  const handleClearChat = async () => {
    try {
      const chatObj = {
        userId: loggedInUser?.userId,
        id: groupDetails?.id,
      };
      await clearChat(chatObj);
      setClearChatPopup(false);
    } catch (error) {
      console.log("Something went wrong", "error");
      setClearChatPopup(false);
    }
  };

  useEffect(() => {
    if (socket && loggedInUser && groupDetails) {
      if (!socket?.connected) return;
      socket.emit("joinGroup", {
        groupId: groupDetails?.id,
        userId: loggedInUser?.userId,
      });
      socket.emit("readAllMessages", {
        groupId: groupDetails?.id,
        userId: loggedInUser?.userId,
      });
    }
  }, [socket, loggedInUser, groupDetails]);

  return (
    <section className="grid grid-rows-[auto_1fr] gap-0">
      <InfoPopup
        open={confirmationPopup}
        btn1={"Cancel"}
        btn2={"Block"}
        loading2={blockUserLoading}
        onClickBtn2={handleBlockUser}
        onClickBtn1={() => setConfirmationPopup(false)}
        title="Block User"
        description="Are you sure you want to block this user?"
        handleClose={() => setConfirmationPopup(false)}
      />
      <InfoPopup
        open={clearChatPopup}
        btn1={"Cancel"}
        btn2={"Clear"}
        loading2={clearChatPending}
        onClickBtn2={handleClearChat}
        onClickBtn1={() => setClearChatPopup(false)}
        title="Clear Chat"
        description="Are you sure you want to clear this chat? All messages will be deleted."
        handleClose={() => setClearChatPopup(false)}
      />
      <div className="sticky top-0 bg-white z-10">
        <div className="px-3 md:px-8 h-12 md:h-16 flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <Typography variant="h4" className="text-2xl capitalize">
              {groupDetails?.name}
            </Typography>
            <Button
              variant="text"
              size="sm"
              onClick={() => {
                setOpenGroupDetailsPopup(true);
              }}
            >
              {isLoggedInUserAdmin ? (
                <PencilSquareIcon className="w-6 h-6 text-secondary" />
              ) : (
                <InformationCircleIcon className="w-6 h-6 text-secondary" />
              )}
            </Button>
            {isLoggedInUserAdmin && (
              <Button
                variant="text"
                size="sm"
                onClick={() => {
                  setMemberPopup(true);
                }}
              >
                <UserPlusIcon className="w-6 h-6 text-secondary" />
              </Button>
            )}
          </div>

          <div className="relative flex gap-2 items-center">
            <UserView
              groupMembers={groupMembers}
              loggedInUser={loggedInUser}
              groupDetails={groupDetails}
              setGroupDetails={setGroupDetails}
              isLoggedInUserAdmin={isLoggedInUserAdmin}
            />
            <MessageMenu options={CitationOption} />
          </div>
        </div>
        <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
      </div>
      <div className="flex flex-col w-full h-[calc(100vh-10.5rem)] md:h-[calc(100vh-8.4rem)]">
        <div className="flex-1 overflow-y-auto" id="scrollableDiv">
          <GroupMessageScreen group={groupDetails} />
        </div>
        <div className="bg-transparent px-6 pb-2">
          <GroupMessageInput
            sender={loggedInUser}
            group={groupDetails}
            setGroupDetails={setGroupDetails}
          />
        </div>
      </div>

      <EditableBodyPopup
        open={openGroupDetailsPopup}
        handleClose={() => setOpenGroupDetailsPopup(false)}
        title="Group Details"
        data={groupDetails}
      >
        <GroupDetailsPopup
          groupDetails={groupDetails}
          setGroupDetails={setGroupDetails}
          groupMembers={groupMembers}
          isLoggedInUserAdmin={isLoggedInUserAdmin}
          handleClose={() => setOpenGroupDetailsPopup(false)}
        />
      </EditableBodyPopup>
      <EditableBodyPopup
        open={memberPopup}
        handleClose={() => setMemberPopup(false)}
        title="Add Group Members"
        data={groupDetails}
      >
        <div className="grid h-80 grid-cols-[1fr] gap-4 items-start justify-around">
          <AddGroupMember
            groupDetails={groupDetails}
            setGroupDetails={setGroupDetails}
            groupMembers={groupMembers}
            handleClose={() => setMemberPopup(false)}
          />
        </div>
      </EditableBodyPopup>
    </section>
  );
};

export default Group;
