import { create } from "zustand";

const useUserStore = create((set) => {
  return {
    user: JSON.parse(localStorage?.getItem("user")) || undefined,
    isLoggedIn: !!JSON.parse(localStorage?.getItem("user")) || undefined,
    setUser: (data) => {
      set((state) => {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...state.user, ...data  })
        );
        return { user: { ...state.user, ...data }, isLoggedIn: true };
      });
    },
    login: (userData) => {
      localStorage.setItem("user", JSON.stringify(userData));
      set({ user: userData, isLoggedIn: true });
    },
    logout: () => {
      localStorage.clear();
      set({ user: null, isLoggedIn: false });
    },
  };
});

const useUserProfileStore = create((set) => {
  return {
    profileDetail: null,
    setProfileDetail: (data) => set({ profileDetail: data }),
    updateProfileDetail: (data) =>
      set((state) => ({ profileDetail: { ...state.profileDetail, ...data } })),
  };
});

const useWorkspaceStore = create((set) => {
  return {
    selectedWorkspace:
      JSON?.parse(localStorage?.getItem("selectedWorkspace")) || undefined,
    workspaceList: [],
    setWorkspaceList: (list) => set({ workspaceList: list }),
    selectWorkspace: (workspace) => {
      set((state) => {
        localStorage?.setItem("selectedWorkspace", JSON.stringify(workspace));
        return {
          selectedWorkspace: workspace,
          workspaceList: state?.workspaceList,
        };
      });
    },

    UpdateWorkspace: (workspace) => {
      set((state) => {
        localStorage?.setItem(
          "selectedWorkspace",
          JSON.stringify({
            ...state?.selectedWorkspace,
            ...workspace,
          })
        );
        return {
          selectedWorkspace: {
            ...state?.selectedWorkspace,
            ...workspace,
          },
          workspaceList: state?.workspaceList,
        };
      });
    },
  };
});

const useSubworkspaceStore = create((set) => {
  return {
    selectedSubWorkspace:
      JSON?.parse(localStorage?.getItem("selectedSubWorkspace")) || undefined,
    subWorkspaceList: [],
    SubWorkspaceUser: [],
    setSubWorkspaceUser: (list) => set({ SubWorkspaceUser: list }),
    setSubWorkspaceList: (list) => set({ subWorkspaceList: list }),
    selectSubWorkspace: (subWorkspace) => {
      set((state) => {
        localStorage?.setItem(
          "selectedSubWorkspace",
          JSON.stringify(subWorkspace)
        );
        return {
          selectedSubWorkspace: subWorkspace,
          subWorkspaceList: state?.subWorkspaceList,
          SubWorkspaceUser: state?.SubWorkspaceUser,
        };
      });
    },
    UpdateSubWorkspace: (subWorkspace) => {
      set((state) => {
        localStorage?.setItem(
          "selectedSubWorkspace",
          JSON.stringify({
            ...state?.selectedSubWorkspace,
            ...subWorkspace,
          })
        );
        return {
          selectedSubWorkspace: {
            ...state?.selectedSubWorkspace,
            ...subWorkspace,
          },
          subWorkspaceList: state?.subWorkspaceList,
          SubWorkspaceUser: state?.SubWorkspaceUser,
        };
      });
    },
  };
});

export { useUserStore, useWorkspaceStore, useUserProfileStore, useSubworkspaceStore };
