import React from "react";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import FeedHeader from "../Components/Functionality/FeedHeader";
import ImageOrInitials from "../Components/Functionality/ImageOrInitials";
import { useWorkspaceStore } from "../Context/store";
import { extractPathname } from "../Utils/Helper";
const NewWorkspaceLayout = () => {
  const { selectedWorkspace: user } = useWorkspaceStore();
  const location = useLocation();
  const { pathname } = location;
  const isNewWorkSpace = true;

  return (
    <div className="w-full ">
      <div className="flex-column ">
        <div className=" flex flex-row md:justify-end z-100 w-full relative bg-sideLayoutPrimary p-2 md:p-3 ">
          <div className="h-12 py-3"></div>{" "}
        </div>
        <div className="flex md:flex-row flex-col">
          <div className=" flex relative ">
            <section className="h-full md:w-fit w-full px-2 lg:px-4 lg:py-4 bg-sideLayoutPrimary bg-secondary-solid border-none shadow-none">
              {isNewWorkSpace && (
                <div className="w-12">
                  {" "}
                  {pathname !== "/createprofile" ? (
                    <ImageOrInitials initials={user?.workSpaceName} />
                  ) : null}
                </div>
              )}{" "}
            </section>{" "}
            <div
              className={`hidden bg-secondary h-full  md:block overflow-y-auto w-60`}
            >
              <div className="h-16 px-4 flex justify-between items-center ">
                {pathname !== "/createprofile" && (
                  <span className="flex text-ellipsis w-full truncate  text-xl text-debatePalette-background">
                    {user?.WorkSpaceName || user?.workSpaceName}
                  </span>
                )}
              </div>
              {pathname !== "/createprofile" && (
                <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
              )}
            </div>
          </div>
          <div className="w-full h-full">
            <div
              className={`h-[calc(100vh-4.5rem)] w-full overflow-y-scroll scrollbar-hide`}
            >
              {extractPathname(pathname)[0] !== "createprofile" && extractPathname(pathname)[0] !== "add-colleague" && (
                <FeedHeader apiType={pathname} AvtarStackCount={0} />
              )}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewWorkspaceLayout;
