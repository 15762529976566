
// Workspace Routes
export const ADD_WORKSPACE = "/User/CreateWorkSpaceWithUser";
export const CREATE_WORKSPACE = "/User/CreateWorkspace";
export const GET_WORKSPACE = "/User/GetWorkspacesListUserIdWise";
export const GET_ALL_WORKSPACE = "/User/GetWorkspacesList";
export const UPDATE_WORKSPACE = "/User/UpdateWorkSpace";
export const REMOVE_WORKSPACE = "/User/RemoveWorkspace";
export const GET_USERS_IN_WORKSPACE = "/User/ListOfWorkSpaceUser";
export const GET_ALL_PENDING_REQUESTS = "/User/GetPendingInvitationList";

// Sub Workspace Routes
export const ADD_SUBWORKSPACE = "/User/AddNewSubWorkSpaceUser";
export const GET_SUBWORKSPACE = "/User/GetSubworkspaces";
export const GET_ALL_SUBWORKSPACE = "/User/GetAllSubWorkspace";
export const UPDATE_SUBWORKSPACE = "/User/UpdateSubWorkSpace";
export const REMOVE_SUBWORKSPACE = "/User/RemoveSubWorkspace";
export const GET_USERS_IN_SUBWORKSPACE = "/User/ListOfSubWorkSpaceUser";
export const ADD_USERS_IN_SUBWORKSPACE = "/User/AddNewSubWorkSpaceUser";
export const ADD_USER_IN_SUBWORKSPACE = "/User/AddUserToSubWorkSpace"
export const EDIT_USERS_IN_SUBWORKSPACE = "/User/EditUserToSubWorkSpace"
export const ADD_USERS_IN_SUBWORKSPACE_EMAILS = "/User/ExistingSubWorkSpaceAddMultipleUsersEmail"
export const REMOVE_USER_FROM_WORKSPACE = "/User/DeleteUserFormWorkSpace";
export const REMOVE_USER_FROM_SUBWORKSPACE = "/User/DeleteUserFormSubWorkSpace";

// Auth Routes
export const SIGNUP = "/User/SignUp";
export const SIGNUP_V1 = "/User/SignUpFromWebsite";
export const LOGIN = "/User/LogIn";
export const USER_CHECK = "/User/GetUserProfileWithOutToken";
export const JOIN_WORKSPACE= "/User/JoinWorkspace"
export const REFRESH_TOKEN = "/User/RefreshToken";

// Profile Routes
export const GET_PROFILE = "/User/GetUserProfile";
export const UPDATE_PROFILE = "/User/UpdateUserProfileData";
export const USER_BLOCK = "/User/AddBlockUser";
export const USER_UNBLOCK = "/User/UnBlockUser";
export const USER_DETAILS = "/User/UserProfileVisit";
export const USER_DEBATES = "/Debate/UserProfileVisitDebateList";
export const GET_ACTIVE_DEBATES = "/Debate/ListOfActiveDebate";
export const GET_PENDING_DEBATES = "/Debate/pendingDebateList";
export const GET_DRAFTS_DEBATES = "/Debate/ListOfDraftDebate";
export const GET_WON_LIST = "/Debate/DebateWonList";
export const GET_LOST_LIST = "/Debate/DebateLostList"; 
export const GET_OPEN_DEBATE_LIST = "/Debate/ListOfOpenDebate";
export const GET_SAVE_DEBATE_LIST = "/User/MySaveList";
export const GET_ALL_FILTER_CATEGORY_COUNT = "/User/ListOfCategoryCountForAllFilter";

// Category Routes
export const ADD_USER_CATEGORY = "/Debate/UserCreatedCategory";
export const GET_CATEGORY_BY_SUBWORKID = "/Debate/CategoryListWorkSpaceWise";

// Followers Routes
export const FOLLOW_USERS_LIST = "/User/FollowerList";

// Following Routes
export const FOLLOWING_USERS_LIST = "/User/FollowingList";
export const ADD_FOLLOWER ="/User/AddFollowing";


// Notification Routes
export const GET_USER_NOTIFICATIONS = "/User/NotificationList";
export const GET_USER_SCHEDULAR_SETTINGS = "/User/GetNotificationSchedule";
export const RESET_USER_SCHEDULAR_SETTINGS = "/User/ResetNotificationSchedule";
export const UPDATE_USER_SCHEDULAR_SETTINGS = "/User/SetNotificationSchedule";
export const GET_USER_SUBWORKSPACE_NOMINATION = "/User/NominationList";
export const GET_NOTIFICATION_STATUS_WORKSPACE_LIST = "/User/GetNotificationToggle";
export const UPDATE_WORKSPACE_NOTIFICATION_STATUS = "/User/SaveNotificationToggle";
export const UPDATE_ACCEPT_REJECT_NOMINATION = "/Debate/AcceptRejectNomination"
export const UPDATE_NOTIFICATION = "/Debate/NotificationActionTake"

// Validation Name Routes
export const VALIDATE_SPACE_NAMES = "/User/WorkSpaceVarification"

// Add Debate Routes
export const ADD_DEBATE_SUBWORKSPACE = "/Debate/AddDebate";
export const ADD_DEBATE_RULE = "/Debate/AddDebateRule";
export const ADD_CITATION = "/Debate/AddCitations";
export const REMOVE_CITATION = "/Debate/RemoveCitation";
export const ADD_NOMINATION = "/Debate/AddNominateToDebate";
export const GET_DEBATE_TYPE_DETAILS = "/Debate/Debatetypes";
export const GET_DEBATE_DETAILS = "/Debate/GetDebateDetails?debateId=";
export const ADD_OPPONENT_ARGUMENT = "/Debate/AddOpponentArgument";
export const ADD_NEXT_ROUND_ARGUMENT = "/Debate/AddNextRoundAgument";
export const ADD_OTHER_USER_ARGUMENT = "/Debate/AddOtherUserArgument";
export const UPDATE_RE_DEBATE = "/Debate/AddRedebateCount";
export const CONVERT_OPEN_DEBATE = "/Debate/OpenDebateFromNotificationList";
export const NOMINATE_NEW_USER = "/Debate/NominateUserFromNotification";
export const DEBATE_RESULT = "/Debate/AllRoundResult";
export const GET_DEBATE_DETAILS_PAGE = "/Debate/GetDebateDetailsWithArgument";
export const GET_DEBATE_CITATIONS = "/Debate/ListOfDebateCitation";
export const GET_USER_ARGUMENTS = "/Debate/ArguemntListForAddCitation";

//Debate Action Routes
export const UPDATE_LIKE_DEBATES = "/Debate/AddLikeToDebate";
export const UPDATE_VOTE_DEBATES = "/Debate/VoteToDebate";
export const SAVE_DEBATE = "/Debate/AddMySaveDebate";
export const REPORT_USER = "/User/AddReportByUser";
export const BLOCK_USER = "/User/AddBlockUser";

// School Routes
export const ADD_UPDATE_SCALER = "/Debate/UserAddUpdateScaler"

// Reference
export const REFERENCE_LIST = "/User/ListOfReference";
export const SAVED_REFERENCE_LIST = "/User/ListOfMySavedReference";
export const ADD_REFERENCE = "/User/AddUpdateReference";
export const SAVE_REFERENCE = "/User/AddRemoveFromMySavedReference";
export const DELETE_REFERENCE = "/User/DeleteReference";

// Role
export const ROLE_CATEGORIES = "/User/GetUserRoles";

// Upload Citation
export const UPLOAD_CITATION = "/Debate/UploadCitationFile";
export const DELETE_BLOB = "/Debate/DeleteBlob";
export const COPY_URL_FOLDER = "/Debate/CopyFileFromLibraryToCitaion"

// Library
export const GET_CITATION_USERWISE = "/User/listofLibraryCitation";
export const UPLOAD_CITATION_LIBRARY = "/User/AddCitationInLibrary";
export const REMOVE_CITATION_LIBRARY = "/User/DeleteLibraryCitation";
export const GET_CITATION_DESCRIPTION = "/User/websiteScrappingUrl?incomingurl="
export const UPDATE_CITATION = "/User/UpdateCitation";
export const UPLOAD_CITATION_EXECUTOR = "/citation/content-executor-tool";

// Debate List Routes
export const GET_PUBLIC_FEED_DEBATE = "/Debate/PublicFeedPage";
export const GET_PUBLIC_DEBATED = "/Debate/UserPublicDebatedDebateList";
export const GET_PUBLIC_VOTED = "/Debate/UserPublicVotedDebatedList";
export const GET_SEMI_PUBLIC_FEED_DEBATE = "/Debate/SubworkSpaceWiseFeedPage";
export const GET_SEMI_PUBLIC_DEBATED = "/Debate/UserDebatedDebateList";
export const GET_SEMI_PUBLIC_VOTED = "/Debate/UserVotedDebatedList";
export const GET_PRIVATE_DEBATE = "/Debate/PrivateDebateList";
export const GET_DEBATED_USER_LIST = "/Debate/RecentlyDebatedUserList";
export const GET_VOTED_USER_LIST = "/Debate/RecentlyVotedUserList";
export const GET_PRIVATE_USER_LIST = "/Debate/RecentlyPrivateDebatedUserList";

// Motion Routes
export const ADD_MOTION = "/Debate/SuggestAMotion";
export const FIND_MOTION_LIST ="/Debate/FindMotionByFilter"
export const CATEGORIES_COUNT_LIST = "/Debate/ListOfCategoryCountForMotion";
export const GET_MOTION_CITATIONS = "/Debate/ListOfMotionCitation"

// Motion Action Routes
export const LIKE_DISLIKE_MOTION = "/Debate/MotionLikeDislike";

// RoleManagement Routes
export const GET_MEMBERS_LIST = "/User/RoleManagementList";

// Search Routes
export const SEARCH_USERS = "/User/SearchUser";
export const SEARCH_DEBATES = "/Debate/SearchDebate";

// Agent Routes
export const GLOBAL_TOPIC_SUGGESTIONS = "/topic-suggestion";
export const ARGUMENT_ASSISTANCE = "/argument-assistant";
export const CATEGORIES_ASSISTANCE = "Category-Image-validate";
export const MOTION_ASSISTANCE = "validate-motion";
export const CITATION_VALIDATOR_AGENT="citation-validate-test"
export const CITATION_MULTI_VALIDATOR_AGENT="/citation/content-executor-tool"
export const CHECK_MULTI_CITATION_VALIDATOR_AGENT="/citation/multiple-citation-validator"
export const CITATION_SUGGESTION_AGENT="citation-suggestion-test"
export const CATEGORIES_SUGGESTION_AGENT="category-suggestion"
export const DEBATE_SUMMARIZATION = "debate-summarization"
export const AI_SESSION_LOGIN = "/api/chat/start_session/"
export const AI_SESSION_LOGOUT = "/api/chat/logout/"

// Chatbots
export const POST_QUESTIONS = "/api/chat/question"
export const GET_SESSION_HISTORY = "/api/chat/history"

// NewsList
export const GET_NEWS_LIST = "/User/NewsList";

// Subscription Plan Routes
export const GET_SUBSCRIPTION_PLAN = "/User/GetAllPlans";
export const GET_USER_SUBSCRIPTION_PLAN = "/User/GetUserPurchasePlanDetails";
export const CREATE_CUSTOMER ="/User/CreateCustomer";
export const CREATE_PAYMENT_INTENT ="/User/CreatePaymentIntent";
export const GET_PRODUCT ="/User/GetSubscriptionPlan";
export const GET_CUSTOMER_PAYMENT_METHODS ="/User/GetPaymentMethods";
export const GET_CUSTOMER_DETAILS ="/User/GetCustomerDetail";
export const GET_UPCOMING_INVOICE ="/User/ViewUpcomingInvoiceDetails";
export const CREATE_SUBSCRIPTION = "/User/CreateSubscription"
export const CREATE_SETUP_INTENT = "/User/CreateSetupIntent"
export const SET_PAYMENT_METHOD = "/User/SetDefaultPaymentMethod"
export const ATTACH_PAYMENT_METHOD = "/User/AttachPaymentMethod"
export const DETACH_PAYMENT_METHOD = "/User/DetachPaymentMethod"
export const CANCEL_SUBSCRIPTION = "/User/CancelSubscription"
export const CANCEL_SCHEDULE = "/User/CancelScheduleSubscription"