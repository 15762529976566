import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import React, { useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { GetCopiedURL } from "../../api/debate/debate";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import CitationPopup from "../../Components/Popup/CitationPopup";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import DebateChip from "../../Components/UI/Chip/DebateChip";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { usePlan } from "../../Context/PlanContext";
import { useUserStore, useWorkspaceStore } from "../../Context/store";
import { useSubworkspaceStore } from "../../Context/store";
import { primaryColor, shadowBlue } from "../../Utils/Constant";
import { getUTCDate } from "../../Utils/Helper";
import { useMultiCitationCheckAgent } from "../../Web-Hooks/Agents/use-citation-agent";
import {
  useAddCitation,
  useAddNextRoundArgument,
  useAddOpponentArgument,
  useAddOtherUserArgument,
  useGetDebateDetails,
  useRemoveCitation,
} from "../../Web-Hooks/Debate/use-debate";
import {
  useDeleteBlob,
  useUploadCitation,
} from "../../Web-Hooks/LibraryAPI/use-citation";
import {
  useUpdateNominationStatus,
  useUpdateNotificationStatus,
} from "../../Web-Hooks/Notification/use-notification";
import AddPhoto from "../CreateDebate/AddPhoto";
import CitationList from "../CreateDebate/CitationList";
import VoteScaler from "../Home/VoteScaler";

const AddArgument = () => {
  const navigate = useNavigate();
  const [isFromExternal, setIsFromExternal] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [citation, setCitation] = useState([]);
  const [argument, setArgument] = useState("");
  const location = useLocation();
  const { pathname, search: searchParams } = location;
  const nominatedObj = location?.state;
  const query = new URLSearchParams(searchParams);
  const isOtherUserSide = query.get("isOtherUserSide");
  const isAddPhoto = query.get("isAddPhoto");
  const nextRound = query.get("roundNo");
  const isNextRound = query.get("isNextRound");
  const isOpponentSide = !isNextRound && !isOtherUserSide ? true : false;
  const debateId = pathname?.split("/")[2];
  const { currentPlan } = usePlan();
  const limitCitation = currentPlan?.maxCitationCount;
  const { user } = useUserStore();
  const wordLimit = 500;
  const { selectWorkspace, workspaceList } = useWorkspaceStore();
  const { selectedSubWorkspace, selectSubWorkspace } = useSubworkspaceStore();
  const { data: debateDetails, isLoading: isDetailsLoading } =
    useGetDebateDetails(debateId, user?.userId, true);
  const [vote, setVote] = useState("");
  const {
    mutateAsync: addOpponentArgument,
    isPending: isAddOpponentArgumentPending,
  } = useAddOpponentArgument();
  const {
    mutateAsync: addOtherUserArgument,
    isPending: isAddOtherUserArgumentPending,
  } = useAddOtherUserArgument();
  const {
    mutateAsync: addNextRoundArgument,
    isPending: isNextRoundArgumentPending,
  } = useAddNextRoundArgument();
  const { mutateAsync: addCitation, isPending: isAddCitationPending } =
    useAddCitation();
  const [selectedCitation, setSelectedCitation] = useState({
    citationUrl: "",
    citationNote: "",
    citationType: "",
  });
  const { mutateAsync: checkMultipleCitation } = useMultiCitationCheckAgent();
  const {
    mutateAsync: uploadCitationOnServer,
    isPending: isUploadCitationPending,
  } = useUploadCitation();
  const isCreator = user?.userId === debateDetails?.createdUserId;
  const [debateInfo, setDebateInfo] = useState({
    DebateImage: null,
    FileSize: null,
  });
  const { mutateAsync: handleStatusChange, isPending: isStatusChangePending } =
    useUpdateNominationStatus();
  const {
    mutateAsync: handleStatusChangeNotification,
    isPending: isStatusChange,
  } = useUpdateNotificationStatus();
  const { mutateAsync: removeCitation } = useRemoveCitation();
  const { mutateAsync: deleteBlob } = useDeleteBlob();

  const initialVote = debateDetails?.userSideForDebateScalerPoint
    ? JSON.parse(debateDetails?.userSideForDebateScalerPoint)
    : 0;

  useLayoutEffect(() => {
    setVote(
      debateDetails?.userSideForDebateScalerPoint
        ? JSON.parse(debateDetails?.userSideForDebateScalerPoint)
        : 0
    );
  }, [debateDetails]);

  const handleStateUpdate = (update) => {
    setDebateInfo(update);
    localStorage.setItem("Debate", JSON.stringify(update));
  };

  const isOpponentAddPhoto = isOpponentSide && debateDetails?.round === 1;

  const debateUploadFilePath = `${process.env.REACT_APP_DEBATE_UPLOAD}/${debateDetails?.workSpaceId}/${debateDetails?.subWorkSpaceId}`;

  const handlePublish = async () => {
    const OpponentArgument = {
      DebateId: debateId,
      OpponentId: user?.userId,
      OpponentOpeningArgument: argument,
      SubWorkSpaceId: debateDetails?.subWorkSpaceId,
      ScalerPoint: vote,
      CreatedAt: getUTCDate(),
      OpponentDebateImage: debateInfo?.DebateImage?.split("/")?.pop(),
    };
    const NextRoundArgument = {
      DebateId: debateId,
      Argument: argument,
      Round: nextRound,
      ScalerPoint: vote,
      CreatedAt: getUTCDate(),
    };
    const OtherArgument = {
      DebateId: debateId,
      OtherUserArgument: argument,
      CreatedAt: getUTCDate(),
      OtherUserId: user?.userId,
      Round: debateDetails?.round,
      Side: isOtherUserSide,
      ScalerPoint: vote,
    };

    if (!isNextRound && !isCreator && !isOtherUserSide) {
      if (nominatedObj) {
        if (nominatedObj?.NominationId) {
          const res = await handleStatusChange(nominatedObj);
          toast.success(res?.message?.message);
        } else if (nominatedObj?.NotificationId) {
          const res = await handleStatusChangeNotification(nominatedObj);
          toast.success(res?.message?.message);
        }
      }
    }

    try {
      const data =
        isNextRound && isCreator
          ? await addNextRoundArgument(NextRoundArgument)
          : isOtherUserSide
          ? await addOtherUserArgument(OtherArgument)
          : await addOpponentArgument(OpponentArgument);

      if (data && citation?.length > 0) {
        const customizedCitation = citation.map(
          ({ citationFrom, citationFile, ...cite }) => {
            if (cite?.citationType?.toLowerCase() !== "url") {
              return {
                ...cite,
                citationUrl: cite?.citationUrl?.split("/")?.pop(),
              };
            }
            return cite;
          }
        );
        // calling AI
        await addCitation({
          InvitationId: data?.invitationId ? data?.invitationId : null,
          ArgumentId: data?.argumentId ? data?.argumentId : null,
          debateId: debateId,
          userId: user?.userId,
          subWorkSpaceId:
            debateDetails?.subWorkSpaceId ||
            selectedSubWorkspace?.subWorkSpaceId,
          bySide: userSideForDebate,
          round: isNextRound ? nextRound : debateDetails?.round,
          citations: customizedCitation,
        });
      }
      // navigate(
      //   debateDetails?.isDebatePublic
      //     ? "/feed-public"
      //     : debateDetails?.isDebateSemiPublic
      //     ? "/feed"
      //     : "/private"
      // );
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
    }

    const findWorkspaceFromUserWorkspaceList = (workspaceId) => {
      return workspaceList?.find(
        (workspace) => workspace.workSpaceId === workspaceId
      );
    };

    const workspace = findWorkspaceFromUserWorkspaceList(
      debateDetails?.workSpaceId
    );

    const subworkspaceList = await getSubWorkspaceByWorkId(
      debateDetails?.workSpaceId,
      user?.userId
    );
    const findSubWorkspaceFromUserSubWorkspaceList = (subWorkspaceId) => {
      return subworkspaceList?.listOfSubworkspaces?.find(
        (subWorkspace) => subWorkspace.subWorkSpaceId === subWorkspaceId
      );
    };

    const subworkspace = findSubWorkspaceFromUserSubWorkspaceList(
      debateDetails?.subWorkSpaceId
    );

    if (workspace && subworkspace) {
      selectWorkspace(workspace);
      selectSubWorkspace(subworkspace);
      navigate(
        debateDetails?.isDebatePublic
          ? "/feed-public"
          : debateDetails?.isDebateSemiPublic
          ? "/feed"
          : "/private"
      );
      return;
    }
    navigate(
      debateDetails?.isDebatePublic
        ? "/feed-public"
        : debateDetails?.isDebateSemiPublic
        ? "/feed"
        : "/private"
    );
  };

  const handleCitationDelete = async (selectedCitation) => {
    const newCitation = citation.filter(
      (item) => item.citationUrl !== selectedCitation?.citationUrl
    );
    setCitation(newCitation);

    if (selectedCitation?.citationType !== "url") {
      const Blob = { fileUrl: selectedCitation?.citationUrl };
      await deleteBlob(Blob);
    }

    if (selectedCitation?.isCitationFromDatabase) {
      const citationToRemove = {
        CitationId: selectedCitation?.citationId,
        UserId: user?.userId,
      };

      if (isInvalidObjectValues(citationToRemove)) {
        return;
      }
      removeCitation(citationToRemove);
    }
  };

  const isInvalidObjectValues = (obj) => {
    return Object.values(obj).some(
      (item) => item === null || item === undefined || item === ""
    );
  };

  const handleCitationSubmit = async (selectedCitation) => {
    try {
      let citationUrl = selectedCitation?.citationUrl;
      const isFileUploaded = Boolean(selectedCitation?.citationFile);

      // If citationFile exists, upload it first
      if (isFileUploaded) {
        const formData = new FormData();
        formData.append("file", selectedCitation.citationFile);
        formData.append("filePath", `${debateUploadFilePath}`);

        try {
          const { data } = await uploadCitationOnServer(formData);
          citationUrl = data.data;
        } catch (error) {
          toast.error("Error uploading citation", error);
          return;
        }
      }

      if (selectedCitation?.citationFrom !== "library") {
        // Validate the URL using handleMultipleCitationValidation
        const validationResponse = await handleMultipleCitationValidation({
          url: citationUrl,
        });

        if (!validationResponse?.success) {
          if (isFileUploaded) {
            await handleCitationDelete({
              citationUrl,
              citationType: selectedCitation.citationType,
            });
          }
          return;
        }
      }

      // Add citation to the list
      const isAlreadyAdded = citation?.some(
        (item) => item?.citationUrl === citationUrl
      );

      if (!isAlreadyAdded) {
        const newCitation = {
          ...selectedCitation,
          citationUrl,
        };

        // Handle external citations
        if (selectedCitation?.citationFrom === "external") {
          setCitation([...citation, newCitation]);
          return;
        }

        if (selectedCitation.citationType === "url" || isFileUploaded) {
          setArgument(argument);
          setCitation([...citation, newCitation]);
          return;
        }

        // Handle copied file citations only when not uploaded
        if (!isFileUploaded) {
          const filePath = citationUrl?.split("debatemodule/")?.pop();

          const copiedData = await GetCopiedURL(filePath, debateUploadFilePath);
          const newUrl = `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${debateUploadFilePath}/${copiedData}`;

          // Validate the new URL
          const newValidationResponse = await handleMultipleCitationValidation({
            url: newUrl,
          });

          if (!newValidationResponse?.success) {
            await handleCitationDelete({
              citationUrl: newUrl,
              citationType: selectedCitation.citationType,
            });
            return;
          }

          setArgument(argument);
          setCitation([...citation, { ...newCitation, citationUrl: newUrl }]);
        }
      } else {
        setArgument(argument);
        setCitation([...citation]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMultipleCitationValidation = async (selectedCitation) => {
    try {
      const data = await checkMultipleCitation({
        citation_url: [selectedCitation?.url],
        debate_id: debateId,
        user_id: user?.userId,
        sessionid: user?.sessionId,
      });

      if (data?.data?.Matched) {
        handleCitationDelete({ citationUrl: selectedCitation?.url });
        toast.error("Citation already exists, Please try another.");
        return {
          success: false,
          message: "Citation already exists, Please try another.",
        };
      }

      return { success: true };
    } catch (error) {
      console.error("Error validating citation", error);
      return { success: false, message: "Validation failed" };
    }
  };

  const handleVoteChange = (value) => {
    setVote(value);
  };

  const handleContentChange = (value) => {
    const inputText = value;
    const words = inputText.trim().split(/\s+/);
    if (words.length <= wordLimit) {
      setArgument(inputText);
    } else {
      const limitedWords = words.slice(0, wordLimit).join(" ") + " ";
      setArgument(limitedWords);
    }
  };

  const userSideForDebate = isCreator
    ? debateDetails?.sideForDebate?.toLowerCase()
    : isOtherUserSide
    ? isOtherUserSide?.toLowerCase()
    : debateDetails?.opponentSideForDebate
    ? debateDetails?.opponentSideForDebate?.toLowerCase()
    : debateDetails?.sideForDebate?.toLowerCase() === "agree"
    ? "disagree"
    : debateDetails?.sideForDebate?.toLowerCase() === "disagree"
    ? "agree"
    : "";

  return (
    <div className="px-8 my-4">
      <>
        {/* <DebateHeader content={content} /> */}
        <section className="w-full">
          <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4">
            <div className="relative flex flex-col gap-2 my-2 w-full">
              {!isOtherUserSide &&
                !isNextRound &&
                !isAddPhoto &&
                isOpponentAddPhoto && (
                  <AddPhoto
                    debateInfo={{
                      ...debateDetails,
                      ...debateInfo,
                    }}
                    handleStateUpdate={handleStateUpdate}
                  />
                )}
              <p className="text-lg font-semibold">
                Write the motion or claim you want to debate!
              </p>{" "}
              <div className={`${shadowBlue} p-3 rounded-md`}>
                <Typography
                  variant="h6"
                  className="text-debatePalette-shadow font-bold text-lg"
                >
                  {isDetailsLoading ? (
                    <LoadingSpinner
                      type={"ThreeDots"}
                      width={"30px"}
                      height={"30px"}
                      color={primaryColor}
                    />
                  ) : (
                    debateDetails?.motionOrClaim
                  )}
                </Typography>
              </div>
            </div>
            <DebateTextArea
              id={"argument"}
              value={argument}
              handleChange={(e) => {
                handleContentChange(e.target.value);
              }}
              label={`Argument`}
              maxLength={500}
              placeholder={"Argument"}
            />
            <div className="flex sm:flex-row flex-col justify-center items-center w-full my-4">
              <CitationComponent
                from={["library", "gallary", "device", "external"]}
                isLoadingFromHandleSubmit={isUploadCitationPending}
                handleCitationSubmit={async (file) =>
                  await handleCitationSubmit(file)
                }
                debateDetails={argument}
                query={"isFromAddArgument=true"}
                citation={citation}
                key={"addArgument"}
                MenuHandlerComponent={
                  <Button
                    variant="text"
                    className="px-3 py-2 flex gap-2 items-center"
                    disabled={citation?.length >= limitCitation}
                  >
                    <PlusCircleIcon className="h-8 w-8 text-debatePalette-buttonBorder " />
                    <span className="text-[#008BFA] text-base font-normal lowercase first-letter:uppercase">
                      Add a citation
                    </span>
                  </Button>
                }
              />
            </div>
            {citation.length > 0 && (
              <CitationList citation={citation} setCitation={setCitation} />
            )}
            <div className="flex flex-col gap-6 justify-center items-center py-6">
              <VoteScaler
                setScalerPoint={handleVoteChange}
                scalerPoint={vote || initialVote}
                userSideForDebate={userSideForDebate}
              />{" "}
              <DebateChip
                option={[{ label: "Agree" }, { label: "Disagree" }]}
                label={
                  isCreator
                    ? debateDetails?.sideForDebate[0].toUpperCase() +
                      debateDetails?.sideForDebate.slice(1)
                    : isOtherUserSide
                    ? isOtherUserSide[0]?.toUpperCase() +
                      isOtherUserSide?.slice(1)
                    : debateDetails?.sideForDebate?.toLowerCase() === "agree"
                    ? "Disagree"
                    : debateDetails?.sideForDebate?.toLowerCase() === "disagree"
                    ? "Agree"
                    : ""
                }
                setLabel={() => {}}
              />
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-between w-full ">
              <div className="flex gap-3 w-full justify-end">
                <BasicButton
                  loading={
                    isAddOpponentArgumentPending ||
                    isAddCitationPending ||
                    isNextRoundArgumentPending ||
                    isAddOtherUserArgumentPending ||
                    isStatusChangePending ||
                    isStatusChange
                  }
                  isDisable={!argument}
                  className={`w-24 flex justify-center rounded-md bg-primary text-debatePalette-title`}
                  onClick={handlePublish}
                >
                  {"Publish"}
                </BasicButton>
              </div>
            </div>
          </div>
        </section>

        {popupOpen &&
          (isUploadCitationPending ? (
            <Spinner />
          ) : (
            <CitationPopup
              handleSubmit={handleCitationSubmit}
              setSelectedCitation={setSelectedCitation}
              popupOpen={popupOpen}
              selectedCitation={selectedCitation}
              setPopupOpen={(pop) => {
                setPopupOpen(pop);
                setIsFromExternal(pop);
              }}
              isFromExternalCitation={isFromExternal}
            />
          ))}
      </>
    </div>
  );
};

export default AddArgument;
