// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkMotionAgent } from "../../api/agents/agentsApi";
import { useUserStore } from "../../Context/store";

export const useMotionAgent = (Debate_motion, enabled) => {
  const { user: loggedInUser } = useUserStore();
  return useQuery({
    queryKey: [ Debate_motion],
    queryFn: async () => {
      return await checkMotionAgent({
        Debate_motion: Debate_motion,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
    },
    select: (data) => data.data,
    enabled: !!Debate_motion && !!loggedInUser && !!enabled,
  });
};

