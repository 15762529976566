import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useUserStore } from "../../Context/store";
import { usePlan } from "../../Context/PlanContext";
import CheckOutPage from "../../Pages/SubscriptionPlan/CheckOutPage";
import {
  useCreateSetupIntent,
  useGetProductDetails,
} from "../../Web-Hooks/Subscription/use-subscription";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  apiVersion: "2024-12-18.acacia",
});

export default function PaymentGateWay() {
  const [search] = useSearchParams();
  const amount = search.get("amount");
  const plan = search.get("plan");
  const productId = search.get("productId");
  const type = search.get("type");
  const { data: products, isLoading: isProductLoading } = useGetProductDetails(
    productId,
    type
  );
  const { user: loggedInUser } = useUserStore();
  const [clientSecret, setClientSecret] = useState();
  const [customerId, setCustomerId] = useState(null);
  const { subscribedPlan } = usePlan();

  const findProduct = products?.find(
    (product) => product.productId === productId
  );
  const findPrice = findProduct?.prices?.find(
    (price) => price.recurringInterval === type
  );

  const { mutateAsync: createSetupIntent, isLoading: isCreatingSetup } =
    useCreateSetupIntent();

  useEffect(() => {
    // Initialize payment on mount
    const initializePayment = async () => {
      // Check if user and product exist
      if (!loggedInUser || !findPrice) return;

      try {
        // Create a subscription
        const { data, error } = await createSetupIntent({
          Email: loggedInUser?.email,
          // priceId: findPrice.priceId,
          // isTrialPlanAvailable: subscribedPlan?.isTrialPlanAvailable,
        });

        if (error) {
          console.error("Error creating setup intent:", error);
          return;
        }

        // Update client secret and prorated amount state
        const { clientSecret, customerId } = data;
        if (!clientSecret) {
          setClientSecret(null);
          return;
        }
        setClientSecret(clientSecret);
        setCustomerId(customerId);
      } catch (error) {
        console.error("Error initializing payment:", error);
      }
    };

    // Only initialize payment if user is logged in
    if (loggedInUser) {
      initializePayment();
    }
  }, [loggedInUser, findPrice]);

  if (isProductLoading || isCreatingSetup || clientSecret === undefined)
    return (
      <div className="h-screen flex justify-center items-center w-full">
        <LoadingSpinner /> {/* Animated loading spinner */}
      </div>
    );

  if (
    !isProductLoading &&
    !isCreatingSetup &&
    clientSecret !== undefined &&
    !clientSecret
  ) {
    return (
      <div className="min-h-screen w-full flex flex-col justify-center items-center">
        <p className="text-xl font-bold text-center">
          Something went wrong. Please try again later.
        </p>
        <div className="mt-4">
          <Link to="/upgrade-plan?plan=base-plan" replace={true}>
            <Button
              variant="outline"
              className="bg-primary text-secondary font-semibold"
            >
              Back
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-full h-full space-y-4">
        {findProduct && findPrice && clientSecret ? (
          <Elements
            stripe={stripePromise}
            options={{
              customerOptions: {
                customer: customerId,
              },
              clientSecret,
              appearance: {
                theme: "stripe",
              },
              loader: "always",
            }}
          >
            <Card className="border-secondary border rounded-lg p-2 w-full sm:w-[90%]  2xl:w-[70%]  ">
              <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 rounded-none text-center"
              >
                <Typography variant="h3" className="text-secondary">
                  Plan: {plan}
                </Typography>
                {subscribedPlan?.isTrialPlanAvailable && (
                  <Typography variant="h4" className="text-secondary">
                    Start your free trial simply adding your card details (14
                    Days Free Trial)
                  </Typography>
                )}
              </CardHeader>
              <CardBody>
                <div id="payment-element">
                  <CheckOutPage
                    clientSecret={clientSecret}
                    amount={amount}
                    price={findPrice}
                    subscribedPlan={subscribedPlan}
                  />
                </div>
              </CardBody>
            </Card>
          </Elements>
        ) : (
          <div className="h-screen flex justify-center items-center w-full">
            <h1 className="text-2xl font-bold">No Plan Found</h1>
          </div>
        )}
      </div>
    </div>
  );
}
