import { SparklesIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";
import React, { Fragment } from "react";
import { usePlan } from "../../Context/PlanContext";
import { useNavigate } from "react-router-dom";

const UpgradeButton = () => {
  const { subscribedPlan } = usePlan();
  const navigate = useNavigate();
  return (
    <Fragment>
      {subscribedPlan?.planName === "Free" && (
        <Button
          variant="outlined"
          className="flex items-center border-primary text-primary p-2 text-xs capitalize gap-3"
          onClick={() => navigate("/upgrade-plan?plan=base-plan")}
        >
          <SparklesIcon className="h-5 w-5 text-primary" />
          UPGRADE
        </Button>
      )}
    </Fragment>
  );
};

export default UpgradeButton;
