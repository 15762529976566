import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import { useState } from "react";
import Logo from "../../Assets/Images/DB_AI.png";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { shadowBlue } from "../../Utils/Constant";
import { useGetCitationDescription } from "../../Web-Hooks/LibraryAPI/use-citation";
import Confetti from "../UI/Confetti";
import toast from "react-hot-toast";
import NoData from "./NoData";

export default function AICitationSuggestion({
  open,
  handleClose,
  handleStateUpdate,
  debateInfo,
  AIValidatorType,
  pauseAIButtonBlinking,
  citationAgent,
  setActiveBlinkingButton,
  isLoading,
}) {
  const { setCitation, citation } = useCreateDebateContext();
  const [selectedOption, setSelectedOption] = useState(null);
  // eslint-disable-next-line
  const [isVisible, setIsVisible] = useState(false);
  // console.log(citationAgent,"citationAgent")
  // Effect to set the "Custom Option" selected when inputValues is not empty

  const { data: urlData } = useGetCitationDescription(selectedOption);
  // Handle radio button changes
  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };
  // console.log(citation,"citation")
  // Handle the "Verify" button click
  const handleVerify = () => {
    setIsVisible(true);
    pauseAIButtonBlinking();
    handleClose();
    setIsVisible(false);
    // setCitation(...citation, {
    //   citationUrl: selectedOption,
    //   citationNote: `Title: ${urlData?.title}\nDescription: ${urlData?.description}`,
    //   citationType: "url",
    // });
    const isAlreadyAdded = citation?.some(
      (cite) => cite?.citationUrl === selectedOption
    );
    if (isAlreadyAdded) {
      toast.error("Citation already added");
      return;
    }
    setCitation([
      ...citation,
      {
        citationUrl: selectedOption,
        citationNote: `Title: ${urlData?.title}\nDescription: ${urlData?.description}`,
        citationType: "url",
      },
    ]);
    // handleStateUpdate({
    //   ...debateInfo,
    //   MotionOrClaim: selectedOption,
    // });
    // toast(
    //   <p className="font-bold text-green-600">Motion verified successfully</p>,
    //   {
    //     position: "top-center",
    //     icon: "🎉",
    //   }
    // );
    // handleClose();
    // setIsVisible(false);
    // setTimeout(() => {
    //    // Reset isVisible when dialog closes
    // }, 5000);
  };

  const isNotCitations = citationAgent?.error_output || citationAgent?.includes("Citation not found");
  return (
    <div className="">
      <Dialog
        open={open}
        className="rounded-[50px] border-gray-500 border-4 flex flex-col items-center p-3"
        size="xl"
      >
        <DialogHeader className="flex gap-3 justify-between">
          <div className="flex gap-4 items-center">
            <img
              src={Logo}
              alt="Logo"
              height="60"
              width="60"
              className=" rounded-full  shadow-sm "
            />
            <span className="text-primary font-semibold text-5xl">
              Suggestion
            </span>
          </div>
        </DialogHeader>
        <DialogBody className="w-full mt-3 px-3">
          <div className="flex flex-col gap-4 min-w-full text-black">
            <p className="w-full text-center">Consider using these citations</p>
            <div className="flex flex-col w-full gap-3 max-h-80 overflow-y-auto">
              {isNotCitations && (
                <div className="flex w-full justify-center items-center">
                  <NoData message={"No citations found"} size={"h-52"} />
                </div>
              )}
              {isLoading && !isNotCitations && (
                <div className="flex items-center h-20 justify-center">
                  <Spinner className="h-10 w-10 text-gray-900/50" />
                </div>
              )}
              {!isNotCitations &&
                citationAgent?.length &&
                citationAgent?.map((item) => (
                  <div
                    key={item?.CitationUrl}
                    className={` flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-5 gap-3 border-2 ${
                      selectedOption === item?.CitationUrl
                        ? shadowBlue
                        : "border-gray-400 !shadow-black"
                    } rounded-md shadow-sm`}
                    onClick={() => handleRadioChange(item?.CitationUrl)}
                  >
                    <img
                      src={
                        item?.CitationThumbnail
                          ? item.CitationThumbnail === "Logo not found"
                            ? Logo
                            : item?.CitationThumbnail
                          : Logo
                      }
                      className="shrink-0"
                      alt="Agent"
                      width={item?.CitationThumbnail ? 45 : 40}
                      height={item?.CitationThumbnail ? 45 : 40}
                    />
                    {/* <div className={`w-4 h-4 rounded-full border ${selectedOption === item.describe ? 'bg-blue-500' : 'bg-white'} mr-3`} /> */}
                    <a
                      href={item?.CitationUrl}
                      className="text-debatePalette-link w-full line-clamp-1 break-all text-wrap"
                      alt={item?.CitationUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="pt-2">{item?.CitationUrl}</span>
                    </a>
                  </div>
                ))}
              {/* Manually added option with textarea */}
            </div>
          </div>
          <div className="flex flex-col gap-4 items-center my-4">
            <div className="flex gap-4">
              {isVisible && <Confetti isVisible={isVisible} />}
              <Button
                className="bg-primary text-black rounded-full border-black border w-40"
                onClick={handleVerify}
                disabled={!selectedOption}
              >
                Use Citation
              </Button>
              <Button
                variant="outlined"
                className="rounded-full w-40"
                onClick={handleClose}
              >
                Keep Original
              </Button>
            </div>
            {AIValidatorType !== "MotionSuggestion" && (
              <Button
                variant="text"
                className="rounded-full text-debatePalette-link p-0 m-0 hover:bg-white active:bg-white focus:bg-white mt-4"
                onClick={handleClose}
              >
                Cancel
              </Button>
            )}
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
}
