import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Progress,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { AvatarStack } from "../../Components/UI/AvatarStack/SummaryAvatarStack";


const DebateCard = ({ title, images, content, refProp, minHeight }) => (
  <Card
    className="w-1/2 bg-gray-200 flex flex-col gap-4 px-8 py-4"
    style={{ minHeight }}
  >
    <div className="flex flex-row justify-between items-center">
      <Typography variant="h6" color="black">
        {title}
      </Typography>
      <AvatarStack users={images} maxVisible={3} />
    </div>
    <div ref={refProp} className="text-black">
      {content}
    </div>
  </Card>
);

const DebateSummaryPopup = ({
  isOpen,
  closeDialog,
  debateSummary,
  debateInfo,
  handleDebateView,
}) => {
  const favorRef = useRef(null);
  const opposedRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("auto");

  useEffect(() => {
    if (favorRef.current && opposedRef.current) {
      const favorHeight = favorRef.current.scrollHeight;
      const opposedHeight = opposedRef.current.scrollHeight;
      setMaxHeight(`${Math.max(favorHeight, opposedHeight)}px`);
    }
  }, [debateSummary]);

  const Users = debateSummary?.Participants;
  const FavoredUser =
    Users?.Favored || Users?.Favored_Image_URL
      ? { name: Users?.Favored, image_Url: Users?.Favored_Image_URL }
      : null;
  const OpposedUser =
    Users?.Opposed || Users?.Opposed_Image_URL
      ? { name: Users?.Opposed, image_Url: Users?.Opposed_Image_URL }
      : null;

  const favoredUsers = [
    ...(FavoredUser ? [FavoredUser] : []), // Include only if FavoredUser exists
    ...(Users?.Other_favored_participents ?? []),
  ];

  const opposedUsers = [
    ...(OpposedUser ? [OpposedUser] : []), // Include only if FavoredUser exists
    ...(Users?.Other_opposed_participents ?? []),
  ];

  const points = Number(debateSummary?.average) * 100;
  const pointsValue = points >= 0 ? points : -(points) ;
  

  return (
    <Dialog open={isOpen} handler={closeDialog} size="lg">
      <DialogHeader className="px-8 py-4 flex flex-row justify-between">
        <Typography variant="h4" color="black">
          Debate Summary
        </Typography>
        <Typography variant="paragraph" className="font-bold" color="black">
          Debate Status:{" "}
          {debateInfo?.isDebateOver ? (
            <span className="text-blue-900 font-bold underline">Completed</span>
          ) : (
            <span
              className={`${
                debateInfo?.roundStatus === "published"
                  ? "text-primary"
                  : "text-secondary"
              } font-bold underline`}
            >
              {debateInfo?.roundStatus === "published" ? "Ongoing" : "Upcoming"}{" "}
              (Round{" "}
              {`${
                debateInfo?.roundStatus === "published"
                  ? debateInfo?.currentRound
                  : debateInfo?.currentRound + 1
              }/${debateInfo?.totalRound}`}
              )
            </span>
          )}
        </Typography>
      </DialogHeader>
      <DialogBody className="border-t border-gray-200">
        {debateInfo?.currentRound === 1 &&
        debateInfo?.roundStatus === "published" ? (
          <Typography variant="h5" color="black">
            Your summary will be generated automatically once the first round is
            complete. Thank you for your patience!
          </Typography>
        ) : (
          <div className="px-8 py-4 text-left max-h-[60vh] overflow-y-auto flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <Typography variant="h6" color="black">
                Motion
              </Typography>
              <Typography variant="paragraph" color="black">
                {debateInfo?.motionOrClaim || ""}
              </Typography>
            </div>

            <div className="flex items-center gap-2">
              <div className="flex-grow border-t-4 border-primary border-double"></div>
              <Card className="shadow-md border-2 px-2 py-2 rounded-full">
                <Typography
                  variant="paragraph"
                  className="mx-4 text-secondary font-extrabold"
                >
                  {debateInfo?.isDebateOver
                    ? "Overall Summary"
                    : `SUMMARY TILL ROUND : ${debateInfo?.currentRound}`}
                </Typography>
              </Card>
              <div className="flex-grow border-t-4 border-primary border-double"></div>
            </div>

            <div className="flex flex-row gap-2">
              <DebateCard
                title="In Favor"
                images={favoredUsers}
                content={debateSummary?.Favored_Key_Arguments}
                refProp={favorRef}
                minHeight={maxHeight}
              />
              <DebateCard
                title="Opposed"
                images={opposedUsers}
                content={debateSummary?.Opposed_Key_Arguments}
                refProp={opposedRef}
                minHeight={maxHeight}
              />
            </div>

            <div className="flex flex-row gap-10">
              <div className="flex flex-col gap-2 min-w-40">
                <Typography variant="h6" color="black">
                  Overall sentiment
                </Typography>
                <Typography variant="paragraph" color="black">
                  {debateSummary?.Overall_Sentiment}
                </Typography>
                <Progress
                  value={pointsValue}
                />
              </div>
              <div className="flex flex-col gap-2">
                <Typography variant="h6" color="black">
                  Conclusion
                </Typography>
                <Typography variant="paragraph" color="black">
                  {debateSummary?.Conclusion_Statements}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </DialogBody>
      <DialogFooter className="justify-center flex gap-2 ">
        <Button color="red" onClick={closeDialog}>
          Close
        </Button>
        <Button
          className="bg-secondary text-debatePalette-background"
          onClick={handleDebateView}
        >
          View Debate
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DebateSummaryPopup;
