import { useCallback, useEffect } from "react";
import { useUserStore } from "./store";
import { SocketEvents, useSocket } from "./SocketContext";
import { useSubworkspaceStore } from "./store";
import { useWorkspaceStore } from "./store";

const useChatSocketHandlers = ({ chatUserList, setChattedUserList }) => {
  const { socket } = useSocket();
  const { user: loggedInUser } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";

  // Helper function to update user in the chat list
  const updateUserInList = useCallback(
    (workspaceId, subWorkspaceId, userId, updates) => {
      setChattedUserList((prevList) => {
        if (!prevList) return prevList;

        const index = prevList.findIndex(
          (user) =>
            user.id === userId &&
            user.workspaceId === workspaceId &&
            (user.subworkspaceid === subWorkspaceId ||
              user.subworkspaceid === subWorkspaceId)
        );
        if (index === -1) {
          // console.warn(`User with id ${userId} not found`);
          return prevList;
        }

        const updatedUser = { ...prevList[index], ...updates };
        const newList = [...prevList];
        newList[index] = updatedUser;
        return newList;
      });
    },
    [setChattedUserList]
  );

  // Sync initial user list on mount or when chatUserList changes
  useEffect(() => {
    setChattedUserList(chatUserList);
  }, [chatUserList, setChattedUserList]);

  // Handler for receiving messages and invalidating queries
  useEffect(() => {
    if (!socket) return;
    const handleReceiveMessage = async (data) => {
      const isSender = loggedInUser?.userId === data?.senderId;
      const isReceiver = loggedInUser?.userId === data?.receiverId;

      if (isSender || isReceiver) {
        const updateUserId = isReceiver ? data?.senderId : data?.receiverId;

        if(!isReceiver){
          updateUserInList(
            data?.workspaceId,
            data?.subworkspaceId,
            updateUserId,
            data
          );
          return;
        }

        setChattedUserList((prevList) => {
          if (!prevList) return prevList;

          const index = prevList.findIndex(
            (user) =>
              user?.id === updateUserId &&
              user?.workspaceId === data?.workspaceId &&
              user?.subworkspaceid === data?.subworkspaceId
          );
          if (index === -1) {
            console.warn(`User with id ${updateUserId} not found`);
            return prevList;
          }

          const updatedUser = {
            ...prevList[index],
            ...data,
            unreadCount: prevList[index]?.unreadCount + 1,
            lastMessageTimestamp: data?.timestamp,
          };
          const newList = [...prevList];
          newList[index] = updatedUser;
          return newList;
        });
 
      }
    };

    const handleUpdateSenderData = async (data) => {
      const isSender = loggedInUser?.userId === data?.senderId;
      const isReceiver = loggedInUser?.userId === data?.receiverId;

      if (isSender || isReceiver) {
        const updateUserId = isReceiver ? data?.senderId : data?.receiverId;
        const updates = isReceiver ? {} : {isRead: data?.isRead, unreadCount: data?.unreadCount};
        updateUserInList(
          data?.workspaceId,
          data?.subworkspaceId,
          updateUserId,
          updates
        );
      }
    };

    const handleUpdateUserStatus = ({ userId, online }) => {
      updateUserInList(
        selectedWorkspace?.workSpaceId,
        isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
        userId,
        { online }
      );
    };

    const handleUpdateLatestMessage = (data) => {
      if (
        data?.data?.workspaceId !== selectedWorkspace?.workSpaceId ||
        data?.data?.subworkspaceId !==
          (isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "")
      )
        return;

      const isReceiver = loggedInUser?.userId === data?.receiverId;
      const isSender = loggedInUser?.userId === data?.senderId;
      if (isReceiver || isSender) {
        const userIdToUpdate = isReceiver ? data?.senderId : data?.receiverId;
        setChattedUserList((prevList) => {
          if (!prevList) return prevList;

          const index = prevList.findIndex(
            (user) =>
              user?.id === userIdToUpdate &&
              user?.workspaceId === data?.data?.workspaceId &&
              user?.subworkspaceid === data?.data?.subworkspaceId
          );
          if (index === -1) {
            console.warn(`User with id ${userIdToUpdate} not found`);
            return prevList;
          }

          const updatedUser = {
            ...prevList[index],
            lastMessage: data?.data?.content,
            isRead: data?.data?.isRead,
            unreadCount:
              prevList[index]?.unreadCount > 0
                ? prevList[index]?.unreadCount - 1
                : 0,
          };
          const newList = [...prevList];
          newList[index] = updatedUser;
          return newList;
        });
      }
    };

    const handleMarkedAsRead = ({
      receiverId,
      unreadCount,
      workspaceId,
      subWorkspaceId,
    }) => {
      updateUserInList(workspaceId, subWorkspaceId, receiverId, {
        unreadCount,
      });
    };

    socket.on("update-receiver-data", handleReceiveMessage);
    socket.on("update-sender-data", handleUpdateSenderData);
    socket.on(SocketEvents.UPDATE_USER_STATUS, handleUpdateUserStatus);
    socket.on("messageDeleted", handleUpdateLatestMessage);
    socket.on(SocketEvents?.RECEIVER_MARKED_AS_READ, handleMarkedAsRead);

    return () => {
      socket.off("update-receiver-data", handleReceiveMessage);
      socket.off("update-sender-data", handleUpdateSenderData);
      socket.off(SocketEvents.UPDATE_USER_STATUS, handleUpdateUserStatus);
      socket.off("messageDeleted", handleUpdateLatestMessage);
      socket.off(SocketEvents?.RECEIVER_MARKED_AS_READ, handleMarkedAsRead);

    };
  }, [socket, loggedInUser, updateUserInList, isForumOnly, selectedWorkspace, selectedSubWorkspace, setChattedUserList]);
 
};

export default useChatSocketHandlers;
