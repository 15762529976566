import { UserPlusIcon } from "@heroicons/react/24/outline";
import { Button, Checkbox, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { useUserStore } from "../../../Context/store";
import { useSubworkspaceStore } from "../../../Context/store";
import { useWorkspaceStore } from "../../../Context/store";
import useDebounce from "../../../Services/useDebounce";
import { getUserImageUrl } from "../../../Utils/Helper";
import { useGetExternalData } from "../../../Web-Hooks/Chat/chat";
import { useAddMemberGroup } from "../../../Web-Hooks/Chat/use-group-chat";
import { useSocket } from "../../../Context/SocketContext";
import toast from "react-hot-toast";
import NoData from "../../../Components/Functionality/NoData";
import SearchField from "../../../Components/UI/Inputs/SearchField";

const AddGroupMember = ({ groupDetails, groupMembers, setGroupDetails, handleClose }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const deferredSearch = useDebounce(search, 500);
  const { user: loggedInUser } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { sendGroupMessage } = useSocket();
  const { data: chatUserList, isLoading: chatUserListLoading } =
    useGetExternalData(
      loggedInUser?.userId,
      selectedWorkspace?.workSpaceId,
      process.env.REACT_APP_FORUM_WISE === "true"
        ? selectedSubWorkspace?.subWorkSpaceId
        : "",
      deferredSearch,
      true
    );
  const { mutateAsync: AddGroupMember, isPending: isAddingMember } =
    useAddMemberGroup();

  const USERS = chatUserList?.filter(
    (user) => !groupMembers?.some((member) => member?.id === user?.id)
  );

  const handleChangeContacts = (member) => {
    const index = users.findIndex((contact) => contact.value === member.id);

    const newContact = {
      label: member.username,
      value: member.id,
    };
    if (index === -1) {
      setUsers([...users, newContact]);
    } else {
      const updatedContacts = [...users];
      updatedContacts.splice(index, 1);
      setUsers(updatedContacts);
    }
  };

  const handleAddMember = async () => {
    const group = {
      ...groupDetails,
      users: users?.map((contact) => contact?.value),
    };
    try {
      await AddGroupMember(group);
      const newUsers = users?.map((contact) => contact?.value);
      setGroupDetails((prev) => ({
        ...prev,
        users: [...new Set([...prev?.users, ...newUsers])],
      }));
      setUsers([]);
      users?.map(({ label, value }) => {
        const Message = {
          type: "user_added",
          content: {
            addedByUserId: loggedInUser?.userId,
            addedByUserName: loggedInUser?.userName,
            userId: value,
            userName: label,
          },
        };
        return sendGroupMessage(
          groupDetails?.id,
          loggedInUser?.userId,
          Message,
          selectedWorkspace?.workSpaceId,
          selectedSubWorkspace?.subWorkSpaceId
        );
      });
      handleClose()
    } catch (error) {
      console.error(error);
      toast.error("Error adding users in group");
    }
  };

  return (
    <div className="grid grid-rows-[auto_1fr_auto] grid-cols-1 w-full h-full items-start gap-3 justify-start">
      <SearchField search={search} setSearch={setSearch} />

      <div className="flex flex-col h-52 gap-3 overflow-y-auto w-full">
        {USERS?.length === 0 && (
          <NoData message={"No more users"} size={"h-[90%] w-full"} />
        )}
        {USERS?.map((member) => (
          <Checkbox
            key={member?.id}
            containerProps={{
              className: "!flex items-center gap-4 shrink-0",
            }}
            checked={users
              ?.map((contact) => contact?.value)
              .includes(member?.id.toString())}
            onChange={() => handleChangeContacts(member)}
            id={member?.id}
            color="blue"
            ripple={false}
            className="w-4 h-4 rounded-none before:content-none"
            label={
              <div className="flex items-center gap-2 w-full truncate">
                {
                  <ImageOrInitials
                    imageSrc={getUserImageUrl(member?.id, member?.avatar)}
                    initials={member?.username || member?.email}
                  />
                }
                <p className="w-full text-md capitalize">
                  {member?.userName || member?.email?.split("@")[0]}
                </p>
              </div>
            }
            labelProps={{
              className:
                "text-debatePalette-title w-full text-md font-semibold capitalize",
            }}
          />
        ))}
      </div>
      <div className="flex w-full justify-center">
        <Button
          variant="filled"
          size="sm"
          className="text-xs bg-primary text-debatePalette-title flex gap-2 items-center"
          disabled={chatUserListLoading || users?.length === 0}
          onClick={handleAddMember}
          loading={isAddingMember}
        >
          <UserPlusIcon className="h-4 w-4" strokeWidth={2} />
          <Typography variant="small" className="normal-case font-semibold">
            Add Member
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default AddGroupMember;
