import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { convertSubcurrencyToMain } from "../../Utils/Helper";
import { Fragment, useState } from "react";

const RenderCalculationUI = ({ upcomingInvoice, oldPlan, newPlan }) => {
  const [open, setOpen] = useState(0);
  if (!upcomingInvoice) return null;

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const creditedProrationObject = upcomingInvoice?.lines?.data?.find(
    (line) => line?.proration_details?.credited_items
  );
  const debitProrationObject = upcomingInvoice?.lines.data?.find(
    (line) => !line?.proration_details?.credited_items
  );

  return (
    <Accordion open={open === 1} className="mb-2 rounded-lg p-0">
      <AccordionHeader
        onClick={() => handleOpen(1)}
        className={`border-b-0 p-0 transition-colors text-base font-[500] ${
          open === 1
            ? "text-debatePalette-title hover:!text-secondary py-2"
            : ""
        }`}
      >
        {open === 1 ? "Hide details" : "View more details"}
      </AccordionHeader>
      <AccordionBody className="pt-0 text-base font-normal">
        <div className="grid grid-cols-1 gap-2 text-secondary text-base font-medium bg-secondary bg-opacity-10 p-2 rounded-md">
          {creditedProrationObject && (
            <div className="flex justify-between">
              <span className="text-secondary font-normal flex-1">
                Prorated credit from {oldPlan?.planName} :
              </span>
              <span className="text-secondary font-semibold">
                {convertSubcurrencyToMain(
                  creditedProrationObject?.currency,
                  creditedProrationObject?.amount
                )}
              </span>
            </div>
          )}
          {debitProrationObject && (
            <div className="flex justify-between">
              <span className="text-secondary font-normal flex-1">
                Prorated charge for {newPlan?.planName} :
              </span>
              <span className="text-secondary font-semibold">
                {convertSubcurrencyToMain(
                  debitProrationObject?.currency,
                  debitProrationObject?.amount
                )}
              </span>
            </div>
          )}
          {!!upcomingInvoice?.total && (
            <div className="flex justify-between">
              <span className="text-secondary font-normal flex-1">Total :</span>
              <span className="text-secondary font-semibold">
                {convertSubcurrencyToMain(
                  upcomingInvoice?.currency,
                  upcomingInvoice.total
                )}
              </span>
            </div>
          )}
          {(upcomingInvoice?.starting_balance !== 0 ||
            upcomingInvoice?.ending_balance !== 0) && (
              <Fragment>
                <hr className="bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
                {upcomingInvoice?.starting_balance !== 0 && (
                  <div className="flex justify-between">
                    <span className="text-secondary font-normal flex-1">
                      Applied account balance :
                    </span>
                    <span className="text-secondary font-semibold">
                      {convertSubcurrencyToMain(
                        upcomingInvoice?.currency,
                        upcomingInvoice?.starting_balance
                      )}
                    </span>
                  </div>
                )}
                {upcomingInvoice?.ending_balance !== 0 && (
                  <div className="flex justify-between">
                    <span className="text-secondary font-normal flex-1">
                       Account balance credit :
                    </span>
                    <span className="text-secondary font-semibold">
                      {convertSubcurrencyToMain(
                        upcomingInvoice?.currency,
                        upcomingInvoice?.ending_balance * -1
                      )}
                    </span>
                  </div>
                )}
                <div className="flex justify-between">
                  <span className="text-secondary font-normal flex-1">
                    Amount due today :
                  </span>
                  <span className="text-secondary font-semibold">
                    {convertSubcurrencyToMain(
                      upcomingInvoice?.currency,
                      upcomingInvoice.amount_due
                    )}
                  </span>
                </div>
              </Fragment>
            )}
        </div>
      </AccordionBody>
    </Accordion>
  );
};

export default RenderCalculationUI;
