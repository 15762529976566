import React, { Fragment, useState } from "react";
import {
  useGetSubscribedPlan,
  useGetSubscriptionPlans,
} from "../../Web-Hooks/Subscription/use-subscription";
import { PricingCard } from "../Payment/PricingCard";
import { PricingToggle } from "../Payment/PricingToggle";
import { useUserStore } from "../../Context/store";

const AISubscriptionPlan = () => {
  const [isYearly, setIsYearly] = useState(false);
  const { user } = useUserStore();

  const { data: subscriptionPlans, isLoading } = useGetSubscriptionPlans(user?.userId);

  const { data: subscribedPlan } = useGetSubscribedPlan(user?.userId);

  const renderSkeleton = (count) => {
    return Array.from({ length: count }).map((_, i) => (
      <div
        className="relative w-72 shrink-0 shadow-lg rounded-lg p-6 border border-gray-300 animate-flashy"
        key={i}
      >
        <div className="text-xl font-bold mb-4 flex justify-center text-transparent">
          <p className="bg-gray-300 w-24 rounded-lg">&nbsp;</p>
        </div>
        <div className="text-xl font-bold mb-4 h-3 flex justify-center text-transparent">
          <p className="bg-gray-300 w-24 rounded-lg">&nbsp;</p>
        </div>
        <div className="font-bold  mb-6 h-12 text-lg flex justify-center">
          <p className="bg-gray-300 w-32 text-center rounded-lg">&nbsp;</p>
        </div>
        <ul className="text-sm text-gray-300 space-y-2 h-64">
          {[...Array(8)].map((_, j) => (
            <li key={`${i}-${j}`} className="flex items-center">
              <span className="mr-2 text-transparent">✔</span>
              <span className="bg-gray-300 text-transparent rounded w-full">
                &nbsp;
              </span>
            </li>
          ))}
        </ul>
        <button className="mt-6 w-full bg-gray-300 text-transparent py-2 px-4 rounded">
          &nbsp;
        </button>
      </div>
    ));
  };

  return (
    <Fragment>
      <div className="w-full flex flex-col gap-6">
        <div className="w-full">
          <h1 className="text-5xl font-bold text-center">Select Your Plan</h1>
          <h6 className="text-md font-bold text-center">
            Pay securely online and manage the booking via desktop or via the
            mobile app.{" "}
          </h6>
        </div>
        <div className="flex justify-center w-full ">
          <PricingToggle isYearly={isYearly} onChange={setIsYearly} />
        </div>
        <div className="animate-fade-in-delay sm:container md:mx-auto">
          <div className="flex flex-wrap justify-center gap-6">
            {isLoading && renderSkeleton(4)}
            {!isLoading &&
              subscriptionPlans.map((plan, index) => (
                <div
                  key={plan.planId}
                  className="hover:scale-105 transform transition duration-300"
                  style={{ animationDelay: `${index * 100}ms` }}
                >
                  <PricingCard
                    key={index}
                    isYearly={isYearly}
                    plan={plan}
                    isDark={plan.bestValue}
                    subscribedPlan={subscribedPlan}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AISubscriptionPlan;
