import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";
import NoData from "../../Components/Functionality/NoData";
import FollowCard from "../../Components/UI/Cards/FollowCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { useUserStore, useWorkspaceStore } from "../../Context/store";
import useDebounce from "../../Services/useDebounce";
import { useGetFollowersList } from "../../Web-Hooks/Profile/use-followers";
import SearchFilter from "./SearchFilter";

const FollowerList = () => {
  const [search, setSearch] = useSearchParams();
  const { selectedWorkspace, workspaceList } = useWorkspaceStore();
  const { user } = useUserStore();
  const deferredSearch = useDebounce(search?.get("search") || "", 1000);
  const [filter, setFilters] = useState(null);
  const {
    data,
    isLoading: isFollowingListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetFollowersList(
    user?.userId,
    filter ? filter.workspace.value : selectedWorkspace?.workSpaceId,
    deferredSearch,
    search.get("selected") === "followers"
  );

  const followersList = data?.pages?.flatMap((page) => page?.followerLists);

  const workspaceOptions =
    workspaceList?.map((workspace) => {
      return {
        label: workspace.workSpaceName,
        value: workspace.workSpaceId,
      };
    }) || [];

  return (
    <div className="h-full flex w-[70%] flex-col gap-5">
      <div className="h-auto flex gap-4 justify-between w-full">
        {" "}
        <SearchFilter
          search={search?.get("search") || ""}
          setSearch={(value) =>
            setSearch({
              selected: search.get("selected"),
              search: value,
            })
          }
          showFilters={["search", "workspace"]}
          setFilters={setFilters}
          filters={
            filter || {
              workspace: {
                label: selectedWorkspace?.workSpaceName,
                value: selectedWorkspace?.workSpaceId,
              },
            }
          }
          workspaceOptions={workspaceOptions}
        />
      </div>
      <div className="flex w-full h-[90%] flex-col gap-2 overflow-y-auto">
        {isFollowingListLoading && RenderLoaderSkeleton(4, "motion")}
        {followersList?.length === 0 && !isFollowingListLoading && (
          <NoData
            isNoData={true}
            message={"No followers yet."}
            size={"w-full h-[80%]"}
          />
        )}
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isFollowingListLoading}
        >
          {followersList?.map((active) => (
            <FollowCard user={active} key={active.userId} filters={filter} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FollowerList;
