import { Checkbox } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import UserAvtarUpload from "../../Components/UI/AvatarStack/UserAvtarUpload";
import DebateInput from "../../Components/UI/Inputs/DebateInput";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";
import SearchField from "../../Components/UI/Inputs/SearchField";
import { useUserStore } from "../../Context/store";
import { useSocket } from "../../Context/SocketContext";
import { useSubworkspaceStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import { getUserImageUrl } from "../../Utils/Helper";
import { useUploadCitation } from "../../Web-Hooks/LibraryAPI/use-citation";
import { useUpdateGroup } from "../../Web-Hooks/Chat/use-group-chat";

const NewGroupPopup = ({
  groupPopup,
  setGroupPopup,
  searchValue,
  setSearchValue,
  USERS,
}) => {
  const [groupName, setGroupName] = useState("");
  const [groupImage, setGroupImage] = useState();
  const [imageFile, setImageFile] = useState(null);
  const [groupDescription, setGroupDescription] = useState("");
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [loader, setLoader] = useState(false);
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { user: loggedInUser } = useUserStore();
  const { socket } = useSocket();
  const { mutateAsync: uploadCitationOnServer, isPending: isUploading } =
    useUploadCitation();
  const queryClient = useQueryClient();
  const wordLimit = 50;
  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";
  const { mutateAsync: updateGroup, isPending: isUpdating } = useUpdateGroup();

  const handleChangeContacts = (member) => {
    const index = contacts.findIndex((contact) => contact.value === member.id);

    const newContact = {
      label: member.username,
      value: member.id,
    };
    if (index === -1) {
      setContacts([...contacts, newContact]);
    } else {
      const updatedContacts = [...contacts];
      updatedContacts.splice(index, 1);
      setContacts(updatedContacts);
    }
  };

  const handleCreateGroup = async () => {
    setLoader(true);
    if (!groupName || contacts.length === 0) {
      toast.error(
        "Please provide a group name and select at least one contact."
      );
      return;
    }

    const users = contacts.map((contact) => contact.value);

    const group = {
      name: groupName,
      description: groupDescription,
      created_by: loggedInUser?.userId,
      workspaceId: selectedWorkspace?.workSpaceId,
      subworkspaceId: selectedSubWorkspace?.subWorkSpaceId,
      users: [loggedInUser?.userId, ...users],
    };

    try {
      socket?.emit("createGroup", group);
    } catch (error) {
      toast.error("Failed to create group");
    } finally {
      socket?.on("newGroupCreated", async ({ groupId, group }) => {
        let groupImage = null;

        if (imageFile) {
          const uploadPath = isForumOnly
            ? `${process.env.REACT_APP_CHAT_UPLOAD}/group/${selectedSubWorkspace?.subWorkSpaceId}/${groupId}`
            : `${process.env.REACT_APP_CHAT_UPLOAD}/group/${groupId}`;
          const formData = new FormData();
          formData.append("file", imageFile);
          formData.append("filePath", uploadPath);
          groupImage = await uploadCitationOnServer(formData);
        }

        await updateGroup({
          ...group,
          groupAvatar: groupImage?.data?.data || group?.groupAvatar,
        });

        navigate(`/group-message/${groupId}`, {
          state: {
            group,
            groupAvatar: groupImage?.data?.data || group?.groupAvatar,
          },
        });
        await queryClient?.invalidateQueries({
          queryKey: ["group", "list", "chats"],
        });
        resetValues();
        setLoader(false);
      });

      toast.success("Group created successfully");
    }
  };

  const handleContentChange = (value) => {
    const inputText = value;
    const words = inputText.trim().split(/\s+/);
    if (words.length <= wordLimit) {
      setGroupDescription(inputText);
      return;
    } else {
      const limitedWords = words.slice(0, wordLimit).join(" ") + " ";
      setGroupDescription(limitedWords);
    }
  };

  const updateUserImage = (file, url) => {
    setGroupImage(url);
    setImageFile(file);
  };

  const resetValues = () => {
    setGroupName("");
    setContacts([]);
    setGroupDescription("");
    setGroupImage("");
    setImageFile(null);
  };

  return (
    <EditableBodyPopup
      open={groupPopup}
      handleClose={() => {
        setGroupPopup(false);
        resetValues();
      }}
      title={"Create Group"}
      size={"md"}
      children={
        <div className="flex flex-col text-debatePalette-title overflow-y-scroll">
          <div className="grid grid-cols-[auto_1fr]  gap-3 w-full place-items-center">
            <div className="w-[150px]">
              <UserAvtarUpload
                setPicture={updateUserImage}
                picture={groupImage}
                userName={groupName}
              />
            </div>
            <div className="flex flex-col w-full text-debatePalette-title">
              <DebateInput
                label={"Group Name"}
                placeholder={"Group Name"}
                maxLength={20}
                value={groupName}
                className={"text-debatePalette-title"}
                handleChange={(e) => setGroupName(e.target.value)}
              />
              <DebateTextArea
                id={"motion-textarea"}
                label={"Group Description"}
                maxLength={50}
                value={groupDescription}
                className={"text-debatePalette-title"}
                handleChange={(e) => handleContentChange(e.target.value)}
                placeholder={"Group description"}
              />
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <p className="text-lg font-semibold">Invite member</p>
            <div className="w-[80%]">
              <SearchField
                id="search-user-message"
                name="search-user-message"
                search={searchValue}
                setSearch={setSearchValue}
              />
            </div>

            <form className="flex flex-col overflow-y-scroll member-list h-52  items-start gap-2">
              {USERS?.map((member) => (
                <Checkbox
                  key={member?.id}
                  containerProps={{
                    className: "!flex items-center gap-4 shrink-0",
                  }}
                  checked={contacts
                    ?.map((contact) => contact?.value)
                    .includes(member?.id.toString())}
                  onChange={() => handleChangeContacts(member)}
                  id={member?.id}
                  color="blue"
                  ripple={false}
                  className="w-4 h-4 rounded-none before:content-none"
                  label={
                    <div className="flex items-center gap-2 w-full truncate">
                      {
                        <ImageOrInitials
                          imageSrc={getUserImageUrl(member?.id, member?.avatar)}
                          initials={member?.username || member?.email}
                        />
                      }
                      <p className="w-full text-md capitalize">
                        {member?.username || member?.email?.split("@")[0]}
                      </p>
                    </div>
                  }
                  labelProps={{
                    className:
                      "text-debatePalette-title w-full text-md font-semibold capitalize",
                  }}
                />
              ))}
            </form>
          </div>
        </div>
      }
      loading={loader || isUploading || isUpdating}
      onClickBtn1={() => {
        setGroupPopup(false);
        resetValues();
      }}
      onClickBtn2={() => {
        handleCreateGroup();
      }}
      disableBtn={!groupName || contacts.length === 0 || !groupDescription}
      btn1={"Cancel"}
      btn2={"Create"}
    />
  );
};

export default NewGroupPopup;
