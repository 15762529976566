import { CreditCardIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Container from "../../Components/UI/Container";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { usePlan } from "../../Context/PlanContext";
import {
  convertEpochToLocalTime,
  convertSubcurrencyToMain,
} from "../../Utils/Helper";
import {
  useCancelSchedule,
  useCancelSubscription,
} from "../../Web-Hooks/Subscription/use-subscription";
import { ReactComponent as AILogo } from "../../Assets/Svg/AILogo.svg";
import { primaryColor } from "../../Utils/Constant";

const ManageSubscriptionPage = () => {
  const { subscribedPlan } = usePlan();

  const queryClient = useQueryClient();

  const { mutateAsync: cancelSubscription } = useCancelSubscription();
  const { mutateAsync: cancelScheduledChange } = useCancelSchedule();
  // Destructure necessary fields from subscribedPlan
  if (!subscribedPlan) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }
  const {
    planName,
    planType,
    stripeJson,
    subscriptionId,
    scheduleSubscriptionDetails,
    isAIPlan,
  } = subscribedPlan;

  // Extract price from Stripe data (assumed to be in cents)
  const unitAmount = stripeJson?.items?.data?.[0]?.price?.unit_amount;
  const unitCurrency = stripeJson?.items?.data?.[0]?.price?.currency;
  const priceFormatted = unitAmount
    ? convertSubcurrencyToMain(unitCurrency, unitAmount)
    : "N/A";

  // Determine the next billing date using the Unix timestamp from Stripe
  const nextBillingDate = stripeJson?.current_period_end
    ? moment(convertEpochToLocalTime(stripeJson.current_period_end)).format(
        "MMMM D, YYYY"
      )
    : "N/A";

  // Check for a cancellation schedule (if cancel_at_period_end is true)
  const isCancelScheduled = stripeJson?.cancel_at_period_end;
  const cancellationDate =
    isCancelScheduled && stripeJson?.current_period_end
      ? moment(convertEpochToLocalTime(stripeJson.current_period_end)).format(
          "MMMM D, YYYY"
        )
      : null;

  // Check if scheduled plan changes exist
  const scheduledPlanExists =
    scheduleSubscriptionDetails &&
    scheduleSubscriptionDetails.planName &&
    scheduleSubscriptionDetails.planStartDate !== "0001-01-01T00:00:00";

  const ScheduledId = stripeJson?.schedule;

  const handleCancelSubscription = () => {
    if (isCancelScheduled) {
      const newWindow = window.open(
        "https://billing.stripe.com/p/login/test_eVa14k41L2Vj7hS8ww",
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
      return;
    }
    Swal.fire({
      titleText: `${planName}`,
      iconColor: primaryColor,
      html: `<div class="flex flex-col gap-4">        
                <div class="flex flex-col gap-4">
                        <p class="text-base text-justify">
                  Your subscription will be canceled, but is still available until the
                  end of your billing period on ${nextBillingDate}.
                </p>
                <p class="text-base text-justify">
                  If you change your mind, you can renew your subscription from the manage subscription page.
              <a href="https://billing.stripe.com/p/login/6oEdSzabUaQHdLacMM" target="_blank" class="text-secondary font-semibold hover:underline">(Customer Portal)</a>
                </p>
                </div>
              </div>`,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          if (ScheduledId) {
            await cancelScheduledChange(ScheduledId); // Call your async function
          }
          const result = await cancelSubscription(subscriptionId); // Call your async function
          await queryClient.invalidateQueries({
            queryKey: ["subscription"],
          });
          return result; // Pass data to the `then` block of Swal
        } catch (error) {
          Swal.showValidationMessage(`Error: ${error.message}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: primaryColor,
      confirmButtonText: "Yes, cancel it!",
    });
  };

  const handleCancelScheduledUpdate = () => {
    if (scheduledPlanExists) {
      Swal.fire({
        title: "Cancel scheduled update",
        html: `
              <p class="text-justify">Your subscription is scheduled to change at the end of your current billing period on <b>Mar 17</b>. You may cancel this change and remain on your current plan, or choose a different plan.</p>    
              <div style="margin-top: 1.5em;">
                <strong>Current Plan</strong>
                <div>${subscribedPlan?.planName} - ${convertSubcurrencyToMain(
          unitCurrency,
          unitAmount
        )}</div>
              </div>
        
              <div style="margin-top: 1.5em;">
                <strong>Scheduled Plan</strong>
                <div>${
                  scheduleSubscriptionDetails?.planName
                } - ${convertSubcurrencyToMain(
          scheduleSubscriptionDetails?.planCurrency,
          scheduleSubscriptionDetails?.planAmount
        )}</div>
              </div>
            `,
        confirmButtonColor: primaryColor,
        showCancelButton: true,
        confirmButtonText: "Cancel change",
        cancelButtonText: "Go back",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const result = await cancelScheduledChange(ScheduledId); // Call your async function
            await queryClient.invalidateQueries({
              queryKey: ["subscription"],
            });
            return result; // Pass data to the `then` block of Swal
          } catch (error) {
            Swal.showValidationMessage(`Error: ${error.message}`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });

      return;
    }
  };

  return (
    <div className="p-8 max-h-screen max-w-screen mx-auto">
      <div className="w-full flex flex-col gap-4 items-center justify-center">
        <div className="w-full flex justify-end">
          <Link
            to={process.env.REACT_APP_STRIPE_MANAGE_BASE_URL}
            target="_blank"
            variant="text"
            className="border-none p-2 bg-secondary text-debatePalette-background flex items-center gap-2 rounded-md px-4 py-2 shadow-md"
            // onClick={handleClick}
          >
            <CreditCardIcon className="h-8 w-8 text-debatePalette-background" />{" "}
            Manage Subscription
          </Link>
        </div>

        <div
          className={`grid xl:grid-flow-col gap-10 w-full ${
            scheduledPlanExists ? "items-stretch" : "place-items-center"
          }`}
        >
          <Card className="shadow-lg border-secondary border max-w-screen-md w-full flex flex-col gap-2 p-8">
            <CardHeader className=" flex bg-secondary justify-center items-center p-2 mt-0">
              <Typography variant="h5" color="white">
                Current Subscription
              </Typography>
            </CardHeader>
            <CardBody className="flex flex-col gap-3">
              <div className=" flex flex-col xl:flex-row xl:items-center gap-2 ">
                <Typography variant="h6">Plan Name:</Typography>
                <div className="flex items-center gap-2">
                  <Typography>{planName}{isAIPlan && " (AI)"}</Typography>{" "}
                  {isAIPlan &&  <AILogo className="w-6 h-6" />}
                </div>
              </div>
              <div className=" flex flex-col xl:flex-row xl:items-center  gap-2 ">
                <Typography variant="h6">Plan Type:</Typography>
                <Typography className="capitalize">{planType}</Typography>
              </div>
              <div className=" flex flex-col xl:flex-row xl:items-center  gap-2 ">
                <Typography variant="h6">Price:</Typography>
                <Typography>{priceFormatted}</Typography>
              </div>
              <div className=" flex flex-col xl:flex-row xl:items-center  gap-2 ">
                <Typography variant="h6">Next Billing Date:</Typography>
                <Typography>{nextBillingDate}</Typography>
              </div>

              {isCancelScheduled && (
                <div className=" flex flex-col xl:flex-row xl:items-center gap-2 ">
                  <Typography variant="h6" color="red">
                    {cancellationDate &&
                      `Your subscription has been scheduled to cancel on ${cancellationDate}. If you want to renew it, please do it from the manage subscription (Stripe customer portal).`}
                  </Typography>
                </div>
              )}
            </CardBody>

            {!isCancelScheduled && planName !== "Free" && planName !== "King's" && (
              <CardFooter>
                <Button color="red" onClick={handleCancelSubscription}>
                  Cancel Subscription
                </Button>
              </CardFooter>
            )}
          </Card>

          {scheduledPlanExists && (
            <Card className="shadow-lg border-secondary border max-w-screen-md w-full flex flex-col gap-2 p-8">
              <CardHeader className=" flex bg-secondary justify-center items-center p-2 mt-0">
                <Typography variant="h5" color="white">
                  Scheduled Plan Changes
                </Typography>
              </CardHeader>
              <CardBody className="flex flex-col gap-3">
                <>
                  <div className=" flex flex-col xl:flex-row xl:items-center  gap-2 ">
                    <Typography variant="h6">Plan Name:</Typography>
                    <Typography>
                      {scheduleSubscriptionDetails.planName}
                    </Typography>
                  </div>
                  <div className=" flex flex-col xl:flex-row xl:items-center  gap-2 ">
                <Typography variant="h6">Plan Type:</Typography>
                <Typography className="capitalize">{scheduleSubscriptionDetails?.planType}</Typography>
              </div>
                  <div className=" flex flex-col xl:flex-row xl:items-center  gap-2 ">
                    <Typography variant="h6">Price:</Typography>
                    <Typography>
                      {scheduleSubscriptionDetails.planAmount
                        ? convertSubcurrencyToMain(
                            scheduleSubscriptionDetails.planCurrency,
                            scheduleSubscriptionDetails.planAmount
                          )
                        : "N/A"}
                    </Typography>
                  </div>
                  <div className=" flex flex-col xl:flex-row xl:items-center  gap-2 ">
                    <Typography variant="h6">Start Date:</Typography>
                    <Typography>
                      {moment(scheduleSubscriptionDetails.planStartDate).format(
                        "MMMM D, YYYY"
                      )}
                    </Typography>
                  </div>
                </>
              </CardBody>
              <CardFooter>
                <Button color="red" onClick={handleCancelScheduledUpdate}>
                  Cancel Schedule Subscription
                </Button>
              </CardFooter>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageSubscriptionPage;
