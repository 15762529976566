import { Typography, Button } from "@material-tailwind/react";
import { Link, useRouteError } from "react-router-dom";
import { agentSessionLogout } from "../api/agents/agentsApi";
import { getItem } from "../Utils/localStorage";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useUserStore } from "../Context/store";
import { useSocket } from "../Context/SocketContext";

export default function ErrorPage() {
  const error = useRouteError();
  const { logout } = useUserStore();
  const { disconnect } = useSocket();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-300 p-6">
      <div className="bg-white shadow-lg rounded-2xl p-8 flex flex-col gap-4 text-center w-full max-w-md">
        <div className="flex justify-center mb-4">
          <ExclamationTriangleIcon className="text-red-500 w-12 h-12" />
        </div>
        <Typography variant="h4" color="red" className="font-bold">
          Oops! Something went wrong.
        </Typography>
        <p className="text-debatePalette-title text-lg">
          {error?.statusText ||
            error?.message ||
            "An unexpected error occurred."}
        </p>

        <div className="flex flex-col items-center gap-4">
          <Button
            className="w-full bg-primary text-white font-semibold py-2 rounded-lg hover:bg-opacity-90"
            onClick={() => {
              window.location.href = "/feed-public";
            }}
          >
            🏠 Go to Feed
          </Button>

          <Link to={"/"} className="w-full">
            <Button
              className="w-full bg-gray-700 text-white font-semibold py-2 rounded-lg hover:bg-gray-800"
              onClick={() => {
                const userId = getItem("user")?.userId;
                const session_id = getItem("user")?.sessionId;
                if (!userId || !session_id) {
                  localStorage.clear();
                  return;
                }
                agentSessionLogout({ user_id: userId, session_id });
                disconnect();
                logout();
              }}
            >
              🔐 Back to Login
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
