// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkArgumentAgent } from "../../api/agents/agentsApi";
import { useUserStore } from "../../Context/store";

export const useArgumentAgent = (motion, category,enabled) => {
  const { user: loggedInUser } = useUserStore();
  return useQuery({
    queryKey: ["argumenty-validate", motion, category],
    queryFn: async () => {
      return await checkArgumentAgent({
        motion,
        category,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
    },
    select: (data) => data.data,
    enabled: !!motion && !!category && !!loggedInUser && !!enabled,
  });
};
