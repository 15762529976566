import { Card } from "@material-tailwind/react";
import React from "react";
import { useFileViewer } from "../../../Context/FileViewerContext";
import { useWorkspaceStore } from "../../../Context/store";
import { extractTitle, getLocalTime } from "../../../Utils/Helper";
import ImageOrInitials from "../../Functionality/ImageOrInitials";

const CitationCard = ({ citation, type }) => {
  const { handleFile } = useFileViewer();
  const { selectedWorkspace } = useWorkspaceStore();
  
  const motionUploadFilePath = `${process.env.REACT_APP_MOTION_UPLOAD}/${selectedWorkspace?.workSpaceId}`;

  const createFileURL = (type, url) => {
    if (type === "motion") {
      return `${process.env.REACT_APP_AZURE_STORAGE_BASE_URL}/debatemodule/${motionUploadFilePath}/${url}`;
    }else{
      return url
    }
  };

  const formatCitation = (citation) => {
    let title = "";
    let description = "";

    if (citation.citationType === "url") {
      // Assuming title and description are separated by 'Title:' and 'Description:' in citationNote
      const noteParts = citation?.citationNote?.split("\n");
      title = noteParts[0]?.replace("Title: ", "")?.trim();
      description = noteParts[1]?.replace("Description: ", "")?.trim();
    } else {
      // Extract the file name from the URL as the title
      title = extractTitle(citation?.citationUrl);
      description = citation?.citationNote;
    }

    return { title, description };
  };

  const { title, description } = formatCitation({
    ...citation,
    citationType: citation?.citationFileType,
  });

  return (
    <Card className="flex p-2 flex-col md:flex-row  gap-2 items-start ">
      <ImageOrInitials
        imageSrc={citation?.createdByUserImage}
        initials={citation?.createdByUserName}
      />
      <div className="w-full flex flex-col gap-1">
        <div className="flex flex-col gap-2 w-full">
          <p className="truncate whitespace-pre-wrap text-sm">
            <span className="font-semibold ">Title:</span> {title}
          </p>
          <p className="truncate whitespace-pre-wrap text-sm">
            <span className="font-semibold">Description:</span> {description}
          </p>
        </div>
        <p
          className="text-sm text-wrap break-all text-debatePalette-link hover:underline cursor-pointer"
          onClick={(e) => {
            e.preventDefault(); // Prevent default download behavior
            if (citation?.citationFileType === "url") {
              window.open(createFileURL(type, citation?.citationUrl), "_blank");
              return;
            }
            handleFile({
              fileUrl: createFileURL(type, citation?.citationUrl),
              fileType: citation?.citationFileType,
              fileName: title,
              fileUploadedAt: getLocalTime(citation?.createdAt),
              createdBy: citation?.createdByUserName,
              creatorImage: citation?.createdByUserImage,
              channelName: selectedWorkspace?.workSpaceName,
            });
          }}
        >
          {title}
        </p>
        <span className="text-xs text-debatePalette-bodyText">
          {getLocalTime(citation?.createdAt)}
        </span>
      </div>
    </Card>
  );
};

export default CitationCard;
