import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetSubscribedPlan } from "../Web-Hooks/Subscription/use-subscription";
import { useUserStore } from "./store";

/**
 * @typedef {Object} Plan
 * @property {string} planName
 * @property {boolean} createWorkspace
 * @property {boolean} joinWorkspace
 * @property {boolean} createForum
 * @property {boolean} actionDebate
 * @property {boolean} createDebate
 * @property {boolean} suggestMotion
 * @property {boolean} findMotion
 * @property {boolean} aiFeature
 * @property {boolean} message
 * @property {boolean} libraryOption
 * @property {boolean} roleManagement
 * @property {boolean} createReference
 * @property {boolean} privateDebate
 * @property {number} maxCreateWorkspaceCount
 * @property {number} maxCreateForumCount
 * @property {number} maxCitationCount
 * @property {number} maxAddUserPerForum
 * @property {string} libraryStorage
 * @property {string} totalWorkspaceJoin
 * @property {string[]} storageOptions
 * @property {boolean} accessReference
 * @property {string} referenceStorage
 * @property {boolean} accessNews
 * @property {boolean} createGroupMessage
 * @property {boolean} accessAddCitation
 * @property {boolean} accessSaveDebate
 */

/**
 * @typedef {Object} SubscriptionPlan
 * @property {string} userId
 * @property {string} subscriptionId
 * @property {string} planId
 * @property {string} planName
 * @property {boolean} planPurchased
 * @property {string} planStartDate
 * @property {string} planEndDate
 * @property {string} planType
 * @property {string} currency
 * @property {string} createdAt
 * @property {string} isTrialPlanAvailable
 * @property {StripeDetails} stripeJson
 */

/**
 * @typedef {Object} StripeDetails
 * @property {string} id
 * @property {string} object
 * @property {boolean} planPurchased
 * @property {string} currency
 * @property {number} billing_cycle_anchor
 * @property {boolean} cancel_at_period_end
 * @property {string} collection_method
 * @property {number} created
 * @property {number} current_period_end
 * @property {number} current_period_start
 * @property {string} customer
 * @property {string} default_payment_method
 * @property {Object[]} items
 * @property {string} latest_invoice
 * @property {string} status
 * @property {Object} payment_settings
 */

/**
 * @typedef {Object} SubscriptionItem
 * @property {string} id
 * @property {string} object
 * @property {number} created
 * @property {PlanDetails} plan
 * @property {PriceDetails} price
 * @property {number} quantity
 * @property {string} subscription
 */

/**
 * @typedef {Object} PlanDetails
 * @property {string} id
 * @property {string} object
 * @property {boolean} active
 * @property {number} amount
 * @property {string} currency
 * @property {string} interval
 * @property {number} interval_count
 * @property {boolean} livemode
 * @property {string} product
 * @property {string} usage_type
 */

/**
 * @typedef {Object} PriceDetails
 * @property {string} id
 * @property {string} object
 * @property {boolean} active
 * @property {number} unit_amount
 * @property {string} currency
 * @property {boolean} livemode
 * @property {RecurringDetails} recurring
 */

/**
 * @typedef {Object} RecurringDetails
 * @property {string} interval
 * @property {number} interval_count
 * @property {string} usage_type
 */

/** @type {React.Context<{currentPlan: Plan,  updateFeature: Function, updatePlan: Function, subscribedPlan: SubscriptionPlan}>} */

const PlanContext = createContext({
  currentPlan: null,
  updateFeature: () => {},
  updatePlan: () => {},
  subscribedPlan: null,
});

export const defaultPlans = {
  Free: {
    planId: process.env.REACT_APP_FREE_PLAN_ID,
    planName: "Free",
    createWorkspace: false,
    joinWorkspace: true,
    totalWorkspaceJoin: 2,
    maxCreateWorkspaceCount: 0,
    createForum: false,
    maxCreateForumCount: 0,
    maxAddUserPerForum: 0,
    libraryOption: true,
    libraryStorage: "1GB",
    storageOptions: ["doc", "image", "pdf", "links"],
    createReference: false,
    accessReference: true,
    message: true,
    createGroupMessage: false,
    accessNews: false,
    accessAddCitation: false,
    accessSaveDebate: false,
    createDebate: false,
    suggestMotion: false,
    findMotion: true,
    aiFeature: false,
    roleManagement: false,
    privateDebate: false,
    maxCitationCount: 1,
    accessAdminPanel: false,
  },
  Team: {
    planId: process.env.REACT_APP_TEAM_PLAN_ID,
    planName: "Team",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 5,
    totalWorkspaceJoin: 5,
    createForum: true,
    maxCreateForumCount: 3,
    maxAddUserPerForum: 5,
    libraryOption: true,
    libraryStorage: "5GB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: false,
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: false,
    accessSaveDebate: false,
    createDebate: true,
    suggestMotion: false,
    findMotion: true,
    aiFeature: false,
    roleManagement: false,
    privateDebate: false,
    maxCitationCount: 2,
    accessAdminPanel: false,
  },
  Pro: {
    planId: process.env.REACT_APP_PRO_PLAN_ID,
    planName: "Pro",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 5,
    totalWorkspaceJoin: 5,
    createForum: true,
    maxCreateForumCount: 10,
    maxAddUserPerForum: 50,
    libraryOption: true,
    libraryStorage: "10GB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: false,
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: true,
    accessSaveDebate: true,
    createDebate: true,
    suggestMotion: true,
    findMotion: true,
    aiFeature: false,
    roleManagement: true,
    privateDebate: true,
    maxCitationCount: 5,
    accessAdminPanel: false,
  },
  Enterprise: {
    planId: process.env.REACT_APP_ENTERPRISE_PLAN_ID,
    planName: "Enterprise",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 15,
    totalWorkspaceJoin: 15,
    createForum: true,
    maxCreateForumCount: 20,
    maxAddUserPerForum: 200,
    libraryOption: true,
    libraryStorage: "1TB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: true,
    referenceStorage: "50GB",
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: true,
    accessSaveDebate: true,
    createDebate: true,
    suggestMotion: true,
    findMotion: true,
    aiFeature: false,
    roleManagement: true,
    privateDebate: true,
    maxCitationCount: 10,
    accessAdminPanel: true,
  },
  Sample: {
    planId: process.env.REACT_APP_SAMPLE_PLAN_ID,
    planName: "Sample",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 5,
    totalWorkspaceJoin: 5,
    createForum: true,
    maxCreateForumCount: 3,
    maxAddUserPerForum: 5,
    libraryOption: true,
    libraryStorage: "5GB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: false,
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: false,
    accessSaveDebate: false,
    createDebate: true,
    suggestMotion: false,
    findMotion: true,
    aiFeature: false,
    roleManagement: false,
    privateDebate: false,
    maxCitationCount: 2,
    accessAdminPanel: false,
  },
  AIWithPro: {
    planId: process.env.REACT_APP_AI_WITH_PRO_PLAN_ID,
    planName: "AI With Pro",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 5,
    totalWorkspaceJoin: 5,
    createForum: true,
    maxCreateForumCount: 10,
    maxAddUserPerForum: 50,
    libraryOption: true,
    libraryStorage: "10GB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: false,
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: true,
    accessSaveDebate: true,
    createDebate: true,
    suggestMotion: true,
    findMotion: true,
    aiFeature: true,
    roleManagement: true,
    privateDebate: true,
    maxCitationCount: 5,
    accessAdminPanel: false,
  },
  AIWithKing: {
    planId: process.env.REACT_APP_AI_WITH_KING_PLAN_ID,
    planName: "King's",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 100,
    totalWorkspaceJoin: 7,
    createForum: true,
    maxCreateForumCount: 10,
    maxAddUserPerForum: 1000000,
    libraryOption: true,
    libraryStorage: "1TB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: true,
    referenceStorage: "50GB",
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: true,
    accessSaveDebate: true,
    createDebate: true,
    suggestMotion: true,
    findMotion: true,
    aiFeature: true,
    roleManagement: true,
    privateDebate: true,
    maxCitationCount: 8,
    accessAdminPanel: false,
  },
  AIWithEnterprise: {
    planId: process.env.REACT_APP_AI_WITH_ENTERPRISE_PLAN_ID,
    planName: "AI With Enterprise",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 15,
    totalWorkspaceJoin: 15,
    createForum: true,
    maxCreateForumCount: 20,
    maxAddUserPerForum: 200,
    libraryOption: true,
    libraryStorage: "1TB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: true,
    referenceStorage: "50GB",
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: true,
    accessSaveDebate: true,
    createDebate: true,
    suggestMotion: true,
    findMotion: true,
    aiFeature: true,
    roleManagement: true,
    privateDebate: true,
    maxCitationCount: 10,
    accessAdminPanel: true,
  },
  TrialPeriod: {
    planId: process.env.REACT_APP_TRIAL_PERIOD_PLAN_ID,
    planName: "TrialPeriod",
    createWorkspace: true,
    joinWorkspace: true,
    maxCreateWorkspaceCount: 2,
    totalWorkspaceJoin: 2,
    createForum: true,
    maxCreateForumCount: 1,
    maxAddUserPerForum: 5,
    libraryOption: true,
    libraryStorage: "1GB",
    storageOptions: ["doc", "image", "pdf", "links", "video"],
    createReference: false,
    accessReference: true,
    message: true,
    createGroupMessage: true,
    accessNews: true,
    actionDebate: true,
    accessAddCitation: true,
    accessSaveDebate: true,
    createDebate: true,
    suggestMotion: true,
    findMotion: true,
    aiFeature: false,
    roleManagement: true,
    privateDebate: false,
    maxCitationCount: 2,
    accessAdminPanel: false,
  },
};

const getDefaultPlan = (planId) => {
  return Object.values(defaultPlans).find(
    (plan) => plan.planId === (planId || "9e402b39-8b33-4530-b152-1581976c738c")
  );
};

export const PlanProvider = ({ children }) => {
  const { user } = useUserStore();
  const [currentPlan, setCurrentPlan] = useState(getDefaultPlan(user?.planId));
  const { data: subscribedPlan } = useGetSubscribedPlan(user?.userId);

  const setPlanByApi = (data) => {
    const plan = getDefaultPlan(data?.planId);
    setCurrentPlan(plan);
  };

  const updateFeature = (feature, value) => {
    setCurrentPlan((prevPlan) => {
      if (!(feature in prevPlan)) {
        throw new Error(`Unknown feature: ${feature}`);
      }
      return {
        ...prevPlan,
        [feature]: value,
      };
    });
  };

  const updatePlan = (planId) => {
    const newPlan = getDefaultPlan(planId);
    setCurrentPlan(newPlan);
  };

  useEffect(() => {
    if (subscribedPlan) {
      setPlanByApi(subscribedPlan);
    }
  }, [subscribedPlan]);

  return (
    <PlanContext.Provider
      value={{
        currentPlan,
        setPlanByApi,
        updateFeature,
        updatePlan,
        subscribedPlan,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error("usePlan must be used within a PlanProvider");
  }
  return context;
};

// Example usage inside a component
// const { currentPlan, setPlanByApi, updateFeature, updatePlan } = usePlan();
