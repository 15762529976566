import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSocket } from "../../../Context/SocketContext";
import { useWorkspaceStore } from "../../../Context/store";
import { useSubworkspaceStore } from "../../../Context/store";
import MessageFileUpload from "../individual/MessageFileUpload";
import EmojiPicker from "emoji-picker-react"; // Import emoji picker
import { FaceSmileIcon } from "@heroicons/react/24/outline";

function GroupMessageInput({ sender, group, setGroupDetails }) {
  const textAreaRef = useRef(null);
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Toggle emoji picker
  const { sendGroupMessage, isGroupTyping, socket } = useSocket();
  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";
  const [disable, setDisable] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  const handleInput = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = "auto";
    textArea.style.height = `${textArea.scrollHeight}px`;
  };

  const handleSend = () => {
    isGroupTyping(group?.id, sender?.userId, false);

    if (!message?.trim()) return;
    const Message = {
      type: "text",
      content: {
        text: message,
      },
    };
    setMessage("");
    textAreaRef.current.style.height = "auto"; // Reset height
    textAreaRef.current.setAttribute("rows", 1); // Reset rows
    sendGroupMessage(
      group?.id,
      sender?.userId,
      Message,
      selectedWorkspace?.workSpaceId,
      isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
    );
  };

  const onEnterPress = (e) => {
    if (e.target.value === "") {
      isGroupTyping(group?.id, sender?.userId, false);
      return;
    }
    isGroupTyping(group?.id, sender?.userId, true);

    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      setShowEmojiPicker(false);
      handleSend();
    }
  };

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji); // Append emoji
    textAreaRef.current.focus();
    // setShowEmojiPicker(false); // Hide the emoji picker
  };

  useEffect(() => {
    if (group?.users?.length > 0) {
      if (!group?.users?.includes(sender?.userId)) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [group, sender]);

  useEffect(() => {
    if (!socket) return;
    const handleInputDisability = (data) => {
      const { groupId, content } = data?.messages[0];

      if (groupId !== group?.id) return;
      if (
        content?.type === "user_removed" &&
        sender?.userId === content?.content?.userId
      ) {
        setGroupDetails((prev) => ({
          ...prev,
          users: prev?.users?.filter((item) => item !== sender?.userId),
        }));
      } else if (
        content?.type === "user_added" &&
        sender?.userId === content?.content?.userId
      ) {
        setGroupDetails((prev) => ({
          ...prev,
          users: [...prev?.users, sender?.userId],
        }));
      }
    };

    socket.on("gettingNewGroupMessageChat", handleInputDisability);
    return () => {
      socket.off("gettingNewGroupMessageChat", handleInputDisability);
    };
  }, [socket, setDisable, setGroupDetails, sender, group]);

  return (
    <Fragment>
      {disable ? (
        <div className="flex items-end bg-gray-100 justify-center text-center font-bold rounded-lg px-4 py-2 shadow-md mx-auto">
          You have been removed from this group. You can no longer send messages
          or participate in the group.
        </div>
      ) : (
        <div className="flex items-end bg-gray-100 border-secondary border rounded-lg px-4 py-2 shadow-md mx-auto relative">
          {/* Emoji Picker Toggle Button */}

          <MessageFileUpload sender={sender} receiver={group} type="group" />

          <Button
            variant="text"
            className="text-gray-500 p-1 text-xl mr-3 focus:outline-none"
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          >
            <FaceSmileIcon className="size-6 text-secondary" />
          </Button>

          {/* Emoji Picker */}
          {showEmojiPicker && (
            <div className="absolute bottom-12 left-0 z-10">
              <EmojiPicker
                onEmojiClick={(emojiData) => handleEmojiClick(emojiData)}
              />
            </div>
          )}
          {/* Expanding Textarea */}
          <textarea
            rows={1}
            resize="false"
            placeholder="Your Message"
            onInput={handleInput}
            onChange={handleChange}
            ref={textAreaRef}
            className="flex-1 !border-0 min-h-full focus:border-transparent resize-y border-none outline-none bg-transparent text-base max-h-36 overflow-y-auto py-1"
            style={{ height: "auto" }}
            id="message-input"
            value={message}
            onKeyDown={onEnterPress}
          />

          {/* Send Button */}
          <Button
            variant="text"
            onClick={() => {
              setShowEmojiPicker(false);
              handleSend();
            }}
            className="text-gray-500 p-1 text-xl ml-3 focus:outline-none"
            disabled={!message}
            id="message-send"
          >
            <PaperAirplaneIcon className="size-6 text-secondary" />
          </Button>
        </div>
      )}
    </Fragment>
  );
}

export default GroupMessageInput;
