import Swal from "sweetalert2";
import Logo from "../Assets/Svg/AILogo.svg";
import LogoType from "../Assets/Svg/Logotype.svg";
import { secondaryColor } from "../Utils/Constant";

export const handleUpgradePlan = ({title, message}, cf) => {
  return Swal.fire({
    imageUrl: LogoType,
    imageWidth: 250,
    imageHeight: 150,
    imageAlt: "Custom image",
    width: 700,
    color: secondaryColor,
    customClass: {
      title: "p-0 !my-2",
      image: "p-0 m-0 w-full mx-auto",
      popup: "flex flex-col items-center gap-3 ",
      htmlContainer: " !py-0 !px-16 !my-2",
      actions:"m-0 my-2",
      container:"!z-[99999]"
      
    },
    titleText: title,
    text: message,
    confirmButtonText: "Upgrade",
    showCancelButton: true,
    confirmButtonAriaLabel: "Upgrade",
    confirmButtonColor: secondaryColor,
    cancelButtonAriaLabel: "cancel",
    cancelButtonText: "Cancel",
    stopKeydownPropagation: true,
    showClass: {
      popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
    },
    hideClass: {
      popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
    },
  }).then((result) => {
    if (result.isConfirmed) {
      cf();
    }
  });
};

export const handleAIUpgradePlan = ({title, message}, cf) => {
  return Swal.fire({
    imageUrl: Logo,
    imageWidth: 150,
    imageHeight: 100,
    imageAlt: "Custom image",
    width: 700,
    color: secondaryColor,
    customClass: {
      title: "text-[#7647FC]",
      image: "p-0 m-0 w-full mx-auto",
      popup: "flex flex-col items-center gap-3 ",
      htmlContainer: " !py-0 !px-16 !my-2",
      actions:"m-0 my-2",
    },
    //   titleText: "Unlock Smart Insights!",
    titleText: title,
    text: message,
    confirmButtonText: "Upgrade",
    showCancelButton: true,
    confirmButtonAriaLabel: "Upgrade",
    confirmButtonColor: "#7647FC",
    cancelButtonAriaLabel: "cancel",
    cancelButtonText: "Cancel",
    showClass: {
      popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
    },
    hideClass: {
      popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
    },
  }).then((result) => {
    if (result.isConfirmed) {
      cf();
    }
  });
};
