import { ClockIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { usePlan } from "../../Context/PlanContext";
import { primaryColor } from "../../Utils/Constant";

const ALERT_THRESHOLDS = [7, 3, 1, 0];
const ALERT_MESSAGES = {
  7: "in 7 days",
  3: "in 3 days",
  1: "tomorrow",
  0: "today",
};

const TrialPeriodPlanTimer = () => {
  const { subscribedPlan } = usePlan();
  const navigate = useNavigate();

  // Calculate the total number of trial days (e.g. 14 days)
  const totalTrialDays = useMemo(() => {
    return moment(subscribedPlan?.planEndDate).diff(
      moment(subscribedPlan?.planStartDate),
      "days"
    );
  }, [subscribedPlan?.planStartDate, subscribedPlan?.planEndDate]);

  // Store the raw remaining days. (This might be 0 on the last day.)
  const rawRemainingDays = moment(subscribedPlan?.planEndDate).diff(
    moment(),
    "days"
  );

  const isAlertDay = ALERT_THRESHOLDS.includes(rawRemainingDays);

  // For display purposes, always show at least "1" day left if trial is active.
  const displayDays = rawRemainingDays < 1 ? 1 : rawRemainingDays;

  // When the raw remaining days hit an alert threshold, show a SweetAlert.
  useEffect(() => {
    if (
      isAlertDay &&
      subscribedPlan?.planType === "trial" &&
      subscribedPlan?.stripeJson?.cancel_at_period_end !== true &&
      subscribedPlan?.stripeJson?.status !== "cancelled"
    ) {
      const alertKey = `trialAlert${rawRemainingDays}Shown`;
      const lastShown = localStorage.getItem(alertKey);
      const today = new Date().toDateString();

      // Only show the alert once per threshold per day.
      if (!lastShown || new Date(lastShown).toDateString() !== today) {
        Swal.fire({
          title: "Your Free Trial is About to Expire!",
          text: `Your ${totalTrialDays}-day free trial of ${
            subscribedPlan?.planName
          } will end ${
            ALERT_MESSAGES[rawRemainingDays]
              ? ALERT_MESSAGES[rawRemainingDays]
              : `${rawRemainingDays} days`
          }. If you'd like to continue enjoying our services, we will proceed with charging your selected payment method.`,
          icon: "warning",
          confirmButtonText: "Continue",
          confirmButtonColor: primaryColor,
          showDenyButton: true,
          denyButtonText: "Cancel Subscription",
          denyButtonColor: "rgba(234, 67, 53, 1)",
        }).then((result) => {
          if (result.isDenied) {
            navigate("/upgrade-plan?plan=base-plan");
          }
          localStorage.setItem(alertKey, new Date().toISOString());
        });
      }
    }
  }, [
    rawRemainingDays,
    isAlertDay,
    totalTrialDays,
    subscribedPlan?.stripeJson?.status,
    subscribedPlan?.stripeJson?.cancel_at_period_end,
    subscribedPlan?.planName,
    subscribedPlan?.planType,
    navigate,
  ]);

  // Only render if there is a valid trial plan.
  if (
    !subscribedPlan ||
    subscribedPlan?.planType !== "trial" ||
    !subscribedPlan?.planStartDate ||
    !subscribedPlan?.planEndDate
  ) {
    return null;
  }

  return (
    <div className="flex items-center px-3 gap-1">
      <ClockIcon className="h-5 w-5 text-debatePalette-timer" strokeWidth={2} />
      <Typography className="font-bold text-base text-debatePalette-timer">
        {displayDays} day{displayDays > 1 ? "s" : ""} left (Trial Period)
      </Typography>
    </div>
  );
};

export default TrialPeriodPlanTimer;
