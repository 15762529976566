import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ReactComponent as Google } from "../../Assets/Svg/Google.svg";
import ConatctList from "../../Components/Functionality/ConatctList";
import ActionButton from "../../Components/UI/Buttons/ActionButton";
import useDebounce from "../../Services/useDebounce";
import { usePlan } from "../../Context/PlanContext";
import { auth, provider } from "../../Services/Google-Signin/config";
import { GoogleAuthProvider, reauthenticateWithPopup } from "firebase/auth";

const GoogleContacts = ({ selectedMembers, setSelectedMembers }) => {
  const [contacts, setContacts] = useState([]);
  const [displayContacts, setDisplayContacts] = useState([]);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const deferredSearch = useDebounce(searchValue, 300);
  const { currentPlan } = usePlan();

  useEffect(() => {
    if (deferredSearch) {
      const filteredList = contacts.filter((item) =>
        item.label.toLowerCase().includes(deferredSearch.toLowerCase())
      );
      setDisplayContacts(filteredList);
    } else {
      setDisplayContacts(contacts);
    }
  }, [deferredSearch, contacts]);

  const hasContactsScope = async (token) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`
      );
      if (!response.ok) {
        return false;
      }
      const tokenInfo = await response.json();
      // tokenInfo.scope is a space-separated list of scopes
      return tokenInfo.scope?.includes(
        "https://www.googleapis.com/auth/contacts.other.readonly"
      );
    } catch (error) {
      toast.error("Error checking token scopes:", error);
      return false;
    }
  };

  const reauthenticateForContacts = async () => {
    try {
      const result = await reauthenticateWithPopup(auth.currentUser, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const newToken = credential.accessToken;
      // Optionally, update your stored token so future requests have the new scope.
      localStorage.setItem("accessToken", newToken);
      return newToken;
    } catch (error) {
      throw error;
    }
  };

  const requestContactAuthorization = async () => {
    try {
      const token = localStorage?.getItem("accessToken");

      const hasAccess = await hasContactsScope(token);

      if (!hasAccess) {
        // Prompt for additional permission
        const newToken = await reauthenticateForContacts();
        // Retry fetching contacts with the new token
        return toast.promise(fetchContacts(newToken), {
          loading: "Fetching contacts...",
          success: (data) =>
            `${
              data?.otherContacts ? "Contacts fetched" : "No contacts found"
            } `,
          error: <b>Could not fetch contacts.</b>,
        });
      }

      if (token) {
        toast.promise(fetchContacts(token), {
          loading: "Fetching contacts...",
          success: (data) =>
            `${
              data?.otherContacts ? "Contacts fetched" : "No contacts found"
            } `,
          error: <b>Could not fetch contacts.</b>,
        });
      }
    } catch (error) {
      console.error("Error requesting contact authorization:", error);
    }
  };

  const fetchContacts = async (token) => {
    try {
      const response = await fetch(
        "https://people.googleapis.com/v1/otherContacts?readMask=names,emailAddresses",
        {
          headers: {
            "GData-Version": "3.0",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch contacts");
      }

      const contactsData = await response.json();
      if (contactsData?.otherContacts) {
        const data = contactsData.otherContacts;
        const updatedData = data.map((contact) => ({
          label: contact.emailAddresses[0].value,
          value: contact.emailAddresses[0].value,
        }));
        const filteredContacts = updatedData.filter(
          (contact) => !selectedMembers.some((m) => m.value === contact.value)
        );
        setContacts(filteredContacts);
        setDisplayContacts(filteredContacts);
        setOpenMemberSelect(true);
        setSearchValue("");
        return contactsData;
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  return (
    <Fragment>
      <Dialog open={openMemberSelect} handler={setOpenMemberSelect}>
        <DialogHeader>Google contacts</DialogHeader>
        <DialogBody>
          <ConatctList
            contacts={contacts}
            displayContacts={displayContacts}
            setOpenMemberSelect={setOpenMemberSelect}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            selectedContactsFromProp={selectedMembers}
            setSelectedContactsFromProp={setSelectedMembers}
            maxLimit={currentPlan?.maxAddUserPerForum}
          />
        </DialogBody>
      </Dialog>
      <ActionButton
        title={"Add-from-Google-Contact"}
        onClick={requestContactAuthorization}
        SVG={Google}
        count={""}
      />
    </Fragment>
  );
};

export default GoogleContacts;
