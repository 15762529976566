import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  attachPaymentMethod,
  cancelSchedule,
  cancelSubscription,
  createCustomer,
  createPaymentIntent,
  createSetupIntent,
  createSubscription,
  detachPaymentMethod,
  getCustomerDetails,
  getPaymentMethods,
  getProductDetails,
  getSubscriptionPlans,
  getUpcomingInvoice,
  getUserSubscribedPlan,
  setDefaultPaymentMethod,
} from "../../api/subscription/subscription";

export const useGetSubscriptionPlans = (userId) => {
  return useQuery({
    queryKey: ["subscription", "list", userId],
    queryFn: async () => {
      return await getSubscriptionPlans(userId);
    },
    select: (data) => {
      return data.data.data;
    },
    enabled: !!userId,
  });
};
export const useGetSubscribedPlan = (userId) => {
  return useQuery({
    queryKey: ["subscription", userId],
    queryFn: async () => {
      return await getUserSubscribedPlan(userId);
    },
    select: (data) => data.data.data,
    enabled: !!userId,
  });
};
export const useGetProductDetails = (stripeProductId, planType) => {
  return useQuery({
    queryKey: ["product", stripeProductId, planType],
    queryFn: async () => {
      return await getProductDetails(stripeProductId, planType);
    },
    select: (data) => data.data,
    enabled: !!stripeProductId && !!planType,
  });
};
export const useGetPaymentMethods = (customerId) => {
  return useQuery({
    queryKey: ["payment-methods", customerId],
    queryFn: async () => {
      return await getPaymentMethods(customerId);
    },
    select: (data) => data?.data?.data,
    enabled: !!customerId,
  });
};
export const useGetCustomerDetails = (customerId) => {
  return useQuery({
    queryKey: ["customer-details", customerId],
    queryFn: async () => {
      return await getCustomerDetails(customerId);
    },
    select: (data) => data?.data?.data,
    enabled: !!customerId,
  });
};
export const useGetUpcomingInvoiceDetails = (
  customerId,
  newPriceId,
  subscriptionItemId,
  subscriptionId
) => {
  return useQuery({
    queryKey: [
      "upcoming-invoice",
      customerId,
      newPriceId,
      subscriptionItemId,
      subscriptionId,
    ],
    queryFn: async () => {
      return await getUpcomingInvoice({
        customerId,
        newPriceId,
        subscriptionItemId,
        subscriptionId,
      });
    },
    select: (data) => data.data,
    enabled:
      !!customerId && !!newPriceId && !!subscriptionItemId && !!subscriptionId,
  });
};

export const useCreateCustomer = () => {
  return useMutation({
    mutationFn: async (user) => {
      return await createCustomer(user);
    },
    onSuccess: async (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useCreatePaymentIntent = () => {
  return useMutation({
    mutationFn: async (user) => {
      return await createPaymentIntent(user);
    },
    onSuccess: async (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useCreateSubscription = () => {
  return useMutation({
    mutationFn: async (user) => {
      return await createSubscription(user);
    },
    onSuccess: async (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useCreateSetupIntent = () => {
  return useMutation({
    mutationFn: async (user) => {
      return await createSetupIntent(user);
    },
    onSuccess: async (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useSetDefaultPaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      return await setDefaultPaymentMethod(user);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: ["subscription"],
      })
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useAttachPaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      return await attachPaymentMethod(user);
    },
    onSuccess: async (data) => {
      await Promise.all([
        queryClient?.invalidateQueries({
          queryKey: ["payment-methods"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["subscription"],
        }),
      ]);
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useDetachPaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      return await detachPaymentMethod(user);
    },
    onSuccess: async (data) => {
      queryClient?.invalidateQueries({
        queryKey: ["payment-methods"],
      })
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      return await cancelSubscription({ subscriptionId: id });
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: ["subscription"],
      })
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useCancelSchedule = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      return await cancelSchedule({ scheduleId: id });
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: ["subscription"],
      })
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
