import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { primaryColor } from "./Utils/Constant";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

root.render(
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId="444992413005-1663foq30sdeu33jki0fshk8q8d0f1kj.apps.googleusercontent.com">
          <App />
          <Toaster
          containerClassName="!z-10000000"
            position="top-right"
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                borderColor: primaryColor,
                color: "secondary",
              },

              // Default options for specific types
              success: {
                duration: 3000,
                style: {
                  // background: 'green',
                }
              },
              
              error: {
                duration: 3000,
                style: {
                  // background: 'red',
                }
              },
            }}
          />
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
);

