import React, { useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import ActiveCard from "../../Components/UI/Cards/ActiveCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { useUserStore } from "../../Context/store";
import { useSubworkspaceStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import useDebounce from "../../Services/useDebounce";
import { useGetAllFilterCategoriesCounts } from "../../Web-Hooks/Categories/use-categories";
import { useGetPendingDebates } from "../../Web-Hooks/Profile/use-profile";
import SearchFilter from "./SearchFilter";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";

const PendingList = ({ selected }) => {
  const { subWorkspaceList } = useSubworkspaceStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const { user } = useUserStore();
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const [filter, setFilters] = useState(null);

  const subId = filter
    ? filter?.forum?.label !== "All forum"
      ? filter?.forum?.value
      : ""
    : "";
  const workId = filter
    ? filter?.forum?.label === "All forum"
      ? filter?.forum?.value
      : selectedWorkspace?.workSpaceId
    : selectedWorkspace?.workSpaceId;

  // const categoryWorkId = filter
  //   ? filter?.forum?.label === "All forum"
  //     ? filter?.forum?.value
  //     : selectedWorkspace?.workSpaceId
  //   : selectedWorkspace?.workSpaceId;

  const {
    data,
    isLoading: isDebateListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPendingDebates(
    user?.userId,
    subId,
    workId,
    filter?.category?.value || "",
    debouncedSearchTerm
  );

  const APIPendingData = data?.pages?.flatMap((page) => page?.pendingDebateList);

  const { data: categoryCounts } = useGetAllFilterCategoriesCounts(
    user?.userId,
    workId,
    subId,
    "pending"
  );

  const forumOptions =
    subWorkspaceList?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
      };
    }) || [];

  const categories =
    categoryCounts?.map((category) => {
      return {
        label: category?.categoryName,
        value: category?.categoryName,
        count: category?.debateCount,
        type: category?.categoryName,
      };
    }) || [];

  const categoryOptions = [
    {
      label: "Most Recent",
      value: "",
    },
    {
      label: "Category",
      options: categories,
    },
  ];

  return (
    <div className="h-full flex flex-col gap-5">
      <div className="h-[10%] flex gap-4 justify-between w-full">
        <SearchFilter
          showFilters={["search", "forum", "category"]}
          search={search}
          setSearch={setSearch}
          forumOptions={[
            ...forumOptions,
            {
              label: "All forum",
              value: selectedWorkspace?.workSpaceId,
            },
          ]}
          categoryOptions={categoryOptions}
          setFilters={setFilters}
          filters={
            filter || {
              forum: {
                label: "All forum",
                value: selectedWorkspace?.workSpaceId,
              },
              category: {
                label: "Most Recent",
                value: "",
              },
            }
          }
        />{" "}
        {/* <SortByFilter Options={Options} /> */}
      </div>
      <div className="flex flex-col gap-2 h-[90%] overflow-y-auto">
        {isDebateListLoading && RenderLoaderSkeleton(4, "motion")}
        {APIPendingData?.length === 0 && !isDebateListLoading && (
          <NoData
            message="No Data Found"
            isNoData={true}
            size={"w-full h-[80%]"}
          />
        )}
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isDebateListLoading}
        >
          {APIPendingData?.map((data) => (
            <ActiveCard
              key={data?.debateId}
              post={data}
              isPending={selected === "pending"}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default PendingList;
