import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AddNewUsersToSubWorkSpace,
  AddNewUserToSubWorkSpace,
  AddNewUserToSubWorkSpaceByEmails,
  addSubWorkspace,
  deleteUserFromSubWorkspace,
  EditUserToSubWorkSpace,
  getSubWorkspace,
  getSubWorkspaceByWorkId,
  getUsersBySubworkspaceID,
  updateSubWorkspace,
} from "../../api/subworkspace/subworkspace";
import { useUserStore } from "../../Context/store";

// Hook to get all workspaces
export const useSubWorkspaces = () => {
  return useQuery("sub-workspaces", getSubWorkspace);
};

// Hook to get workspaces by user ID

// export const useSubWorkspacesByUserId = (userId) => useQuery({
//   queryKey: ["sub-workspaces"],
//   queryFn: () => getWorkspaceByUserId(userId),
//   enabled: !!userId,
// });

export const useSubWorkspacesByWorkspaceId = (workspaceId,userId) => {
  return useQuery({
    queryKey: ["sub-workspaces",workspaceId,userId],
    queryFn: async () => {
      const res = await getSubWorkspaceByWorkId(workspaceId,userId);
      return res;
    },
    enabled: !!workspaceId && !!userId,
    select: (data) => {
      return data.listOfSubworkspaces;
    },
  });
};
export const useAddSubWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (subworkspace) => {
      return await addSubWorkspace(subworkspace);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sub-workspaces"] });
    },
  });
};

export const useUpdateSubWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (subworkspace) => {
      return await updateSubWorkspace(subworkspace);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["sub-workspaces"] });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useGetSubWorkspaceUserBySubId = (subId, search = "") => {
  const { user: loggedInUser } = useUserStore();

  return useQuery({
    queryKey: ["sub-workspace-users", subId, loggedInUser?.userId, search],
    queryFn: async () => await getUsersBySubworkspaceID(subId,loggedInUser?.userId, search),
    enabled: (!!subId && !!loggedInUser?.userId) || !!search,
    select: (data) => {
      const users = data.listSubWorkSpaceUsers.filter(
        (user) => user?.userId !== loggedInUser.userId
      );
      return users;
    },
  });
};

export const useAddUsersToSubWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (User) => {
      return await AddNewUsersToSubWorkSpace(User);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["sub-workspace-users"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useAddSingleUserToSubWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({

    mutationFn: async (User) => {
      return await AddNewUserToSubWorkSpace(User);
    },
    onSuccess: () => Promise.all([
      queryClient.invalidateQueries({
       queryKey: ["sub-workspace-users"],
     }),
      queryClient.invalidateQueries({
       queryKey: ["workspace-users"],
     }),
     queryClient.invalidateQueries({
      queryKey: ["roleList"],
    })
   ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useEditUserToSubWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (User) => {
      return await EditUserToSubWorkSpace(User);
    },
    onSuccess: () => Promise.all([
      queryClient.invalidateQueries({
       queryKey: ["sub-workspace-users"],
     }),
      queryClient.invalidateQueries({
       queryKey: ["workspace-users"],
     }),
     queryClient.invalidateQueries({
       queryKey: ["roleList"],
     })
   ]),
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useAddUserToSubWorkspaceByEmails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (User) => {
      return await AddNewUserToSubWorkSpaceByEmails(User);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["sub-workspace-users"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useDeleteUserFromSubWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (deleteUser) => {
      return await deleteUserFromSubWorkspace(deleteUser);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["sub-workspaces"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["sub-workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["workspace-users"],
        }),
      ])
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
