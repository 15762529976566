// useArgumentAgent.js
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  debateSummary,
  suggestCategoryAgent,
  topicSuggestionAI,
} from "../../api/agents/agentsApi";
import { useUserStore } from "../../Context/store";

export const useTopicSuggestionAI = () => {
  const { user: loggedInUser } = useUserStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (topic) => {
      return await topicSuggestionAI({
        debate_topic: topic,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["debate-topic-suggestion"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useCategorySuggestionAI = (debate_topic) => {
  const { user: loggedInUser } = useUserStore();

  return useQuery({
    queryKey: ["category-suggestionss", debate_topic],
    queryFn: async () => {
      return await suggestCategoryAgent({
        debate_topic: debate_topic,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
    },
    select: (data) => data?.data,
    enabled: !!debate_topic && !!loggedInUser,
  });
};
export const useDebateSummaryAI = (debateId) => {
  const { user: loggedInUser } = useUserStore();

  return useQuery({
    queryKey: ["debate-summary", debateId],
    queryFn: async () => {
      return await debateSummary({
        debate_id: debateId,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
    },
    select: (data) => data?.data["Debate Summary"],
    enabled: !!debateId && !!loggedInUser,
  });
};
