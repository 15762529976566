// CreateDebateContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getItem } from "../Utils/localStorage";
import {
  useAddCitation,
  useAddDebateToSubWorkspace,
  useRemoveCitation,
  useUpdateRedebateCount,
} from "../Web-Hooks/Debate/use-debate";
import { useUserStore } from "./store";
import { useSubworkspaceStore } from "./store";
import {
  useDeleteBlob,
  useUploadCitationContentExecutor,
} from "../Web-Hooks/LibraryAPI/use-citation";

const CreateDebateContext = createContext({
  debateRule: {
    Rounds: "1",
    TimeFrame: "1 day",
    MaxVotesToWin: "25",
  },
  citation: [],
  setCitation: () => {},
  setDebateRule: () => {},
  debateInfo: {
    MotionOrClaim: "",
    CreatorOpeningArgument: "",
    DebateImage: null,
    Fields: [],
    Type: "blitz",
    IsOtheruserAllow: false,
    SideForDebate: null,
    Round: 1,
    IsPublishWithoutOpponent: false,
    IsDebateSemiPublic: false,
    IsDebatePublic: true,
    IsDebatePrivate: false,
    NominateUserId: null,
    InvitedUserId: null,
    FileSize: 0,
    CustomRounds: null,
    CustomTimeFrame: null,
    CustomeMaxVotesToWin: null,
    ScalerPoint: 0,
  },
  handleStateUpdate: () => {},
  handleNext: () => {},
  handleBack: () => {},
  viewers: [],
  setViewers: () => {},
  removeAndDeleteUnwantedCitation: () => {},
  isAddDebatePending: false,
  isAddCitationPending: false,
  isUpdateRedebateCountPending: false,
  handleDebateAndCitationAPI: () => {},
});

export const useCreateDebateContext = () => {
  return useContext(CreateDebateContext);
};

export const CreateDebateProvider = ({ children }) => {
  const [citation, setCitation] = useState([]);
  const [viewers, setViewers] = useState([]);
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { mutateAsync: deleteBlob } = useDeleteBlob();
  const { user } = useUserStore();
  const [query] = useSearchParams();
  const from = query?.get("from");
  const debateId = query?.get("debateId");
  const { mutateAsync: removeCitation } = useRemoveCitation();
  const { mutateAsync: addCitation, isPending: isAddCitationPending } =
    useAddCitation();
  const { mutateAsync: addDebate, isPending: isAddDebatePending } =
    useAddDebateToSubWorkspace();
  const { mutateAsync: uploadCitationOnExecutor } =
    useUploadCitationContentExecutor();
  const {
    mutateAsync: updateRedebateCount,
    isPending: isUpdateRedebateCountPending,
  } = useUpdateRedebateCount();
  const [debateInfo, setDebateInfo] = useState({
    MotionOrClaim: "",
    CreatorOpeningArgument: "",
    DebateImage: null,
    Fields: [],
    Type: "blitz",
    IsOtheruserAllow: false,
    SideForDebate: null,
    IsPublishWithoutOpponent: false,
    IsDebateSemiPublic: false,
    IsDebatePublic: true,
    IsDebatePrivate: false,
    NominateUserId: null,
    InvitedUserId: null,
    FileSize: 0,
    CustomRounds: null,
    CustomTimeFrame: null,
    CustomeMaxVotesToWin: null,
    ScalerPoint: 0,
  });
  const navigate = useNavigate();

  const handleStateUpdate = (updatedState) => {
    setDebateInfo(updatedState);
    localStorage.setItem("Debate", JSON.stringify(updatedState));
  };

  // Handle Add Debate and Citation
  const handleDebateAndCitationAPI = async (
    formData,
    draft,
    storeId,
    handleSubmit
  ) => {
    const AllCitation = getItem("AllCitation");
    if (AllCitation?.length) {
      const citationsToRemove = AllCitation?.filter(
        (cite) =>
          cite?.citationSide?.toLowerCase() !==
          debateInfo?.SideForDebate?.toLowerCase()
      );
      removeAndDeleteUnwantedCitation(citationsToRemove, user);
    }

    try {
      const data = await addDebate(formData);
      if (data?.debateId) {
        if (from === "Redebate" && !draft) {
          await updateRedebateCount({
            DebateId: debateId,
            DebateCreatorUserId: user?.userId,
            RedebateCreatorUserId: user?.userId,
          });
        }
      }
      if (data && citation?.length > 0) {
        const filterCitation = citation.filter(
          (item) => !item.isCitationFromDatabase
        );
        // console.log(citation,"citation")
        const customizedCitations = filterCitation.map((item) => {
          if (item.citationType !== "url") {
            return {
              ...item,
              citationUrl: item?.citationUrl?.split("/")?.pop(),
            };
          }
          return item;
        });
        await addCitation({
          ArgumentId: data?.argumentId ? data?.argumentId : null,
          debateId: data?.debateId,
          userId: user?.userId,
          workSpaceId: selectedSubWorkspace?.workSpaceId,
          subWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
          bySide: debateInfo?.SideForDebate,
          round: debateInfo?.Type === "eonian" ? 0 : 1,
          citations: customizedCitations,
        });
        let modifyCitation = citation.map((item) => ({
          url: item.citationUrl,
          type: item.citationType  
        }));
         uploadCitationOnExecutor({
          citation_url: modifyCitation,
          debate_id: data?.debateId,
        });
      }

      if (draft) {
        navigate(`/createDebate?from=draft&debateId=${data?.debateId}`, {
          replace: true,
        });
        toast.success("Draft saved");
        return;
      }
      navigate(
        debateInfo?.IsDebatePublic
          ? "/feed-public"
          : debateInfo?.IsDebateSemiPublic
          ? "/feed"
          : "/private"
      );
      handleSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  // to remove and delete unwanted citation from database
  const removeAndDeleteUnwantedCitation = (allCitations, user) => {
    if (allCitations?.length) {
      // Remove each citation from the database if it’s saved
      allCitations.forEach((citationToRemove) => {
        const citationData = {
          CitationId: citationToRemove.citationId,
          UserId: user?.userId,
        };

        if (citationToRemove?.citationType !== "url") {
          const Blob = { fileUrl: citationToRemove?.citationUrl };
          deleteBlob(Blob);
        }

        // Check for valid CitationId and UserId before removing
        if (
          !Object.values(citationData).includes(null) &&
          !Object.values(citationData).includes(undefined) &&
          !Object.values(citationData).includes("")
        ) {
          removeCitation(citationData);
        }
      });
    }
  };

  useEffect(() => {
    const updateStateOnRefresh = () => {
      const Debate = JSON.parse(localStorage.getItem("Debate")) || debateInfo;
      handleStateUpdate(Debate);
      const Citation = JSON.parse(localStorage.getItem("Citation")) || citation;
      setCitation(Citation);
      const Viewers = JSON.parse(localStorage.getItem("Viewers")) || viewers;
      setViewers(Viewers);
    };
    return updateStateOnRefresh(); // eslint-disable-next-line
  }, []);

  const handleNext = (page) => {
    navigate(`/createDebate/${page}?${query.toString()}`);
  };
  const handleBack = (page) => {
    navigate(`/createDebate/${page}?${query.toString()}`);
  };

  return (
    <CreateDebateContext.Provider
      value={{
        debateInfo,
        handleStateUpdate,
        handleNext,
        handleBack,
        setCitation,
        viewers,
        setViewers,
        citation,
        removeAndDeleteUnwantedCitation,
        handleDebateAndCitationAPI,
        isAddCitationPending,
        isAddDebatePending,
        isUpdateRedebateCountPending,
      }}
    >
      {children}
    </CreateDebateContext.Provider>
  );
};
