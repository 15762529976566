import {
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect } from "react";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";
import "animate.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { ThreeDots } from "react-loader-spinner";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { AIIcon } from "../../Utils/Helper";
import {
  useGetSessionHistory,
  usePostQuestion,
} from "../../Web-Hooks/Chatbot/Chatbot";
import Viewer from "react-viewer";
import { useSubworkspaceStore, useUserStore, useWorkspaceStore } from "../../Context/store";
import { Card, Typography } from "@material-tailwind/react";
import { primaryColor } from "../../Utils/Constant";

function BotMessage({ answer, setImagePreviewOpen, setImageLink }) {
  const isImageUrl = (url) => {
    // Regular expression to check for common image file extensions
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    return imageExtensions.test(url);
  };
  const handleLinkClick = (url) => {
    // console.log("Link clicked:", url);
    let isImage=isImageUrl(url)
    if (isImage) {
      // If it's an image URL, open the image preview
      setImagePreviewOpen(true);
      setImageLink(url);
    } else if(!isImage){ 
      // If it's not an image, open the link in a new tab
      window.open(url, "_blank");
    
  };
};

  // Function to handle link click by adding event listeners after HTML is rendered
  const addLinkEventListeners = () => {
    const links = document.querySelectorAll(".bot-message a");
    links.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault(); // Prevent default link behavior
        handleLinkClick(link.href); // Log the URL
      });
    });
  };

  // Adding event listeners after render
  useEffect(() => {
    addLinkEventListeners();
     // eslint-disable-next-line
  }, [answer]); // Trigger when answer is updated

  return (
    <div
      className="bot-message"
      dangerouslySetInnerHTML={{
        __html: answer
          ?.replace(/\n/g, "<br />")
          ?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          ?.replace(/\[(.*?)\]\((.*?)\)/g, (match, text, url) => {
            return `<a href='${url}' style='color: #4f84e3; word-wrap: break-word; max-width: 100%;'>${text}</a>`;
          })
          ?.replace(
            /\[(.*?)\]\((.*?)\)/g,
            "<a href='$2' className='underline bg-debatePalette-link' target='_blank'>$1</a>"
          )
          ?.replace(/\*(.*?)\*/g, "<em>$1</em>"),
      }}
    />
  );
}



export default function ChatBot() {
  const [open, setOpen] = useState(false); // Track if the chatbot is open
  const [messages, setMessages] = useState([]); // Initialize messages as empty array
  const [input, setInput] = useState(""); // Text input for user
  const messageEndRef = useRef(null); // Reference to scroll to the latest message
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { user: loggedInUser } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const [imageLink, setImageLink] = useState("");
  const { data: profileDetail } = useGetUserById(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );
  // const { user: loggedInUser } = useUserStore();
  // const { profileDetail } = useUserProfileStore();

  const chatContainerRef = useRef(null);
  const { data: sessionData } = useGetSessionHistory(
    loggedInUser?.userId,
    loggedInUser?.sessionId,
    page,
    pageSize
  );
  // eslint-disable-next-line
  const { mutateAsync: postQuestion, isLoading: isLoading } = usePostQuestion(); // eslint-disable-next-line
  const [isTyping, setIsTyping] = useState(isLoading); // Track if the bot is typing
  const [isAtBottom, setIsAtBottom] = useState(true); // Track if the user is at the bottom of the chat
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false); // Track if the image preview is open
  const fetchMoreMessageData = () => {
    // eslint-disable-next-line
    if (sessionData?.pagination?.total_pages > page) {
      setHasMore(true);
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false); // Disable infinite scroll when no more pages are available
    }
  };

  useEffect(() => {
    if (sessionData?.chat_history) {
      const initialMessages = sessionData?.chat_history
        .map((session) => [
          {
            text: session.answer, // Bot's answer
            sender: "bot",
            timestamp: new Date(session.updated_at),
          },
          {
            text: session.question, // User's question
            sender: "user",
            timestamp: new Date(session.created_at),
          },
        ])
        .flat(); // Flatten the array to make it a single array of messages

      const reversedMessages = initialMessages.reverse();

      if (page === 1) {
        // On initial page load, set the messages
        setMessages((prevMessages) => {
          // Remove duplicates based on 'text' or 'timestamp'
          const uniqueMessages = [
            ...new Map(
              initialMessages.map((msg) => [
                msg.text + msg.timestamp, // Create a unique key using text and timestamp
                msg,
              ])
            ).values(),
          ];
          return uniqueMessages;
        });
      } else if (page > 1) {
        // On subsequent page loads (when scrolling), append the new messages at the bottom
        setMessages((prevMessages) => {
          const uniqueMessages = [
            ...new Map(
              [...reversedMessages, ...prevMessages].map((msg) => [
                msg.text + msg.timestamp, // Create a unique key using text and timestamp
                msg,
              ])
            ).values(),
          ];
          return uniqueMessages;
        });
      }
    }
  }, [sessionData?.chat_history, page]); // This effect runs when either sessionData or page changes

  // Toggle chatbot open/close state
  const handleOpen = () => {
    setOpen(!open);
    setIsAtBottom(true);
    setPageSize(10);
  };

  // const handleScroll = (e) => {
  //   const scrollHeight = e.target.scrollHeight;
  //   const scrollTop = e.target.scrollTop;
  //   const clientHeight = e.target.clientHeight;
  // };

  // Handle user input submission
  const handleSubmit = async () => {
    if (input.trim()) {
      // 1. Add user message immediately to messages
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: input, sender: "user", timestamp: new Date() },
      ]);
      setInput("");
      // 2. Show typing indicator while waiting for the bot response
      setIsTyping(true);

      try {
        // 3. Make the API call to get the bot's response
        const data = await postQuestion({ question: input });

        // 4. If the bot has a response, add it to the message list
        if (data?.answer) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: data.answer, sender: "bot", timestamp: new Date() },
          ]);
        } else {
          // If there's no answer from the bot, display a default message
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: "Sorry, I didn't understand that.",
              sender: "bot",
              timestamp: new Date(),
            },
          ]);
        }
      } catch (error) {
        // Handle any errors during the API call
        console.error("Error in getting response from the bot:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: "There was an error. Please try again later.",
            sender: "bot",
            timestamp: new Date(),
          },
        ]);
      } finally {
        // 5. Hide typing indicator once the bot's response is received
        setIsTyping(false);
      }
    }
  };

  // Scroll to the latest message whenever the messages change
  useEffect(() => {
    if (open && messageEndRef.current) {
      // Only scroll to bottom if user is at the bottom of the chat
      if (isAtBottom) {
        messageEndRef.current.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [messages, open, isAtBottom]); // Ensure scrolling happens when messages change or when open state changes

  // Handle Enter key to submit message
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleSubmit();
    }
  };

  // Automatically adjust height of the textarea
  const handleInputChange = (e) => {
    setInput(e.target.value);
    // Adjust the height based on content
    e.target.style.height = "auto"; // Reset the height before setting the new one
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to the scroll height
  };

  // Format time in a human-readable format
  const formatTime = (date) => {
    return date?.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <>
      <div>
        {/* Dialog component */}
        {/* <Dialog open={imagePreviewOpen} handler={toggleDialog} size="2xl"> */}
        {/* <div className="flex items-center justify-center w-full  "> */}
        {/* {imageLink} */}
        {/* Image inside dialog */}
        <Viewer
          visible={imagePreviewOpen}
          onClose={() => {
            setImagePreviewOpen(false);
          }}
          images={[{ src: imageLink, alt: imageLink }]}
          changeable={false}
          noLimitInitializationSize={true}
        />
        {/* </div>
        </Dialog> */}
      </div>
      {/* Chatbot button */}
      <div
        className="flex flex-col justify-center items-center"
        onClick={handleOpen}
      >
        <div className="flex flex-col justify-center items-center">
          <QuestionMarkCircleIcon className="h-8 w-8" />
          <Typography color="blue-gray" className="text-xs font-normal">
            Debato
          </Typography>
        </div>
      </div>

      {/* Chatbot Dialog with Animation */}
      {open && (
        <Card
          className={`fixed -bottom-12 right-4 z-50 w-[40vw] bg-white shadow-2xl shadow-black/55 rounded-lg border border-gray-400 ${
            open
              ? "animate__animated animate__pulse animate__faster  "
              : " animate__animated animate__bounceOutDown "
          } `}
        >
          <div className="border-b-4  rounded-t rounded-b-3xl  px-4 shadow-2xl  border-gray-400 flex justify-between items-center bg-[#FAD84C]">
            <div className="flex items-center gap-2">
              <div className="h-16 w-16">
                <AIIcon />
              </div>
              <Typography
                color="blue-gray"
                className="font-semibold text-lg mt-2"
              >
                Debatebase Support Chatbot
              </Typography>
            </div>
            <button
              onClick={handleOpen}
              className="text-black hover:text-gray-800 shadow-md shadow-gray-600 rounded-full"
            >
              <XCircleIcon className="h-8 w-8" />
            </button>
          </div>
          <div
            id="scrollableDiv"
            className="w-full h-[calc(100dvh-22rem)] 4xl:h-[calc(100dvh-24rem)] 5xl:h-[calc(100dvh-28rem)] 6xl:h-[calc(100dvh-36rem)] overflow-y-auto"
            style={{
              // height: 250,
              paddingTop: "1rem",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse", // Keep the scroll bar at the bottom
            }}
          >
            <InfiniteScroll
              dataLength={sessionData?.pagination?.total_count} // If no sessionData, show 0
              next={fetchMoreMessageData}
              style={{ display: "flex", flexDirection: "column-reverse" }} // Ensure scroll behavior works in reverse
              inverse={true} // Infinite scroll starts from the bottom
              hasMore={hasMore}
              loader={
                sessionData?.pagination?.total_pages !== null && (
                  <p className="text-center m-5">⏳&nbsp;Loading...</p>
                )
              }
              endMessage={
                <p className="text-center m-5">No more chat history </p>
              }
              scrollableTarget="scrollableDiv"
            >
              <div ref={chatContainerRef}>
                {messages.map((message, index) => (
                  <div
                    key={index} // Ensure each message has a unique key
                    className={`flex ${
                      message.sender === "user"
                        ? "justify-end animate__animated  animate__fadeIn animate__faster"
                        : "justify-start animate__animated  animate__fadeIn "
                    } items-start`}
                  >
                    {message.sender === "bot" ? (
                      <div className="rounded-full border-2 bg-primary flex justify-center items-center transition-all duration-300 ml-2">
                        <img
                          src={require("../../Assets/Images/DB_AI.png")}
                          alt="Logo"
                          className="min-w-10 min-h-10 w-10 h-10"
                        />
                      </div>
                    ) : (
                      <ImageOrInitials
                        imageSrc={profileDetail?.userImage || null}
                        initials={
                          profileDetail?.userName || profileDetail?.email
                        }
                        size="h-8 w-8"
                      />
                    )}
                    <div className="flex flex-col gap-2">
                      <div
                        className={`flex ml-2 rounded-3xl items-center max-w-[90%]  p-3  shadow-sm ${
                          message.sender === "user"
                            ? "bg-gradient-to-r from-primary  to-gray-200 2xl:to-gray-300 text-right mr-4 max-w-[90%] text-black"
                            : "bg-gray-300 text-left text-black"
                        }`}
                      >
                        <Typography
                          // color="blue-gray"
                          className={`text-md font-normal text-left w-full`}
                        >
                          <BotMessage
                            answer={message.text}
                            setImagePreviewOpen={setImagePreviewOpen}
                            setImageLink={setImageLink}
                          />
                        </Typography>
                      </div>
                      <div
                        className={`text-xs text-gray-800  pb-3 ${
                          message.sender === "user"
                            ? "text-right pr-5"
                            : "text-left pl-3"
                        } `}
                      >
                        {formatTime(message.timestamp)}
                      </div>
                    </div>
                  </div>
                ))}
                {isTyping && (
                  <div className="flex justify-start items-center">
                    <div className="rounded-full flex justify-center items-center transition-all duration-300">
                      <img
                        src={require("../../Assets/Images/DB_AI.png")}
                        alt="Logo"
                        className="w-10 h-10"
                      />
                    </div>
                    <div
                      className={`flex ml-2 items-center max-w-xs p-3 rounded-lg shadow-sm bg-gray-100 text-left`}
                    >
                      <ThreeDots
                        visible={true}
                        height={"30px"}
                        width={"30px"}
                        color={primaryColor}
                        radius="9"
                        ariaLabel="three-dots-loading"
                      />
                    </div>
                  </div>
                )}

                <div ref={messageEndRef} />
              </div>
            </InfiniteScroll>
          </div>
          {/* <ChatBotTest messages={messages} BotMessage={BotMessage} formatTime={formatTime} profileDetail={profileDetail} sessionData={sessionData} page={page} setPage={setPage} setMessages={setMessages}/> */}

          {/* Input Box */}
          <div className="mt-4 border-t-4 border-gray-400 rounded-lg p-2 flex items-end  pt-2">
            <textarea
              className="w-full  font-semibold focus:outline-none resize-none min-h-[5px] overflow-auto pr-2"
              placeholder="Type your question here..."
              value={input}
              onChange={handleInputChange} // Adjust height dynamically
              onKeyDown={handleKeyDown} // Listen for Enter key press
            />
            <div
              onClick={handleSubmit}
              className="-rotate-45 text-secondary border-2 rounded-full p-2 mr-5  bg-primary"
            >
              <PaperAirplaneIcon className="h-5 w-5 bg-primary font-bold'" />

              {/* Send */}
            </div>
          </div>
          {/* <div
      id="scrollableDiv"
      style={{
        height: 300,
        overflow: "auto",
        display: "flex",
        flexDirection: "column-reverse" // Keep the scroll bar at the bottom
      }}
    >
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        style={{ display: "flex", flexDirection: "column-reverse" }} // Ensure scroll behavior works in reverse
        inverse={true} // Infinite scroll starts from the bottom
        hasMore={hasMore}
        loader={<h4>Loading...</h4>} // Loader while fetching data
        scrollableTarget="scrollableDiv"
      >
        {items.map((_, index) => (
          <div style={style} key={index}>
            div - #{index} {/* Display item index */}
        </Card>
      )}
    </>
  );
}
