import React from "react";
import AISelectedLine from "../../Assets/Images/AISelectedLine.png";
import SelectedLine from "../../Assets/Images/SelectedLine.png";
import { ReactComponent as AILogo } from "../../Assets/Svg/AILogo.svg";
import ActionButton from "./ActionButton";
import CancelActionButton from "./CancelActionButton";

export function PricingCard({
  plan,
  isYearly,
  subscribedPlan,
  theme = "base",
}) {
  const {
    isAiPlan,
    isRecommended,
    isContactUs,
    monthlyAmonut,
    planKeywords,
    planName,
    yearlyAmonut,
    planId,
  } = plan;
  const breakdownYearlyAmount = Number.isInteger(yearlyAmonut / 12)
    ? (yearlyAmonut / 12).toFixed(0)
    : (yearlyAmonut / 12).toFixed(2);
  const intervalWiseAmount = !isYearly ? monthlyAmonut : breakdownYearlyAmount;
  const planIntervalText = isYearly
    ? "per user / month, billed yearly"
    : "per user / month";
  const planInterval = isYearly ? "year" : "month";
  const isCurrentPlan =
    planId === subscribedPlan?.planId &&
    isAiPlan === !!subscribedPlan?.isAIPlan &&
    planInterval === subscribedPlan?.stripeJson?.items?.data[0]?.plan?.interval;

  const features = planKeywords.sort((a, b) =>
    a.included === b.included ? 0 : a.included ? -1 : 1
  );

  const AIColor =
    planName !== "Free" && planName !== "King's"
      ? theme === "base"
        ? "bg-gradient-to-br from-orange-50 to-blue-50 shadow-lg text-secondary"
        : "bg-gradient-to-br from-purple-50/70 to-pink-50/35 shadow-lg text-secondary"
      : "bg-white border text-secondary shadow-lg";

  return (
    <div className={`relative rounded-3xl py-6 px-8 w-80 ${AIColor}`}>
      <div className="space-y-6">
        <div className="text-center">
          <div
            className={`text-2xl font-bold mb-1 flex gap-2 items-center justify-center`}
          >
            {planName} {isAiPlan && <AILogo className="w-6 h-6" />}{" "}
          </div>
          {/* <p className={`text-sm h-10 `}>{description}</p> */}
        </div>

        {isContactUs ? (
          <div
            className={`flex items-baseline text-3xl font-bold  justify-center h-[75px] `}
          >
            Custom Pricing
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-[75px]">
            <div className="flex items-baseline">
              <span className={`text-[2rem] font-bold `}>$</span>
              <span className={`text-[2rem] font-bold `}>{intervalWiseAmount}</span>
            </div>
            <span className={`ml-1 `}>{planIntervalText}</span>
            {isRecommended && (
              <div>
                <img
                  src={isAiPlan ? AISelectedLine : SelectedLine}
                  alt="Selected Line"
                  className="w-full"
                />
              </div>
            )}
          </div>
        )}

        <ul className="space-y-2 h-64 overflow-y-auto">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-6">
              <div className={`w-3 h-3 rounded-full bg-secondary`} />
              <span className={`text-sm text-secondary`}>{feature.text}</span>
            </li>
          ))}
        </ul>
        <div className="h-20 flex flex-col justify-center gap-2">
          <ActionButton
            theme={theme}
            plan={plan}
            isYearly={isYearly}
            isCurrentPlan={isCurrentPlan}
            subscribedPlan={subscribedPlan}
          />
          <CancelActionButton
            plan={plan}
            isYearly={isYearly}
            isCurrentPlan={isCurrentPlan}
            subscribedPlan={subscribedPlan}
          />
        </div>
      </div>
    </div>
  );
}
