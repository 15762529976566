import React from "react";
import ActionButton from "../../Components/UI/Buttons/ActionButton";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import URLImage from "../../Assets/Images/url.png";
import VideoImage from "../../Assets/Images/video.png";
import { getFileType, getLocalTime } from "../../Utils/Helper";
import { useWorkspaceStore } from "../../Context/store";
import { useFileViewer } from "../../Context/FileViewerContext";
// import { Typography } from "@material-tailwind/react";

export const formatCitation = (citation) => {
  let title = "";
  let description = "";

  if (citation?.refFileType === "url") {
    if (!citation?.note) {
      title = citation?.refUrl?.split("/").pop();
      description = "";
    } else {
      // Assuming title and description are separated by 'Title:' and 'Description:' in citationNote
      const noteParts = citation?.note?.split("\n");
      title = noteParts[0]?.replace("Title: ", "").trim() || "";
      description = noteParts[1]?.replace("Description: ", "").trim() || "";
    }
  } else {
    // Extract the file name from the URL as the title
    const apiTitle = citation?.refUrl?.split("/").pop();
    title = apiTitle?.split("_")?.slice(1)?.join(" ");
    description = citation.note;
  }

  return { title, description };
};

const LibraryCard = ({ Actions, Citation, menuChildren = <></> }) => {
  const { title, description } = formatCitation({
    ...Citation,
    citationType: Citation?.refFileType,
  });
  const { selectedWorkspace } = useWorkspaceStore();
  const {handleFile} = useFileViewer();

  const fileType = getFileType(`hello.${Citation?.refFileType}`);
  const Src =
    fileType === "doc"
      ? DocImage
      : fileType === "pdf"
      ? PDFImage
      : Citation?.refFileType === "url"
      ? URLImage
      : fileType === "video"
      ? VideoImage
      : Citation?.refUrl;

  const handleClick = (citation) => {
    if (citation?.refFileType === "url") {
      window.open(citation.refUrl, "_blank");
      return;
    }
    handleFile({
      fileUrl: citation?.refUrl,
      fileType: citation?.refFileType,
      fileName: title,
      fileUploadedAt: getLocalTime(citation?.createdAt),
      createdBy: citation?.createdByUserName,
      creatorImage: citation?.createdByUserImage,
      channelName: selectedWorkspace?.workSpaceName,
    });
    return;
  };
  return (
    <div className="flex gap-2 w-full items-center">
      <figure className="w-14">
        <img
          id={`${Citation?.id}`}
          className="h-12 w-12 rounded-md object-cover object-center cursor-pointer"
          src={Src}
          alt={Citation?.refFileType}
          onClick={(e) =>{
            e.preventDefault();
            e.stopPropagation();
            handleClick(Citation)
          }}
        />
      </figure>

      <div className="px-2 py-1 text-wrap rounded-md flex-1 border border-debatePalette-buttonBorder">
        <span className="line-clamp-1 text-debatePalette-title font-bold">
          Title: {title}
        </span>
        <span className="line-clamp-2">Description: {description}</span>
      </div>
      {Actions &&
        Actions?.map((act) => (
          <ActionButton
            key={act.title}
            SVG={act.SVG}
            title={act.title}
            istooltip={false}
            isText={false}
            isDisable={act.isDisable}
            onClick={() => act?.handleClick({...Citation, title: title})}
          />
        ))}
      {menuChildren}
    </div>
  );
};

export default LibraryCard;
