import React, { useState } from "react";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import { useSocket } from "../../Context/SocketContext";
import { useUserStore } from "../../Context/store";
import { useGetExternalData } from "../../Web-Hooks/Chat/chat";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import {
  Checkbox,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { useWorkspaceStore } from "../../Context/store";
import { useSubworkspaceStore } from "../../Context/store";
import { getUserImageUrl } from "../../Utils/Helper";
import { useGroupChatList } from "../../Web-Hooks/Chat/use-group-chat";
import NoData from "../../Components/Functionality/NoData";

const ShareDebate = ({
  shareDebatePopup,
  setShareDebatePopup,
  debate,
  setDebate,
  type = "debate",
  enabled,
}) => {
  const { sendMessage, sendGroupMessage } = useSocket();
  const { user } = useUserStore();
  const [contact, setContact] = useState([]);
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const [activeTab, setActiveTab] = useState("message");

  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";
  const { data: chatUserList, isLoading: chatUserListLoading } =
    useGetExternalData(
      user?.userId,
      selectedWorkspace?.workSpaceId,
      isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
      "",
      enabled
    );
  const { data: groupChatList } = useGroupChatList(
    user?.userId,
    selectedWorkspace?.workSpaceId,
    isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
    true,
    (data) => {
      return {
        totalCount: data?.data?.data?.totalUnreadCount,
        groups: data?.data?.data?.groups,
      };
    }
  );

  const handleSendOject = async (receivers = []) => {
    if (!receivers.length) return;

    const userContacts = contact.filter((item) => item.username);
    const groupContacts = contact.filter((item) => item.name);

    const Message = {
      type: type,
      content: debate,
    };

    userContacts.forEach((receiver) => {
      sendMessage(
        user?.userId,
        receiver.id,
        Message,
        selectedWorkspace?.workSpaceId,
        selectedSubWorkspace?.subWorkSpaceId
      );
    });
    groupContacts.forEach((receiver) => {
      sendGroupMessage(
        receiver.id,
        user?.userId,
        Message,
        selectedWorkspace?.workSpaceId,
        selectedSubWorkspace?.subWorkSpaceId
      );
    });
  };

  const isDebate = type === "debate" ? true : false;
  const isMotion = type === "motion" ? true : false;

  const handleChange = (member) => {
    if (contact.some((c) => c.id === member.id)) {
      setContact(contact.filter((c) => c.id !== member.id));
    } else {
      setContact([...contact, member]);
    }
  };

  const data = [
    {
      label: "Message",
      value: "message",
      Component: (
        <form className="flex flex-col overflow-y-auto member-list h-72 items-start gap-2">
          {chatUserList?.length === 0 && (
            <div className="flex w-full h-full justify-center items-center">
              <NoData message={"No groups found"} size={"w-[100%] h-[80%]"} />
            </div>
          )}
          {chatUserList?.map((member) => (
            <Checkbox
              key={member?.id}
              containerProps={{
                className: "!flex items-center gap-4 shrink-0",
              }}
              checked={contact.some((c) => c.id === member.id)}
              onChange={() => handleChange(member)}
              id={member?.id}
              color="blue"
              ripple={false}
              className="w-4 h-4 rounded-none before:content-none"
              label={
                <div className="flex items-center gap-2 w-full truncate">
                  {
                    <ImageOrInitials
                      imageSrc={getUserImageUrl(member?.id, member?.avatar)}
                      initials={member?.username || member?.email}
                    />
                  }
                  <p className="w-full text-md">
                    {member?.username || member?.email?.split("@")[0]}
                  </p>
                </div>
              }
              labelProps={{
                className:
                  "text-debatePalette-title w-full text-md font-semibold capitalize",
              }}
            />
          ))}
        </form>
      ),
    },
    {
      label: "Group",
      value: "group",
      Component: (
        <form className="flex flex-col overflow-y-auto member-list h-72 items-start gap-2">
          {groupChatList?.groups?.length === 0 && (
            <div className="flex w-full h-full justify-center items-center">
              <NoData message={"No groups found"} size={"w-[100%] h-[80%]"} />
            </div>
          )}
          {groupChatList?.groups?.map((group) => (
            <Checkbox
              key={group?.id}
              containerProps={{
                className: "!flex items-center gap-4 shrink-0",
              }}
              checked={contact.some((c) => c.id === group.id)}
              onChange={() => handleChange(group)}
              id={group?.id}
              color="blue"
              ripple={false}
              className="w-4 h-4 rounded-none before:content-none"
              label={
                <div className="flex items-center gap-2 w-full truncate">
                  {
                    <ImageOrInitials
                      imageSrc={getUserImageUrl(group?.id, group?.avatar)}
                      initials={group?.name}
                    />
                  }
                  <p className="w-full text-md">{group?.name}</p>
                </div>
              }
              labelProps={{
                className:
                  "text-debatePalette-title w-full text-md font-semibold capitalize",
              }}
            />
          ))}
        </form>
      ),
    },
  ];

  return (
    <div>
      <EditableBodyPopup
        open={shareDebatePopup}
        handleClose={() => {
          setShareDebatePopup(false);
          setDebate(null);
          setContact([]);
        }}
        disableBtn={!contact}
        btn1={"Cancel"}
        btn2={"Send"}
        title={`Share ${isDebate ? "Debate" : isMotion ? "Motion" : "Profile"}`}
        onClickBtn1={() => {
          setShareDebatePopup(false);
          setDebate(null);
          setContact([]);
        }}
        onClickBtn2={async () => {
          await handleSendOject(contact);
          setShareDebatePopup(false);
          setDebate(null);
          setContact([]);
        }}
      >
        {chatUserListLoading ? (
          <LoadingSpinner />
        ) : (
          <Tabs value={activeTab}>
            <TabsHeader
              className="rounded-none border-b px-2 py-0 w-full border-transparent bg-transparent"
              indicatorProps={{
                className:
                  "bg-transparent border-b-2 border-primary shadow-none rounded-none",
              }}
            >
              {data.map(({ label, value, unReadCount }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className={
                    activeTab === value
                      ? "text-debatePalette-title font-semibold"
                      : "text-debatePalette-title"
                  }
                >
                  <div className="flex gap-2 text-sm font-semibold ">
                    <span>{label}</span>
                    {!!unReadCount && <span>{unReadCount}</span>}
                  </div>
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {data.map(({ value, Component }) => (
                <TabPanel className="py-2 px-0" key={value} value={value}>
                  {Component}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        )}
        <div className="flex flex-col gap-2 items-center">
          <p className="text-lg text-debatePalette-bodyText">
            Do you want to share the{" "}
            {`${isDebate ? "Debate" : isMotion ? "Motion" : "Profile"}`}?
          </p>
        </div>
      </EditableBodyPopup>
    </div>
  );
};

export default ShareDebate;
