import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  addReference,
  addToSaveReference,
  deleteReference,
  getReferenceList,
  getSavedReferenceList,
} from "../../api/reference/reference";
import { useUserStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import {
  updateDynamicDeleteReference,
  updateDynamicSaveUnSaveReference,
  updateDynamicUpdateReference,
} from "../../Utils/Helper";

export const useGetReferenceList = (
  userId,
  workspaceId,
  subworkspaceId,
  search
) =>
  useInfiniteQuery({
    queryKey: ["reference", userId, workspaceId, subworkspaceId, search],
    queryFn: ({ pageParam = 1 }) =>
      getReferenceList(userId, workspaceId, subworkspaceId, search, pageParam),
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined; // Load next page if available
    },
    enabled: !!userId && !!workspaceId,
  });
export const useGetSavedReferenceList = (userId, workspaceId, search) =>
  useInfiniteQuery({
    queryKey: ["reference", "saved", userId, workspaceId, search],
    queryFn: ({ pageParam = 1 }) =>
      getSavedReferenceList(userId, workspaceId, search, pageParam),
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined; // Load next page if available
    },
    enabled: !!userId && !!workspaceId,
  });

export const useAddReference = () => {
  const queryClient = useQueryClient();
  const [search] = useSearchParams();
  return useMutation({
    mutationFn: async (reference) => {
      const data = await addReference(reference);
      const { message } = data;
      return {
        ...reference,
        newReferenceId: message.referenceId,
        query: search?.get("search") || "",
      };
    },
    onSuccess: async (data) => {
      await Promise.all([
        queryClient.setQueryData(
          [
            "reference",
            data?.CreatedByUserId,
            data?.WorkspaceId,
            data?.SubworkspaceId || "",
            data?.query || "",
          ],
          (oldData) =>
            updateDynamicUpdateReference(
              oldData,
              data?.referenceId,
              data,
              "referencesList",
              "reference"
            )
        ),
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useSaveReference = () => {
  const queryClient = useQueryClient();
  const [search] = useSearchParams();
  const query = search?.get("search") || "";
  const { user } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();

  return useMutation({
    mutationFn: async (reference) => {
      const subId = reference?.SubworkspaceId;
      delete reference.SubworkspaceId;
      const data = await addToSaveReference(reference);
      const { message } = data;
      return {
        ...message,
        workSpaceId: reference?.WorkspaceId || selectedWorkspace?.workSpaceId,
        subworkspaceId: subId || "",
        referenceId: reference?.ReferenceId,
        query: query,
        isReferenceSave: reference?.IsReferenceSave,
        userId: user?.userId,
      };
    },
    onSuccess: async (data) => {
      await Promise.all([
        queryClient.setQueryData(
          [
            "reference",
            "saved",
            data?.userId,
            data?.workSpaceId,
            data?.query || "",
          ],
          (oldData) =>
            updateDynamicSaveUnSaveReference(
              oldData,
              data?.referenceId,
              data?.isReferenceSave,
              "referencesList",
              "saved"
            )
        ),
        queryClient.setQueryData(
          [
            "reference",
            data?.userId,
            data?.workSpaceId,
            data?.subworkspaceId,
            data?.query || "",
          ],
          (oldData) =>
            updateDynamicSaveUnSaveReference(
              oldData,
              data?.referenceId,
              data?.isReferenceSave,
              "referencesList",
              "reference"
            )
        ),
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

export const useDeleteReference = () => {
  const queryClient = useQueryClient();
  const [search] = useSearchParams();
  const query = search?.get("search") || "";
  const { user } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();
  return useMutation({
    mutationFn: async (reference) => {
      const subId = reference?.SubworkspaceId;
      const workId = reference?.WorkspaceId;
      delete reference?.WorkspaceId;
      delete reference?.SubworkspaceId;
      const data = await deleteReference(reference);
      const { message } = data;
      return {
        ...message,
        workSpaceId: workId || selectedWorkspace?.workSpaceId,
        subworkspaceId: subId,
        referenceId: reference?.ReferenceId,
        query: query,
        isReferenceSave: reference?.IsReferenceSave,
        userId: user?.userId,
      };
    },
    onSuccess: async (data) => {
      await Promise.all([
        queryClient.setQueryData(
          [
            "reference",
            data?.userId,
            data?.workSpaceId,
            data?.subworkspaceId,
            data?.query || "",
          ],
          (oldData) =>
            updateDynamicDeleteReference(
              oldData,
              data?.referenceId,
              "referencesList",
              "reference"
            )
        ),
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
