import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { hoverTextStyle } from "../../Utils/Constant";

const SignupFooter = ({ info }) => {

  const location = useLocation();

  // console.log(toggle);

  return (
    <div className="flex flex-col xs:flex-row justify-center items-center gap-5">
      {info?.footerOpt?.map((item, i) => (
        <Fragment key={i}>
          {item.opt !== "Choose Country" && item.opt !== "Choose Region" && (
            <Link
              key={i}
              id={item?.value}
              as="button"
              target="_blank"
              to={item?.link}
              state={{ prevPath: location?.pathname }}
              variant="small"
              color="black"
              className={`ml-1 text-debatePalette-base text-sm underline ${hoverTextStyle}`}
            >
              {item.opt}
            </Link>
          )}
          {/* {(item.opt === "Choose Country" || item.opt === "Choose Region") && (
            <Menu open={toggle} handler={() => setToggle1(!toggle)}>
              <MenuHandler id={'countries'}>
                <div
                  className={`ml-1 min-w-[150px] text-sm text-debatePalette-base flex capitalize gap-2 items-center justify-center ${hoverTextStyle}`}
                >
                  <div className="flex gap-2">
                    {selectedCountry === null && (
                      <GlobeAltIcon className="h-5 w-5" />
                    )}
                    {selectedCountry || item.opt}
                  </div>
                  <ChevronDownIcon
                    strokeWidth={1.5}
                    className={`h-3.5 w-3.5 transition-transform mt-1 text-debatePalette-base ${
                      toggle ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </MenuHandler>
              <MenuList className="w-[100px] bg-debatePalette-background">
                {COUNTRIES?.map((item, i) => (
                  <MenuItem
                    key={i}
                    id={item?.name}
                    onClick={() => {
                      setSelectedCountry(item.name);
                      setToggle1(false);
                    }}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )} */}
        </Fragment>
      ))}
    </div>
  );
};

export default SignupFooter;
