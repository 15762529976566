import {
  BuildingLibraryIcon,
  DocumentIcon,
  PaperClipIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import ImageCropperSRP from "../../../Components/Functionality/ImageCropperSRP";
import EditableBodyPopup from "../../../Components/Popup/EditableBodyPopup";
import { useSocket } from "../../../Context/SocketContext";
import { getFileType, getRoomName } from "../../../Utils/Helper";
import { useUploadCitation } from "../../../Web-Hooks/LibraryAPI/use-citation";
import LibraryPopup from "../LibraryPopup";
import { useWorkspaceStore } from "../../../Context/store";
import { useSubworkspaceStore } from "../../../Context/store";
import { GetCopiedURL } from "../../../api/debate/debate";

const MessageFileUpload = ({ sender, receiver, type = "individual" }) => {
  const { sendMessage, sendGroupMessage } = useSocket();
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const [selectFromLibraryPopup, setSelectFromLibraryPopup] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [selectedCitation, setSelectedCitation] = useState({});
  const [imageCropperPopup, setImageCropperPopup] = useState(false);
  const imageCropperRef = useRef(null);
  const { mutateAsync: uploadFile, isPending: isUploading } =
    useUploadCitation();

  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";

  const handleSend = (selectedCitation) => {
    const fileType =
      selectedCitation?.citationType === "url"
        ? "url"
        : getFileType(selectedCitation.citationUrl);
    const Message = {
      type: fileType,
      content: {
        url: selectedCitation.citationUrl,
      },
    };
    setSelectedCitation(null);

    type === "group"
      ? sendGroupMessage(
          receiver?.id,
          sender?.userId,
          Message,
          selectedWorkspace?.workSpaceId,
          isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
        )
      : sendMessage(
          sender?.userId,
          receiver?.id,
          Message,
          selectedWorkspace?.workSpaceId,
          isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
        );
  };
  const Options = [
    {
      id: "gallary",
      SVG: <PhotoIcon className="size-5 text-inherit" />,
      label: "Gallary",
      value: "gallary",
      handleClick: () => {
        imageCropperRef.current.click();
      },
    },
    {
      id: "file",
      SVG: <DocumentIcon className="size-5 text-inherit" />,
      label: "File",
      value: "file",
      handleClick: async () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = `video/mp4,.doc,.docx,.pdf`; // Added image/* for all image types
        input.click();
        await new Promise((resolve) => {
          input.onchange = (e) => {
            resolve(e.target.files[0]);
            if (e.target.files[0]) {
              setSelectedCitation((prev) => ({
                ...prev,
                citationTitle: e.target.files[0].name,
                citationUrl: e.target.files[0].name,
                citationType: e.target.files[0].name.split(".")?.pop(),
                citationFile: e.target.files[0],
                citationFrom: "device",
              }));
              setConfirmationPopup(true);
            }
          };
        });
      },
    },
    {
      id: "library",
      SVG: <BuildingLibraryIcon className="size-5 text-inherit" />,
      label: "Library",
      value: "library",
      handleClick: () => {
        setSelectFromLibraryPopup(true);
      },
    },
  ];

  const roomName = getRoomName(sender?.userId, receiver?.id);

  const individualPath = isForumOnly
    ? `${process.env.REACT_APP_CHAT_UPLOAD}/individual/${selectedSubWorkspace?.subWorkSpaceId}/${roomName}`
    : `${process.env.REACT_APP_CHAT_UPLOAD}/individual/${roomName}`;
  const groupPath = isForumOnly
    ? `${process.env.REACT_APP_CHAT_UPLOAD}/group/${selectedSubWorkspace?.subWorkSpaceId}/${receiver?.id}`
    : `${process.env.REACT_APP_CHAT_UPLOAD}/group/${receiver?.id}`;

  const handleUploadFile = async () => {
    if (!selectedCitation?.citationFile) {
      if (selectedCitation?.citationType === "url") {
        handleSend(selectedCitation);
        return;
      }
      if (!selectedCitation?.citationUrl) return;
      const data = await GetCopiedURL(
        selectedCitation?.citationUrl?.split("debatemodule/").pop(),
        type === "group" ? groupPath : individualPath
      );

      const newUrl = `${
        process.env.REACT_APP_AZURE_STORAGE_BASE_URL
      }/debatemodule/${type === "group" ? groupPath : individualPath}/${data}`;

      handleSend({ ...selectedCitation, citationUrl: newUrl });
      return;
    }
    const formData = new FormData();
    formData.set("file", selectedCitation?.citationFile || null);
    formData.set("filePath", type === "group" ? groupPath : individualPath);

    // formData.set("filePath", `Users/${sender?.userId}`);
    const { data } = await uploadFile(formData);
    if (!data?.data) {
      toast.error("Error uploading file");
      return;
    }
    handleSend({
      ...selectedCitation,
      citationUrl: data.data,
    });
  };

  const handleAttachmentClick = () => {
    setSelectFromLibraryPopup(true);
  };

  return (
    <Fragment>
      <ImageCropperSRP
        inputRef={imageCropperRef}
        setModalOpen={setImageCropperPopup}
        modalOpen={imageCropperPopup}
        maxCropSize={500}
        type={`debate`}
        updateAvatar={(file, url) => {
          setSelectedCitation({
            ...selectedCitation,
            citationTitle: file.name,
            citationUrl: url,
            citationType: "image",
            citationFile: file,
            citationFrom: "gallary",
            citationNote: "image",
          });
          setConfirmationPopup(true);
        }}
        closeModal={() => setImageCropperPopup(false)}
        resetState={() => {
          setSelectedCitation({
            citationUrl: "",
            citationNote: "",
            citationType: "",
            citationFile: null,
          });
        }}
      />
      <EditableBodyPopup
        open={confirmationPopup}
        handleClose={() => setConfirmationPopup(false)}
        btn1={"Cancel"}
        btn2={"Send"}
        loading={isUploading}
        title={"Attach File"}
        onClickBtn1={() => {
          setConfirmationPopup(false);
          setSelectedCitation({});
        }}
        onClickBtn2={async () => {
          await handleUploadFile();
          setConfirmationPopup(false);
          setSelectedCitation({});
        }}
      >
        <div className="flex flex-col gap-2 items-center">
          <p className="text-lg text-debatePalette-bodyText">
            Do you want to attach the file?
          </p>
          <p>{selectedCitation?.citationTitle}</p>
        </div>
      </EditableBodyPopup>
      <LibraryPopup
        setCitationPopup={() => {}}
        setSelectedCitation={setSelectedCitation}
        libraryPopup={selectFromLibraryPopup}
        setLibraryPopup={setSelectFromLibraryPopup}
        handleSubmit={() => {
          setSelectFromLibraryPopup(false);
          setConfirmationPopup(true);
        }}
      />
      <Menu placement="top-start">
        <MenuHandler>
          <Button
            variant="text"
            className="text-gray-500 p-1 text-xl mr-3 focus:outline-none"
            onClick={handleAttachmentClick}
          >
            <PaperClipIcon stroke="2" className="size-6 text-secondary" />
          </Button>
        </MenuHandler>
        <MenuList className="min-w-40 bg-secondary text-debatePalette-main !z-10000000">
          {Options?.map((option) => (
            <MenuItem
              key={option.id}
              onClick={option.handleClick}
              className="flex items-center gap-2 hover:bg-gray-100 px-2 py-1"
            >
              {option.SVG}
              <p className="text-md font-semibold text-inherit py-2">
                {option.label}
              </p>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Fragment>
  );
};

export default MessageFileUpload;
