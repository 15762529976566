import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ReportUser } from "../../api/debate/debate";
import {
  blockUser,
  getActiveDebates,
  getDraftDebates,
  getLostDebates,
  getOpenDebates,
  getOtherUserDetailsByUserId,
  getPendingDebates,
  getSavedDebates,
  getUserDebates,
  getUserDetailsByUserId,
  getWonDebates,
  unBlockUser,
  updateUserDetails,
} from "../../api/profile/profile";
import { useUserStore } from "../../Context/store";

export const useGetUserById = (userId, subId, workId, enabled = true) =>
  useQuery({
    queryKey: ["user", userId, subId, workId, enabled],
    queryFn: () => getUserDetailsByUserId(userId, subId, workId),
    select: (data) => data,
    enabled: !!userId && !!workId,
  });
export const useGetUserDebates = (userId, workId) => {
  const { user: loggedInUser } = useUserStore();
  const loggedInUserId = loggedInUser?.userId;
  return useInfiniteQuery({
    queryKey: ["other-user-debates", userId, loggedInUserId, workId],
    queryFn: ({ pageParam = 1 }) =>
      getUserDebates(userId, loggedInUserId, workId, pageParam),
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined; // Load next page if available
    },
    enabled: !!userId && loggedInUserId && !!workId,
  });
};

export const useGetOtherUserDetailsById = (otherUserId, workspaceId) => {
  const { user: loggedInUser } = useUserStore();
  const loggedInUserId = loggedInUser?.userId;
  return useQuery({
    queryKey: ["other-user", otherUserId, loggedInUserId, workspaceId],
    queryFn: () =>
      getOtherUserDetailsByUserId(otherUserId, loggedInUserId, workspaceId),
    enabled: !!otherUserId && !!loggedInUserId && !!workspaceId,
    select: (data) => data,
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (updatedProfileBody) => {
      return await updateUserDetails(updatedProfileBody);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};

export const useBlockUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (blockUserBody) => {
      return await blockUser(blockUserBody);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["public-feed-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-voted-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["feed-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["voted-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
        // queryClient.invalidateQueries({
        //   queryKey: ["workspace-users"],
        // }),
        // queryClient.invalidateQueries({
        //   queryKey: ["sub-workspace-users"],
        // }),
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-private-users"],
        }),
      ]);
    },
  });
};
export const useUnblockUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (blockUserBody) => {
      return await unBlockUser(blockUserBody);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["public-feed-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-voted-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["feed-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["voted-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["sub-workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-private-users"],
        }),
      ]);
    },
  });
};

export const useReportUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (reportDebate) => {
      return await ReportUser(reportDebate);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["sub-workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-voted-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["voted-subworkspace"],
        }),
      ]);
    },
  });
};

export const useGetActiveDebates = (
  userId,
  subworkspaceId,
  workSpaceId,
  category,
  search
) => {
  return useInfiniteQuery({
    queryKey: [
      "active-debates",
      userId,
      subworkspaceId,
      workSpaceId,
      category,
      search,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      return await getActiveDebates(
        userId,
        subworkspaceId,
        workSpaceId,
        category,
        search,
        pageParam
      );
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!workSpaceId,
  });
};
export const useGetPendingDebates = (
  userId,
  subworkspaceId,
  workSpaceId,
  category,
  search
) => {
  return useInfiniteQuery({
    queryKey: [
      "pending-debates",
      userId,
      subworkspaceId,
      workSpaceId,
      category,
      search,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      return await getPendingDebates(
        userId,
        subworkspaceId,
        workSpaceId,
        category,
        search,
        pageParam
      );
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!workSpaceId,
  });
};
export const useGetOpenDebates = (
  userId,
  subworkspaceId,
  workSpaceId,
  category,
  search
) => {
  return useInfiniteQuery({
    queryKey: [
      "open-debates",
      userId,
      subworkspaceId,
      workSpaceId,
      category,
      search,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      return await getOpenDebates(
        userId,
        subworkspaceId,
        workSpaceId,
        category,
        search,
        pageParam
      );
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!workSpaceId,
  });
};
export const useGetSavedDebates = (
  userId,
  subworkspaceId,
  workSpaceId,
  category
) => {
  return useInfiniteQuery({
    queryKey: ["saved-debates", userId, subworkspaceId, workSpaceId, category],
    queryFn: async ({ pageParam = 1 }) => {
      return await getSavedDebates(
        userId,
        subworkspaceId,
        workSpaceId,
        category,
        pageParam
      );
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!workSpaceId,
  });
};
export const useGetWonDebates = (userId, subworkspaceId, search) => {
  return useInfiniteQuery({
    queryKey: ["won-debates", userId, subworkspaceId, search],
    queryFn: async ({ pageParam = 1 }) => {
      return await getWonDebates(userId, subworkspaceId, search, pageParam);
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!subworkspaceId,
  });
};
export const useGetLostDebates = (userId, subworkspaceId, search) => {
  return useInfiniteQuery({
    queryKey: ["lost-debates", userId, subworkspaceId, search],
    queryFn: async ({ pageParam = 1 }) => {
      return await getLostDebates(userId, subworkspaceId, search, pageParam);
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => data,
    enabled: !!userId && !!subworkspaceId,
  });
};

export const useGetDraftDebates = (
  userId,
  subworkspaceId,
  workSpaceId,
  category,
  search
) => {
  return useInfiniteQuery({
    queryKey: [
      "draft-debates",
      userId,
      subworkspaceId,
      workSpaceId,
      category,
      search,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      return await getDraftDebates(
        userId,
        subworkspaceId,
        workSpaceId,
        category,
        search,
        pageParam
      );
    },
    select: (data) => data,
    getNextPageParam: (lastPage) => {
      const { currentPage, totalPages } = lastPage;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    enabled: !!userId && !!workSpaceId,
  });
};
