import { Button, Chip, Typography } from "@material-tailwind/react";
import React from "react";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { useUserStore } from "../../../Context/store";
import { useSocket } from "../../../Context/SocketContext";
import { useSubworkspaceStore } from "../../../Context/store";
import { useWorkspaceStore } from "../../../Context/store";
import { getUserImageUrl } from "../../../Utils/Helper";
import { useMakeMemberAdmin, useRemoveMemberGroup } from "../../../Web-Hooks/Chat/use-group-chat";

const Member = ({ member, groupDetails, setGroupDetails, isLoggedInUserAdmin }) => {
  const { mutateAsync: removeMember, isPending: isRemoving } =
    useRemoveMemberGroup();
  const { mutateAsync: makeAdmin, isPending: isMakingAdmin } =
    useMakeMemberAdmin();
  const { user: loggedInUser } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();
  const { selectedSubWorkspace } = useSubworkspaceStore();
  const { sendGroupMessage } = useSocket();

  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true";

  const handleRemoveMember = async () => {
    try {
      const userToRemove = {
        id: groupDetails?.id,
        userId: member?.id,
      };
      await removeMember(userToRemove);

      const Message = {
        type: "user_removed",
        content: {
          removedByUserId: loggedInUser?.userId,
          removedByUserName: loggedInUser?.userName,
          userId: member?.id,
          userName: member?.username,
        },
      };
      sendGroupMessage(
        groupDetails?.id,
        loggedInUser?.userId,
        Message,
        selectedWorkspace?.workSpaceId,
        isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
      );
      setGroupDetails((prev) => ({
        ...prev,
        users: prev?.users?.filter((item) => item !== member?.id),
      }));
    } catch (error) {
      console.log("Error occurred while removing member", error);
    }
  };
  const handleMakeAdmin = async () => {
    try {
      const makeUserAdmin = {
        groupId: groupDetails?.id,
        currentAdminId: loggedInUser?.userId,
        newAdminId: member?.id,
        workspaceId: selectedWorkspace?.workSpaceId,
        subworkspaceId: isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
      };
      await makeAdmin(makeUserAdmin);

      // const Message = {
      //   type: "user_removed",
      //   content: {
      //     removedByUserId: loggedInUser?.userId,
      //     removedByUserName: loggedInUser?.userName,
      //     userId: member?.id,
      //     userName: member?.username,
      //   },
      // };
      // sendGroupMessage(
      //   groupDetails?.id,
      //   loggedInUser?.userId,
      //   Message,
      //   selectedWorkspace?.workSpaceId,
      //   selectedSubWorkspace?.subWorkSpaceId
      // );
      // setGroupDetails((prev) => ({
      //   ...prev,
      //   users: prev?.users?.filter((item) => item !== member?.id),
      // }));
    } catch (error) {
      console.log("Error occurred while removing member", error);
    }
  };

  return (
    <div className="flex items-center w-full justify-between gap-2">
      <div className="flex gap-3 items-center">
        <ImageOrInitials
          imageSrc={getUserImageUrl(member?.id, member?.avatar)}
          initials={member?.username}
          showUserStatus={true}
          userStatus={member?.online}
          size="h-10 w-10"
        />
        <div className="flex flex-col">
          <Typography className=" capitalize font-semibold text-debatePalette-title">
            {member?.username}
          </Typography>
          <Typography
            variant="small"
            className="text-xs capitalize text-debatePalette-title"
          >
            {member?.isAdmin ? <Chip color="green" variant="gradient" value="Admin" size="sm" className="capitalize p-0 text-center py-1 px-1"/> : "Member"}
          </Typography>
        </div>
      </div>
      {isLoggedInUserAdmin && member?.id !== loggedInUser?.userId && (
        <div className="flex gap-3 items-center justify-center">
          <Button
            variant="text"
            className="text-xs bg-secondary text-debatePalette-background hover:text-debatePalette-title"
            onClick={handleMakeAdmin}
            loading={isMakingAdmin}
          >
            Make Admin
          </Button>
          <Button
            variant="text"
            color="red"
            className="text-xs"
            onClick={handleRemoveMember}
            loading={isRemoving}
          >
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};
const GroupMemberPopup = ({ groupDetails, setGroupDetails, groupMembers, isLoggedInUserAdmin }) => {
  return (
    <div className="flex flex-col items-start gap-3 justify-center">
      {groupMembers?.map((member) => (
        <Member
          key={member?.id}
          setGroupDetails={setGroupDetails}
          member={member}
          groupDetails={groupDetails}
          isLoggedInUserAdmin={isLoggedInUserAdmin}
        />
      ))}
    </div>
  );
};

export default GroupMemberPopup;
