import {
  Avatar,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import React, { Fragment, useState } from "react";
import { getInitials } from "../../../Utils/Helper";

export function AvatarStack({ users = [], maxVisible = 3 }) {
  const [open, setOpen] = useState(false);
  const visibleUsers = users.slice(0, maxVisible);
  const hiddenUsers = users.slice(maxVisible);
  const totalHidden = hiddenUsers.length;

  if (users.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex items-center flex-1">
        <div className="flex flex-row-reverse items-center w-full">
          {visibleUsers.reverse().map((user, index) => (
            <Fragment key={index}>
              <UserAvatar
                user={user}
                className="-ml-4 border-2 h-10 w-10 hover:z-10 border-white"
              />
            </Fragment>
          ))}
        </div>
        {totalHidden > 0 && (
          <button
            className="flex items-center -ml-2 justify-center w-10 h-10 bg-gray-200 rounded-full text-sm font-bold hover:z-10"
            onClick={() => setOpen(true)}
          >
            +{totalHidden}
          </button>
        )}
      </div>

      {/* Popup Modal for All Users */}
      <Dialog open={open} handler={() => setOpen(false)}>
        <DialogHeader>All Users</DialogHeader>
        <DialogBody className="flex flex-wrap gap-4 p-4">
          {users.map((user, index) => (
            <div key={index} className="flex items-center gap-3">
              <UserAvatar user={user} />
              <span className="text-gray-700">{user.name}</span>
            </div>
          ))}
        </DialogBody>
      </Dialog>
    </>
  );
}

// Separate component for handling Avatar and Initials
const UserAvatar = ({ user, className = "" }) => {
  const [imgError, setImgError] = useState(false);

  return imgError || !user.image_Url ? (
    <span
      className={`flex relative items-center justify-center border-2 bg-gray-200 text-gray-700 font-semibold rounded-full border-white h-10 w-10 ${className}`}
    >
      {getInitials(user.name)}
    </span>
  ) : (
    <Avatar
      size="sm"
      variant="circular"
      alt={user.name}
      className={`border-2 relative bg-gray-200 ${className}`}
      src={user.image_Url}
      onError={() => setImgError(true)}
    />
  );
};
