import React from "react";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "../../Components/Functionality/InfiniteScrollList";
import Container from "../../Components/UI/Container";
import { useUserStore } from "../../Context/store";
import { useWorkspaceStore } from "../../Context/store";
import { useGetPublicUserWiseVotedList } from "../../Web-Hooks/Debate/use-debate";
import Posts from "../Posts/Posts";

const PublicVoted = () => {
  const location = useLocation();
  const { pathname } = location;
  const opponentId = pathname.slice(1).split("/")[1];
  const { user } = useUserStore();
  const { selectedWorkspace } = useWorkspaceStore();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
  useGetPublicUserWiseVotedList(
      user?.userId,
      selectedWorkspace?.workSpaceId,
      opponentId
    );

  return (
    <Container>
      {/* Title */}
        <InfiniteScroll
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
        >
          <Posts
            apiType={pathname}
            user={user}
            isLoading={isLoading && !isFetchingNextPage}
            AllDebates={data?.pages?.flatMap((page) => page.votedDebateList)} // Combine all pages
          />
        </InfiniteScroll>
    </Container>
  );
};

export default PublicVoted;
