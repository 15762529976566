import { Card, CardHeader, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddFollowing } from "../../../Web-Hooks/Profile/use-following";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import UserFollowButton from "../Buttons/UserFollowButton";
import { getNameFromEmail } from "../../../Utils/Helper";
import { useUserStore } from "../../../Context/store";

export default function UserCard({ user, onClick, className }) {
  const navigate = useNavigate();
  const {user: loggedInUser} = useUserStore();
  const [following, setFollowing] = useState(user.isFollowing);
  const { mutate: handleFollow } = useAddFollowing();

  const handleFollowing = (e,followingId) => {
    e.stopPropagation();
    handleFollow({
      Type: !following ? "follow" : "unfollow",
      FollowingUserId: followingId,
      FollowerUserId: loggedInUser?.userId,
      IsFollow: true,
    });
    setFollowing(!following);
  };
  return (
    <Card
      color="transparent"
      shadow={false}
      className={` py-0 max-w-44 w-full border shadow-md cursor-pointer ${className}`}
      onClick={() => {
        navigate(`/profile/${user?.userId}`, { state: { user: user } });
      }}
    >
      <CardHeader
        color="transparent"
        floated={false}
        shadow={false}
        className="p-4"
      >
        <div className="flex flex-col items-center gap-5">
          <ImageOrInitials
            imageSrc={user?.userImage}
            initials={user?.userName || getNameFromEmail(user?.userEmail)}
            classNameInitials={"shadow-none"}
          />
          <Typography color="blue-gray" className="xl:text-lg font-bold text-center w-full truncate">
            {user?.userName || getNameFromEmail(user?.userEmail)}
          </Typography>
          <Typography className="xl:text-lg font-normal" color="blue-gray">
            Followers {user?.followersCount}
          </Typography>
          <UserFollowButton
            isFollowing={following}
            handleFollow={(e) => handleFollowing(e,user?.userId)}
          />
        </div>
      </CardHeader>
    </Card>
  );
}
