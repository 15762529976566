import { RouterProvider } from "react-router-dom";
import { FileViewerProvider } from "./Context/FileViewerContext";
import { PlanProvider } from "./Context/PlanContext";
import { SocketProvider } from "./Context/SocketContext";
import { NotificationTabProvider } from "./Context/TabContext";
import { ToggleProvider } from "./Context/ToggleSidebar";
import { ProfileProvider } from "./Context/openProfileContext";
import { router } from "./Pages/Routes/routes";

function App() {
  return (
    <ToggleProvider>
      <SocketProvider>
        <FileViewerProvider>
          <NotificationTabProvider>
            <ProfileProvider>
              <PlanProvider>
                <RouterProvider router={router} />
              </PlanProvider>
            </ProfileProvider>
          </NotificationTabProvider>
        </FileViewerProvider>
      </SocketProvider>
    </ToggleProvider>
  );
}

export default App;
